import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const AGENCY_LIST = gql`
  query agencyList($filter: AgencyFilter) {
    agencyList(filter: $filter) {
      total
      data {
        id
        name
        type
        projectAgencies {
          projectName
          creator
          createdAt
        }
      }
    }
  }
`;

export const GET_AGENCY_CONTACTS = gql`
  query contactsList($filter: ContactFilter) {
    contactsList(filter: $filter) {
      total
      data {
        id
        name
        designation
        phoneNo
        email
      }
    }
  }
`;
