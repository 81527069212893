import { Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Error, ErrorMobile } from './assets/svg';
import { BREAKPOINTS, ROUTES } from './common/constants';

const Error404 = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  return (
    <Card className="wrapper-404 bordered">
      <div className="gx-page-error-container">
        <div className="gx-page-error-content">
          <div className="gx-text-center error-img">
            {isDesktopViewport ? <Error /> : <ErrorMobile />}
          </div>
          <h1 className="gx-text-center error-msg">
            Oops something went wrong
          </h1>
          <div className="gx-text-center home-link">
            <Link
              className="gx-btn gx-btn-primary text-underline"
              to={ROUTES.MAIN}
            >
              Home
            </Link>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Error404;
