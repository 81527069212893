import {
  Autocomplete,
  Circle,
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import { Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { MarkerIcon } from '../assets/svg';
import { LAT_LNG } from '../common/constants';

const mapOptions = {
  clickableIcons: false,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = LAT_LNG;
const radiusOptions = {
  strokeColor: '#fc5027',
  strokeOpacity: 1,
  strokeWeight: 3,
  fillOpacity: 0.2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};
const libraries = ['places'];

const GoogleMapComponent = (props) => {
  const { marker, setMarker, radius, inputValue, setInputValue } = props;
  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        const container = document.querySelector('#google-maps-container');
        // eslint-disable-next-line no-undef
        const autoCompleteDropdown = document.querySelector('.pac-container');
        if (container && autoCompleteDropdown) {
          container.appendChild(autoCompleteDropdown);
        }
      }, 500);
    }
  }, [isLoaded]);

  const handleDrag = (e) => {
    setMarker(e?.latLng?.toJSON());
  };

  const MarkerComponent = ({ markerObj }) => {
    return (
      <Marker
        icon={{
          url: MarkerIcon,
          // eslint-disable-next-line no-undef
          scaledSize: new window.google.maps.Size(30, 30),
          // eslint-disable-next-line no-undef
          origin: new window.google.maps.Point(0, 0),
          // eslint-disable-next-line no-undef
          anchor: new window.google.maps.Point(15, 15),
        }}
        draggable
        onDragEnd={handleDrag}
        position={{ lat: markerObj?.lat, lng: markerObj?.lng }}
      />
    );
  };

  const RadiusComponent = ({ markerObj, radius: radiusValue }) => {
    return (
      <Circle center={markerObj} options={radiusOptions} radius={radiusValue} />
    );
  };

  const handlePlaceChange = () => {
    setInputValue(autocomplete?.getPlace()?.formatted_address);
    setMarker(autocomplete?.getPlace()?.geometry?.location?.toJSON());
  };

  return (
    <>
      {isLoaded && (
        <Row className="map-row" id="google-maps-container">
          <Autocomplete
            onPlaceChanged={handlePlaceChange}
            onLoad={(v) => setAutocomplete(v)}
          >
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e?.target?.value)}
              className="map-input"
              placeholder="Enter an address"
            />
          </Autocomplete>
          <GoogleMap
            mapContainerClassName="common-map mb-20"
            zoom={12}
            options={mapOptions}
            center={marker || center}
          >
            {marker && (
              <>
                <MarkerComponent markerObj={marker} />
                <RadiusComponent markerObj={marker} radius={radius} />
              </>
            )}
          </GoogleMap>
        </Row>
      )}
    </>
  );
};

export default GoogleMapComponent;
