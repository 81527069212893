import { Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../../../common/constants';
import CommonCard from '../../../../components/CommonCard';
import StageItemList from './stageItemList/StageItemList';
import StageList from './stageList/StageList';

const EqcDetailContent = ({ eqcTypeData }) => {
  const [stageId, setStageId] = useState();
  const [title, setTitle] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <div className="mb-20">
      <Row gutter={[16, 16]}>
        <Col span={isDesktopViewport ? 8 : 24}>
          <StageList
            setStageId={setStageId}
            stageId={stageId}
            setModalVisible={setModalVisible}
            eqcTypeData={eqcTypeData}
            setTitle={setTitle}
          />
        </Col>
        {isDesktopViewport ? (
          <Col span={16}>
            {stageId ? (
              <StageItemList stageId={stageId} eqcTypeData={eqcTypeData} />
            ) : (
              <CommonCard className="stage-item-card">
                <div className="header d-flex align-center justify-center">
                  <h2 className="mb-0">Please Select Stage First</h2>
                </div>
              </CommonCard>
            )}
          </Col>
        ) : (
          <>
            <div id="stage-item-modal" />
            <Modal
              maskClosable={false}
              // eslint-disable-next-line no-undef
              getContainer={() => document.getElementById('stage-item-modal')}
              width="100vw"
              title={title}
              open={modalVisible}
              footer={null}
              destroyOnClose
              onCancel={() => setModalVisible(false)}
            >
              <StageItemList stageId={stageId} eqcTypeData={eqcTypeData} />
            </Modal>
          </>
        )}
      </Row>
    </div>
  );
};

export default EqcDetailContent;
