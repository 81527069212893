import { useContext } from 'react';
import { AppContext } from '../AppContext';
import {
  ALLOWED_ACTIONS,
  ALLOWED_ACTION_TYPE,
  SYSTEM_ROLES,
} from '../common/constants';

const CanPerform = ({
  action,
  children,
  type = ALLOWED_ACTION_TYPE.SYSTEM,
}) => {
  const {
    state: { currentRole, projectRole },
  } = useContext(AppContext);
  let accessValue = false;

  if (type === ALLOWED_ACTION_TYPE.PROJECT) {
    accessValue = ALLOWED_ACTIONS[action]?.includes(projectRole);
  } else if (type === ALLOWED_ACTION_TYPE.BOTH) {
    accessValue =
      ALLOWED_ACTIONS[action]?.includes(projectRole) ||
      ALLOWED_ACTIONS[action]?.includes(currentRole);
  } else if (
    type === ALLOWED_ACTION_TYPE.SYSTEM ||
    currentRole === SYSTEM_ROLES.SYSTEM_ADMIN
  ) {
    accessValue = ALLOWED_ACTIONS[action]?.includes(currentRole);
  }
  return accessValue && (children || accessValue);
};
export default CanPerform;
