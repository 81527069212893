import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { CopyOutline, RightIcon, WrongIcon } from '../assets/svg';
import { ADDONS, GA_EVENT, GA_LABEL } from '../common/constants';
import { Event } from '../common/trackEvents';
import { copyToClipboard } from '../common/utils';
import { GET_PROFILE } from '../modules/users/graphql/Queries';
import { REQUEST_FEATURE_UPGRADE } from './graphql/Mutation';

const UpgradeModal = ({
  showModal,
  setShowModal,
  isAlreadyRequested = false,
  projectId,
  featureKey,
  refetch,
}) => {
  const [requestSent, setRequestSent] = useState(false);
  const handleCancel = () => {
    setShowModal(false);
  };
  const { initializeAuth, getTenantUser } = useContext(AppContext);
  const tenantUser = getTenantUser();
  const [getUserProfile] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth('', '', res?.getLoggedInUser);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (!requestSent) {
      Event(GA_EVENT.OPEN_REQUEST_FEATURE_UPGRADE_MODAl, {
        label: GA_LABEL.OPEN_REQUEST_FEATURE_UPGRADE_MODAl,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        tenant_id: tenantUser?.tenant?.id,
        is_already_sent: isAlreadyRequested,
        feature_key: featureKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [requestFeatureUpgrade, { loading }] = useMutation(
    REQUEST_FEATURE_UPGRADE,
    {
      onCompleted() {
        if (refetch) {
          refetch();
        } else {
          getUserProfile();
        }
        setRequestSent(true);
        Event(GA_EVENT.FEATURE_UPGRADE_REQUESTED, {
          label: GA_LABEL.FEATURE_UPGRADE_REQUESTED,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          tenant_id: tenantUser?.tenant?.id,
          feature_key: featureKey,
        });
      },
      onError() {},
    },
  );

  const handleClick = () => {
    requestFeatureUpgrade({
      variables: {
        projectId,
        featureKey,
      },
    });
  };
  return (
    <div id="upgrade-modal">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        width={320}
        onCancel={handleCancel}
        closeIcon={<WrongIcon />}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('upgrade-modal')
        }
      >
        <div>
          <h2>
            {!requestSent && isAlreadyRequested ? `Request Sent` : `Upgrade`}
          </h2>
          <p>
            {!requestSent && isAlreadyRequested ? (
              <div>
                <p>
                  Our DigiQC Customer Success Team will be in touch shortly to
                  assist you in supercharging your analytics. For immediate
                  assistance, contact us at
                </p>
                <div className="d-flex align-center text-color mt-10">
                  +91 7984800621
                  <CopyOutline
                    className="duplicate-icon ml-5"
                    height={20}
                    width={20}
                    onClick={() =>
                      copyToClipboard('+91 7984800621', 'Phone number copied!')
                    }
                  />
                </div>
              </div>
            ) : (
              `Your current plan has restriction and requires upgrade.`
            )}
          </p>
          {featureKey === ADDONS.REPORT_CONFIG && (
            <p>
              To know more about this feature,{' '}
              <a
                href="https://help.digiqc.com/en/articles/167-32-eqc-report-configurator"
                rel="noreferrer"
                target="_blank"
              >
                Click here.
              </a>
            </p>
          )}
          {requestSent ? (
            <div className="text-success d-flex align-center">
              Request Sent <RightIcon className="ml-5" height={15} width={15} />
            </div>
          ) : (
            <div className="form-buttons">
              <Button
                shape="round"
                type="primary"
                className="save-button"
                onClick={() => {
                  return isAlreadyRequested ? handleCancel() : handleClick();
                }}
                disabled={loading}
                loading={loading}
              >
                {isAlreadyRequested ? `Okay` : `Request Upgrade`}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UpgradeModal;
