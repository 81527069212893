import { useLazyQuery } from '@apollo/client';
import { Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DEFAULTDATETIMEFORMAT,
  DEFAULT_PAGE_SIZE,
  SCROLL_CONST,
} from '../../../../../../../common/constants';
import useRouter from '../../../../../../../common/useRouter';
import { GET_PROJECT_AUDITOR_COMMENTS } from '../../../../../graphql/Queries';

const AuditorCommentModal = ({
  showModal,
  setShowModal,
  sectionId,
  section,
}) => {
  const [commentData, setCommentData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [fetchComments, { loading }] = useLazyQuery(
    GET_PROJECT_AUDITOR_COMMENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.auditorCommentList?.data;
        if (scrollFlag) {
          const datalist = [...commentData, ...data];
          setCommentData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setCommentData(datalist);
        }
        setHasMore(data?.length >= DEFAULT_PAGE_SIZE);
      },
    },
  );
  const {
    params: { projectId },
  } = useRouter();
  useEffect(() => {
    if (showModal) {
      fetchComments({
        variables: {
          filter: {
            projectId: Number(projectId),
            sectionId: Number(sectionId),
            section,
            skip: 0,
            limit: DEFAULT_PAGE_SIZE,
            sortBy: { field: 'createdAt', order: 'DESC' },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  const handleScroll = (e) => {
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !loading) {
      setScrollFlag(true);
      fetchComments({
        variables: {
          filter: {
            projectId: Number(projectId),
            sectionId: Number(sectionId),
            section,
            skip: commentData?.length,
            limit: DEFAULT_PAGE_SIZE,
            sortBy: { field: 'createdAt', order: 'DESC' },
          },
        },
      });
    }
  };
  return (
    <Modal
      maskClosable={false}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={false}
      destroyOnClose
    >
      <div>
        <h2>View Comments</h2>
        <div
          className="common-scroll-wrapper position-relative"
          onScroll={handleScroll}
        >
          <div className="position-absolute width-percent-100 infinite-data-container auditor-comment-modal">
            {commentData && commentData.length > 0 ? (
              commentData.map((data) => (
                <div key={data.id} className="auditor-view-comment-wrap">
                  <p>
                    {data?.comment}
                    <span>
                      <p>- {data?.auditor?.name}</p>
                      <p>
                        ({moment(data?.createdAt).format(DEFAULTDATETIMEFORMAT)}
                        )
                      </p>
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <div className="auditor-view-comment-wrap">
                <p>No Comments added yet!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AuditorCommentModal;
