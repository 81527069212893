import { Button, Modal } from 'antd';
import React from 'react';

const DeactivateRfiModal = ({ showModal = false, handleOk, handleCancel }) => {
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>Deactivate caution!!</h2>
        <p>If turn off, RFI in all checklist will be turn off. Are you sure?</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOk}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeactivateRfiModal;
