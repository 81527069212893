import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { filter, includes, isEmpty, map } from 'lodash';
import React, { useState } from 'react';
import { DUPLICATE_PROJECT_NOMENCLATURE_LEVEL } from '../../../../graphql/Mutation';
import NomenclatureTree from './NomenclatureTree';

const DuplicateLevelModal = ({
  showModal,
  setShowModal,
  handleRefetch,
  nomenclatureList,
  setNomenclatureList,
  setSelectedTree,
  chartNodes,
  projectId,
  setCheckedKeys,
  checkedKeys,
  setCheckedTreeNode,
  setExpandedKeys: setMainExpandedKeys,
  isProjectActive,
}) => {
  const [newCheckedKeys, setNewCheckedKeys] = useState([]);
  const [newCheckedTreeNode, setNewCheckedTreeNode] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const handleCancel = () => {
    setShowModal(false);
  };

  const [duplicateProjectNomenclatureLevel, { loading }] = useMutation(
    DUPLICATE_PROJECT_NOMENCLATURE_LEVEL,
    {
      onCompleted() {
        setNewCheckedKeys();
        setMainExpandedKeys((prev) => [
          ...prev,
          ...map(newCheckedTreeNode, (item) => item?.data?.data?.id),
        ]);
        setNewCheckedTreeNode();
        setCheckedTreeNode();
        setCheckedKeys();
        handleCancel();
        handleRefetch();
      },
      onError() {},
    },
  );

  const handleCheck = (checkedKeysValue) => {
    const filteredNode = filter(chartNodes, (list) =>
      includes(checkedKeysValue, Number(list?.id)),
    );

    setNewCheckedTreeNode(filteredNode);
  };

  const handleDuplicate = () => {
    duplicateProjectNomenclatureLevel({
      variables: {
        data: {
          projectId,
          levels: checkedKeys,
          ids: map(newCheckedTreeNode, (item) => item?.data?.data?.id),
        },
      },
    });
  };

  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        <div>
          <h2>Duplicate Level</h2>
          <p>Hey there! </p>
          <p>Select levels where you want to duplicate.</p>
          <div className="mb-20">
            <NomenclatureTree
              nomenclatureList={nomenclatureList}
              setNomenclatureList={setNomenclatureList}
              setSelectedTree={setSelectedTree}
              chartNodes={chartNodes}
              setCheckedTreeNode={setNewCheckedTreeNode}
              checkedTreeNode={newCheckedTreeNode}
              setCheckedKeys={setNewCheckedKeys}
              checkedKeys={newCheckedKeys}
              handleCheck={handleCheck}
              needActionButton={false}
              className="inside-modal"
              expandedKeys={expandedKeys}
              setExpandedKeys={setExpandedKeys}
              isProjectActive={isProjectActive}
            />
          </div>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleDuplicate}
              disabled={loading || isEmpty(newCheckedTreeNode)}
              loading={loading}
            >
              Duplicate
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DuplicateLevelModal;
