import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_STAGE_COMMENT = gql`
  mutation updateEqcStageWithoutAuth($data: UpdateEqcStageInput) {
    updateEqcStageWithoutAuth(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_STAGE_ITEM_COMMENT = gql`
  mutation updateEqcStageItemWithoutAuth($data: UpdateEqcStageItemInput!) {
    updateEqcStageItemWithoutAuth(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_APPROVAL_STATUS = gql`
  mutation updateApprovalEqc($data: UpdateApprovalEqcInput) {
    updateApprovalEqc(data: $data) {
      status
      message
    }
  }
`;
