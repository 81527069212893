import { Button, Modal, Spin } from 'antd';
import React from 'react';

const ThankyouModal = ({
  showModal,
  setShowModal,
  handleClick,
  count,
  loading,
}) => {
  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        {loading ? (
          <Spin spinning className="d-flex justify-center" />
        ) : (
          <div>
            <h2>Thank you for reviewing.</h2>
            {count > 0 && <p>You have {count} more approvals pending</p>}
            <div className="form-buttons">
              <Button
                shape="round"
                type="primary"
                className="save-button"
                onClick={() => {
                  handleClick();
                  setShowModal(false);
                }}
              >
                Okay
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ThankyouModal;
