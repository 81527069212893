import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_DROPDOWN_LIST = gql`
  query reportUserDropdownList($filter: DashboardFilter!) {
    reportUserDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_AGENCY_DROPDOWN_LIST = gql`
  query reportAgencyDropdownList($filter: DashboardFilter!) {
    reportAgencyDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_EQC_TYPE_DROPDOWN_LIST = gql`
  query reportEqcTypeDropdownList($filter: DashboardFilter!) {
    reportEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
        project {
          id
          uniqueCode
        }
      }
    }
  }
`;

export const GET_PROJECT_DROPDOWN_LIST = gql`
  query reportProjectDropdownList($filter: ProjectFilter) {
    reportProjectDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_USER_LIST = gql`
  query projectUserList($filter: ProjectUserFilter!) {
    projectUserList(filter: $filter) {
      total
      data {
        id
        assignedAsApprover
        user {
          id
          name
          email
        }
      }
    }
  }
`;

export const GET_PROJECT_EQC_TYPE_LIST = gql`
  query projectEqcTypeList($filter: ProjectEqcTypeFilter!) {
    projectEqcTypeList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const COMMON_PROJECT_EQC_TYPE_DROP_DOWN_LIST = gql`
  query commonProjectEqcTypeDropdownList($filter: ProjectEqcTypeFilter!) {
    commonProjectEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_AGENCY_LIST = gql`
  query projectAgencyList($filter: ProjectAgencyFilter!) {
    projectAgencyList(filter: $filter) {
      total
      data {
        id
        agency {
          id
          name
        }
      }
    }
  }
`;
