import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_DASHBOARD_AGENCY_DROPDOWN_LIST = gql`
  query dashboardProjectAgencyList($filter: DashboardFilter) {
    dashboardProjectAgencyList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const GET_DASHBOARD_USERS_DROPDOWN_LIST = gql`
  query dashboardProjectUserList($filter: DashboardFilter) {
    dashboardProjectUserList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
