import { Button, Modal } from 'antd';
import { includes } from 'lodash';
import React from 'react';
import { PERMISSIONS } from '../../../../../../../../common/constants';

function ApprovalTypeConfirmModal({
  showModal,
  handleOk,
  handleCancel,
  hasExternalApproval,
  eqcTypeData,
  loading,
}) {
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        footer={null}
        closable={false}
        width={400}
      >
        <div>
          <h2>CAUTION</h2>
          {hasExternalApproval && (
            <p>
              External approvers will be removed if you are selecting on-site
              approval.
            </p>
          )}
          {!includes(
            eqcTypeData?.project?.permissions,
            PERMISSIONS.LOCATION,
          ) && (
            <p>
              Location restriction permission will be turned-on for the project
              and approvers has be in project location to give approvals.
            </p>
          )}
          <p>Are you sure?</p>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
              disabled={loading}
            >
              No
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleOk}
              loading={loading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ApprovalTypeConfirmModal;
