import { Menu, Popover, Space } from 'antd';
import { includes } from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import alternateProfile from '../../../assets/images/alternateProfile.png';
import {
  ALLOWED_ACTION_KEYS,
  MODULES,
  ROUTES,
} from '../../../common/constants';
import useRouter from '../../../common/useRouter';
import CanPerform from '../../../components/CanPerform';
import NotificationModal from '../../../modules/notification/NotificationModal';

const UserProfile = ({ userData }) => {
  const {
    location: { pathname },
  } = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const profileItem = [
    {
      label: <Link to={ROUTES.PROFILE}>{MODULES.PROFILE}</Link>,
      key: ROUTES.PROFILE,
    },
    ...(CanPerform({
      action: ALLOWED_ACTION_KEYS.NOTIFICATION_SETTING,
    })
      ? [
          {
            label: (
              <div onClick={() => setShowModal(true)}>
                {MODULES.NOTIFICATION}
              </div>
            ),
            key: ROUTES.NOTIFICATION,
          },
        ]
      : []),
    {
      label: <Link to={ROUTES.CHANGE}>{MODULES.CHANGE}</Link>,
      key: ROUTES.CHANGE,
    },
    {
      label: <Link to={ROUTES.LOGOUT}>{MODULES.LOGOUT}</Link>,
      key: ROUTES.LOGOUT,
    },
  ];

  const paths = [ROUTES.CHANGE, ROUTES.PROFILE];

  return (
    <div className="gx-avatar-row pointer header-right p-0">
      <div className="header-right-menu" />
      <Popover
        className="d-flex flex-row user-profile"
        placement="bottomRight"
        content={
          <Menu
            selectedKeys={[`/${pathname?.split('/')[1]}`]}
            items={profileItem}
            onClick={() => setVisible(false)}
          />
        }
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
        getTooltipContainer={(triggerNode) => triggerNode?.parentNode}
        destroyTooltipOnHide={!includes(paths, pathname)}
      >
        <Space direction="horizontal">
          <img
            src={userData?.getLoggedInUser?.profileImage || alternateProfile}
            className="profileImage gx-pointer mr-5"
            alt="Avatar"
          />
        </Space>
      </Popover>

      {showModal && (
        <NotificationModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default UserProfile;
