import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_EQC_TYPE = gql`
  query eqcTypeList($filter: EqcTypeFilter) {
    eqcTypeList(filter: $filter) {
      total
      data {
        id
        name
        uom
        isActive
        createdBy
        iso
        updatedAt
        creator {
          id
          name
        }
      }
    }
  }
`;

export const TEMPLATE_LIST = gql`
  query masterEqcTypeList($filter: EqcTypeFilter) {
    masterEqcTypeList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_STAGE_LIST = gql`
  query eqcTypeStageList($filter: EqcTypeStageFilter!) {
    eqcTypeStageList(filter: $filter) {
      total
      data {
        id
        name
        order
        drawing
        witness
      }
    }
  }
`;

export const GET_STAGE_ITEM_LIST = gql`
  query eqcTypeStageItemList($filter: EqcTypeStageItemFilter!) {
    eqcTypeStageItemList(filter: $filter) {
      total
      data {
        id
        title
        type
        order
        options
        photo
        remark
        description
      }
    }
  }
`;
