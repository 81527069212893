import { useLazyQuery } from '@apollo/client';
import { Button, Modal, Skeleton } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CopyOutline } from '../../../../../../assets/svg';
import { copyToClipboard } from '../../../../../../common/utils';
import { REQUEST_NOMENCLATURE_FEATURE } from '../../../../graphql/Queries';

const RequestAccessModal = ({
  showModal,
  setShowModal,
  projectId,
  isAlreadyRequested = false,
  projectDataRefetch,
}) => {
  const [isSent, setIsSent] = useState(false);
  const [
    requestProjectNomenclatureFeature,
    { loading: requestLoading, data },
  ] = useLazyQuery(REQUEST_NOMENCLATURE_FEATURE, {
    variables: {
      projectId: Number(projectId),
    },
    fetchPolicy: 'network-only',
    onError() { },
    onCompleted() {
      setIsSent(true);
      projectDataRefetch({
        variables: {
          id: Number(projectId),
        },
      });
    },
  });

  useEffect(() => {
    if (!isAlreadyRequested) {
      requestProjectNomenclatureFeature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="request-access-modal">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={isAlreadyRequested && !isSent ? 320 : 420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('request-access-modal')
        }
      >
        {requestLoading ? (
          <Skeleton />
        ) : (
          <div>
            <h2>
              {isAlreadyRequested && !isSent
                ? 'Request Access'
                : 'Request Sent'}{' '}
            </h2>
            {isAlreadyRequested && !isSent ? (
              'Request already sent'
            ) : (
              <>
                {!isEmpty(
                  data?.requestProjectNomenclatureFeature?.systemAdminList,
                ) ? (
                  <p>
                    Our DigiQC Customer Success Team will reach out to the
                    account admin shortly to assist in supercharging analytics.
                    For immediate assistance, please contact your account admin
                    mention below:
                  </p>
                ) : (
                  <p>
                    Our DigiQC Customer Success Team will be in touch shortly to
                    assist you in supercharging your analytics. For immediate
                    assistance, contact us at
                  </p>
                )}
                {isEmpty(
                  data?.requestProjectNomenclatureFeature?.systemAdminList,
                ) ? (
                  <div className="d-flex align-center text-color mt-10">
                    +91 7984800621
                    <CopyOutline
                      className="duplicate-icon ml-5"
                      height={20}
                      width={20}
                      onClick={() =>
                        copyToClipboard(
                          '+91 7984800621',
                          'Phone number copied!',
                        )
                      }
                    />
                  </div>
                ) : (
                  <ol>
                    {map(
                      data?.requestProjectNomenclatureFeature?.systemAdminList,
                      (contact) => {
                        return (
                          <li>
                            {contact?.name}:{' '}
                            <span className="text-color">
                              {contact?.phoneNo}
                            </span>{' '}
                            <CopyOutline
                              className="duplicate-icon ml-5"
                              height={20}
                              width={20}
                              onClick={() =>
                                copyToClipboard(
                                  contact?.phoneNo,
                                  'Phone number copied!',
                                )
                              }
                            />
                          </li>
                        );
                      },
                    )}
                  </ol>
                )}
              </>
            )}
            <div className="form-buttons">
              <Button
                shape="round"
                type="primary"
                className="save-button"
                onClick={() => setShowModal(false)}
              >
                Okay
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RequestAccessModal;
