import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_AGENCY = gql`
  mutation createAgency($data: CreateAgencyInput!) {
    createAgency(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_AGENCY = gql`
  mutation updateAgency($id: ID!, $data: UpdateAgencyInput!) {
    updateAgency(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_AGENCY = gql`
  mutation deleteAgency($id: ID!) {
    deleteAgency(id: $id) {
      status
      message
    }
  }
`;
export const DELETE_AGENCY_CONTACT = gql`
  mutation deleteContact($id: ID!) {
    deleteContact(id: $id) {
      status
      message
    }
  }
`;

export const CREATE_AGENCY_CONTACT = gql`
  mutation createContact($data: CreateContactInput) {
    createContact(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_AGENCY_CONTACT = gql`
  mutation updateContact($id: ID!, $data: UpdateContactInput) {
    updateContact(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const IMPORT_AGENCY_CSV = gql`
  mutation importAgencyCsv($key: String!) {
    importAgencyCsv(key: $key) {
      insertedData
      invalidData
      insertedDataCount
      invalidDataCount
    }
  }
`;
