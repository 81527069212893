import { Col, Row } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import nomenclatureDesktop from '../../../../../../assets/svg/nomenclatureDesktop.svg';
import nomenclatureMobile from '../../../../../../assets/svg/nomenclatureMobile.svg';
import roundShape from '../../../../../../assets/svg/roundShape.svg';
import { BREAKPOINTS } from '../../../../../../common/constants';

const DisabledState = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <div className="disabled-state">
      <Row className="first-row">
        <Col span={isDesktopViewport ? 12 : 24} className="col-left">
          <p className="main-heading">
            Standardized Naming Protocols for Enhanced Analytical Tracking
          </p>
          <p className="secondary-heading">
            Supercharge Analytics Precision: Admins Define Master Naming for
            Mobile Users, Ensuring Flawless Tracking and Quality Oversight
          </p>
          <div id="create-button" />
        </Col>
        <Col span={isDesktopViewport ? 12 : 24} className="col-right">
          <picture>
            <img src={roundShape} alt="round-shape" className="round-shape" />
          </picture>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/MwekQKnO3C8?si=nhZQKXvXkxqjEbtU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div>
            <picture>
              <source media="(max-width:999px)" srcSet={nomenclatureMobile} />
              <img
                src={nomenclatureDesktop}
                className="width-percent-100 mobile-nomenclature-image"
                alt="mobile-nomenclature"
              />
            </picture>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DisabledState;
