import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../../common/utils';
import { ADD_AUDITOR_COMMENT } from '../../../../../graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const AddCommentModal = ({
  showModal,
  setShowModal,
  sectionId,
  section,
  refetch = () => { },
}) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { params: { projectId } } = useRouter();
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };
  const [addAuditorComment, { loading }] = useMutation(ADD_AUDITOR_COMMENT, {
    onError: () => { },
    onCompleted: () => {
      Event(GA_EVENT.ADD_AUDITOR_COMMENT, {
        label: GA_LABEL.ADD_AUDITOR_COMMENT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        section_id: sectionId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      handleCancel();
      refetch();
    },
  });

  const handleSubmit = (values) => {
    addAuditorComment({
      variables: {
        data: {
          projectId: Number(projectId),
          sectionId: Number(sectionId),
          section,
          comment: values?.comment,
        },
      },
    });
  };

  return (
    <Modal
      maskClosable={false}
      open={showModal}
      onCancel={handleCancel}
      footer={false}
    >
      <h2>Add Comment</h2>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="comment"
          label="Comment"
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 1000,
              message: 'Name cannot be more than 1000 characters',
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
        </Form.Item>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={loading}
          >
            Add
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default AddCommentModal;
