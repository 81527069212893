import { useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Form, Modal, Row } from 'antd';
import { get, orderBy, range } from 'lodash';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DATEFORMATWITHDASH,
  DEFAULTDATEFORMAT,
} from '../../../../../../common/constants';
import { formValidatorRules } from '../../../../../../common/utils';
import { MARK_QC_PASS } from '../../../../graphql/Mutation';

const MyDatePicker = DatePicker.generatePicker(momentGenerateConfig);

const MarkQCPassModal = (props) => {
  const { showModal, setShowModal, eqcData, setEqcData, refetchEqcData } =
    props;
  const [isLastStage, setIsLastStage] = useState(false);
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;

  const handleCancel = () => {
    setShowModal(false);
    setEqcData();
    form.resetFields();
  };
  useEffect(() => {
    if (eqcData) {
      const orderedEQCStages = orderBy(
        get(eqcData, 'eqcStages'),
        ['id'],
        ['desc'],
      );
      setIsLastStage(
        Number(get(eqcData, 'stageHistories[0].eqcStageId')) ===
          Number(get(orderedEQCStages, '[0].id')),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcData]);

  const [markQCPass, { loading }] = useMutation(MARK_QC_PASS, {
    onError() {},
    onCompleted() {
      setEqcData();
      form.resetFields();
      setShowModal(false);
      refetchEqcData();
    },
  });

  const onFormSubmit = async ({
    inspectorReminderDate,
    inspectorReminderTime,
    projectAgencyId,
  }) => {
    const combineDate = inspectorReminderDate?.set({
      hour: moment(inspectorReminderTime, 'HH:mm').hours(),
      minute: moment(inspectorReminderTime, 'HH:mm').minutes(),
      second: moment(inspectorReminderTime, 'HH:mm').seconds(),
      millisecond: 0,
    });
    markQCPass({
      variables: {
        data: {
          eqcId: Number(eqcData?.id),
          eqcStageId: Number(get(eqcData, 'eqcStageHistories[0].eqcStageId')),
          projectId: Number(projectId),
          inspectorReminderDate:
            eqcData?.eqcStageIndex === eqcData?.eqcStageCount
              ? undefined
              : eqcData?.eqcStageHistories?.[0]?.inspectorReminderDate ||
                moment(combineDate)?.utc()?.format(),
          projectAgencyId,
        },
      },
    });
  };

  return (
    <Modal
      maskClosable={false}
      centered
      form={form}
      visible={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <h2>Mark QC Pass</h2>
      {eqcData?.eqcStageHistories?.[0]?.inspectorReminderDate ||
      eqcData?.eqcStageIndex === eqcData?.eqcStageCount ? (
        <p>Are you sure you want to pass this EQC stage?</p>
      ) : (
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          {!isLastStage && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="inspectorReminderDate"
                  label="Reminder Date"
                  rules={[required]}
                >
                  <MyDatePicker
                    format={DATEFORMATWITHDASH}
                    className="width-percent-100"
                    disabledDate={(current) =>
                      current && current < moment().startOf('day')
                    }
                    onChange={() =>
                      form.setFieldsValue({ inspectorReminderTime: '' })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="inspectorReminderTime"
                  label="Reminder Time"
                  dependencies={['inspectorReminderDate']}
                  rules={[required]}
                >
                  <MyDatePicker
                    picker="time"
                    className="width-percent-100"
                    showSecond={false}
                    inputReadOnly
                    disabledTime={() => ({
                      disabledHours: () =>
                        range(0, 24).splice(
                          0,
                          form.getFieldValue('inspectorReminderDate') &&
                            moment().format(DEFAULTDATEFORMAT) ===
                              moment(
                                form.getFieldValue('inspectorReminderDate'),
                              ).format(DEFAULTDATEFORMAT)
                            ? moment().hour()
                            : 0,
                        ),
                      disabledMinutes: (value) =>
                        range(0, 60).splice(
                          0,
                          form.getFieldValue('inspectorReminderDate') &&
                            moment().format(DEFAULTDATEFORMAT) ===
                              moment(
                                form.getFieldValue('inspectorReminderDate'),
                              ).format(DEFAULTDATEFORMAT) &&
                            value === moment().hour()
                            ? moment().minute()
                            : 0,
                        ),
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      )}
      <div className="form-buttons">
        <Button shape="round" className="cancel-button" onClick={handleCancel}>
          {eqcData?.eqcStageHistories?.[0]?.inspectorReminderDate
            ? 'No'
            : `Cancel`}
        </Button>
        <Button
          shape="round"
          type="primary"
          className="save-button"
          htmlType="submit"
          loading={loading}
          onClick={() =>
            eqcData?.eqcStageHistories?.[0]?.inspectorReminderDate ||
            eqcData?.eqcStageIndex === eqcData?.eqcStageCount
              ? onFormSubmit({})
              : form.submit()
          }
        >
          {eqcData?.eqcStageHistories?.[0]?.inspectorReminderDate ||
          eqcData?.eqcStageIndex === eqcData?.eqcStageCount
            ? 'Yes'
            : `Save`}
        </Button>
      </div>
    </Modal>
  );
};

export default MarkQCPassModal;
