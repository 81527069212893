import { Button, Modal } from 'antd';
import React from 'react';
import {
  CONFIRMATION_MODAL_TITLES,
  CONFIRMATION_TYPES,
} from '../../../../../../common/constants';

const ConfirmationModal = ({
  showModal = false,
  setShowModal,
  handleConfirmation,
  type = CONFIRMATION_TYPES?.EDIT,
  alreadyDraftedUserData,
  setIsActionLoading,
  isActionLoading,
  setAlreadyDraftedUserData,
}) => {
  const handleOk = () => {
    setIsActionLoading(true);
    handleConfirmation();
  };

  const handleCancel = () => {
    setShowModal(false);
    if (alreadyDraftedUserData) {
      setAlreadyDraftedUserData();
    }
  };
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>CAUTION</h2>
        <p>
          {type !== CONFIRMATION_TYPES.MULTIPLE_USER &&
            CONFIRMATION_MODAL_TITLES?.[type]?.RFI_TITLE}
        </p>
        {type === CONFIRMATION_TYPES.MULTIPLE_USER &&
          alreadyDraftedUserData?.draftCreatorInfo && (
            <p>
              This RFI is already being edited by "
              <b>{alreadyDraftedUserData?.draftCreatorInfo?.name}</b> (
              <b>{alreadyDraftedUserData?.draftCreatorInfo?.phoneNo}</b>)", do
              you want to continue as this will discard changes made by other
              user?
            </p>
          )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
            disabled={isActionLoading}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOk}
            disabled={isActionLoading}
            loading={isActionLoading}
          >
            {CONFIRMATION_MODAL_TITLES?.[type]?.BUTTON_TITLE}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
