import { Input } from 'antd';
import { debounce, trim } from 'lodash';
import React, { useMemo, useState } from 'react';
import { SearchIcon } from '../assets/svg';

let searchDebounce = null;

const SearchComponent = (props) => {
  const { name = '', getData, className, defaultValue, ...rest } = props;
  const [query, setQuery] = useState(defaultValue || '');
  const handleChange = ({ target: { value } }) => {
    setQuery(value);
    if (getData) {
      if (searchDebounce) {
        searchDebounce.cancel();
        searchDebounce = null;
      }
      searchDebounce = debounce(getData, 500);
      searchDebounce(trim(value));
    }
  };

  useMemo(() => {
    setQuery(defaultValue);
  }, [defaultValue]);

  return (
    <Input
      className={`search-component ${className || ''}`}
      allowClear
      placeholder="Search"
      value={query}
      name={name}
      onChange={handleChange}
      onPressEnter={handleChange}
      prefix={<SearchIcon />}
      {...rest}
    />
  );
};

export default SearchComponent;
