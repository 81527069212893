import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTIFICATION_SETTING = gql`
  query getNotificationSetting {
    getNotificationSetting {
      id
      key
      description
      isActive
      tenantId
      userId
      type
      notificationType
      sentOn
      createdAt
      updatedAt
    }
  }
`;
