import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { keys, map } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../../../../AppContext';
import { GA_EVENT, GA_LABEL, UOMS } from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonSelect from '../../../../../../components/CommonSelect';
import { UPDATE_PROJECT_EQC_TYPE } from '../../../../graphql/Mutation';

const EditEqcTypeModal = (props) => {
  const {
    showModal,
    setShowModal,
    eqcTypeData,
    setEqcTypeData,
    refetchEqcTypeData,
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { params: { projectId } } = useRouter();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;
  const handleCancel = () => {
    setShowModal(false);
    setEqcTypeData();
    form.resetFields();
  };
  useEffect(() => {
    if (eqcTypeData) {
      const { name, uom, iso } = eqcTypeData;
      form.setFieldsValue({ name, uom, iso });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcTypeData]);

  const [updateProjectEqcType] = useMutation(UPDATE_PROJECT_EQC_TYPE, {
    onError() { },
    onCompleted() {
      Event(GA_EVENT.EDIT_PROJECT_CHECKLIST, {
        label: GA_LABEL.EDIT_PROJECT_CHECKLIST,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_checklist_id: eqcTypeData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setEqcTypeData();
      form.resetFields();
      setShowModal(false);
      refetchEqcTypeData();
    },
  });

  const onFormSubmit = async (formValues) => {
    try {
      await updateProjectEqcType({
        variables: {
          id: eqcTypeData?.id,
          data: {
            ...formValues,
            projectId: Number(projectId),
          },
        },
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        form={form}
        open={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <h2 className="mb-15">Edit Checklist</h2>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              required,
              {
                max: 250,
                message: 'Name cannot be more than 250 characters',
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item name="uom" label="UOM" rules={[required]}>
            <CommonSelect
              options={[
                ...map(keys(UOMS), (item) => {
                  return {
                    key: item,
                    value: item,
                    label: UOMS[item],
                  }
                }),
              ]} />
          </Form.Item>
          <Form.Item
            name="iso"
            label="Reference Number"
            rules={[
              required,
              {
                max: 250,
                message: 'Content cannot be more than 250 characters',
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditEqcTypeModal;
