import { Card } from 'antd';
import * as eva from 'eva-icons';
import React, { useEffect } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS, ROUTES } from '../../common/constants';
import useRouter from '../../common/useRouter';
import ProjectList from './components/ProjectList';

const Projects = () => {
  const { navigate } = useRouter()
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Projects</div>
        </>
      )}
      <div className="mobile-card-wrapper">
        <Card className="project-module">
          <div className="project-header d-flex align-center justify-between">
            {isDesktopViewport && (
              <div className="project-header-title d-flex">
                <i
                  data-eva="folder-outline"
                  onClick={() => {
                    navigate(ROUTES.PROJECTS);
                  }}
                  className="pointer"
                />
                <h1>Projects</h1>
              </div>
            )}
            <div
              id="add-search-projects"
              className={`project-header-button d-flex align-center ${!isDesktopViewport ? 'width-percent-100' : ''
                }`}
            />
          </div>
          <ProjectList />
        </Card>
      </div>
    </>
  );
};

export default Projects;
