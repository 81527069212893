import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { omit } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  WARNINGS,
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../../../common/utils';
import {
  CREATE_PROJECT_EQC_STAGE,
  UPDATE_PROJECT_EQC_STAGE,
} from '../../../../../../graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const AddEditStageModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    stageData,
    setStageData,
    eqcTypeId,
    refetchStageDataWithInitialValues,
    isDisabled,
    eqcTypeData,
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const { params: { projectId } } = useRouter();
  const [warning, setWarning] = useState(false);
  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setStageData();
  };
  const [createStage, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_EQC_STAGE,
    {
      onError() { },
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_CHECKLIST_STAGE, {
          label: GA_LABEL.ADD_PROJECT_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      },
    },
  );

  const [updateStage, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_EQC_STAGE,
    {
      onError() { },
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_CHECKLIST_STAGE, {
          label: GA_LABEL.EDIT_PROJECT_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      },
    },
  );

  const onSubmitFinish = async (formValues) => {
    let newFormValues = {
      name: formValues?.name,
      projectEqcTypeId: Number(eqcTypeId),
    };
    if (isDisabled) {
      newFormValues = omit(newFormValues, ['name']);
    }
    const variables = isUpdate
      ? { data: newFormValues, id: stageData.id }
      : newFormValues;
    try {
      if (isUpdate) {
        await updateStage({
          variables: { ...variables },
        });
        return;
      }
      await createStage({
        variables: { data: { ...variables } },
      });
    } catch (error) {
      return error;
    }
  };

  const handleFieldsChange = () => {
    if (eqcTypeData?.isPublished) {
      const formData = form.getFieldsValue(true);
      if (formData?.needApproval) {
        setWarning(WARNINGS.APPROVER_CHANGED);
      } else if (stageData?.needApproval && !formData.needApproval) {
        setWarning(WARNINGS.APPROVER_REMOVED);
      } else {
        setWarning('');
      }
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      className="dialog"
      footer={null}
      closable={false}
      destroyOnClose
    >
      <h2>{isUpdate ? 'Edit Stage' : 'Add Stage'}</h2>
      <Form
        form={form}
        initialValues={stageData}
        layout="vertical"
        onFinish={onSubmitFinish}
        onFieldsChange={handleFieldsChange}
        className="stage-config"
      >
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 250,
              message: 'Stage name cannot be more than 250 characters',
            },
          ]}
          name="name"
          label="Name"
        >
          <Input
            allowClear
            placeholder="Enter Stage Name"
            disabled={isDisabled}
          />
        </Form.Item>
        {warning && <div className="text-danger">{warning}</div>}
        <div className="form-buttons mt-15">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditStageModal;
