import { Drawer } from 'antd';
import React from 'react';

const ApproversDrawer = ({ open, setOpen, children, height = 450 }) => {
  return (
    <div id="view-approvers-drawer">
      <Drawer
        placement="bottom"
        height={height}
        onClose={() => setOpen(false)}
        open={open}
        destroyOnClose
        closable={false}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('view-approvers-drawer')
        }
      >
        <>
          <div className="title">Approvers info:</div>
          {children}
        </>
      </Drawer>
    </div>
  );
};

export default ApproversDrawer;
