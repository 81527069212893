import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROJECT_LIST = gql`
  query projectList($filter: ProjectFilter) {
    projectList(filter: $filter) {
      total
      data {
        id
        name
        uniqueCode
        clientName
        isActive
        projectUsers {
          id
          userId
          user {
            id
            name
            isDisabled
          }
          roles
        }
        creator {
          id
          name
        }
        timeZone
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_DROPDOWN_LIST = gql`
  query projectDropdownList($filter: ProjectFilter) {
    projectDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const ASSET_PROJECT_DROPDOWN_LIST = gql`
  query assetProjectDropdownList($filter: ProjectFilter) {
    assetProjectDropdownList(filter: $filter) {
      total
      data {
        id
        name
        uniqueCode
      }
    }
  }
`;

export const ASSET_EQC_TYPE_DROPDOWN_LIST = gql`
  query assetProjectEqcTypeDropdownList($filter: ProjectEqcTypeFilter!) {
    assetProjectEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const ASSET_EQC_TYPE_STAGE_DROPDOWN_LIST = gql`
  query assetProjectEqcTypeStageDropdownList(
    $filter: ProjectEqcTypeStageFilter!
  ) {
    assetProjectEqcTypeStageDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const ASSET_EQC_TYPE_STAGE_ITEM_DROPDOWN_LIST = gql`
  query assetProjectEqcTypeStageItemDropdownList(
    $filter: ProjectEqcTypeStageItemFilter!
  ) {
    assetProjectEqcTypeStageItemDropdownList(filter: $filter) {
      total
      data {
        id
        title
      }
    }
  }
`;

export const GET_SINGLE_PROJECT = gql`
  query getProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      uniqueCode
      clientName
      isRfiRequestSent
      isReportConfigRequestSent
      description
      longitude
      latitude
      isRfiActive
      radius
      permissions
      timeZone
      nomenclatureCount
      isNomenclatureRequestSent
      isActive
      logo
      projectRFIs {
        id
        status
      }
      projectUsers {
        id
        userId
        roles
        access
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query projectUserDropdownList($filter: ProjectUserFilter!) {
    projectUserDropdownList(filter: $filter) {
      total
      data {
        id
        name
        roles
      }
    }
  }
`;

export const GET_ONBOARDED_PROJECT_USERS = gql`
  query onboardProjectUserList($filter: ProjectUserFilter!) {
    onboardProjectUserList(filter: $filter) {
      total
      data {
        id
        user {
          id
          name
          roles
        }
        roles
      }
    }
  }
`;

export const GET_PROJECT_USERS_FOR_EQC_TYPES = gql`
  query projectEqcTypeUserDropdownList($filter: ProjectUserFilter!) {
    projectEqcTypeUserDropdownList(filter: $filter) {
      total
      projectAdminContains
      data {
        id
        user {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_AGENCIES = gql`
  query agencyDropdownList($filter: ProjectAgencyFilter) {
    agencyDropdownList(filter: $filter) {
      total
      data {
        id
        name
        contacts {
          id
          name
        }
      }
    }
  }
`;

export const GET_ONBOARDED_PROJECT_AGENCY_LIST = gql`
  query onboardProjectAgencyList($filter: ProjectAgencyFilter!) {
    onboardProjectAgencyList(filter: $filter) {
      total
      data {
        id
        agencyId
        agency {
          id
          name
          type
          contacts {
            id
            name
          }
        }
        projectAgencyContacts {
          contact {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_AGENCY_LIST = gql`
  query projectAgencyList($filter: ProjectAgencyFilter!) {
    projectAgencyList(filter: $filter) {
      total
      data {
        id
        projectId
        agencyId
        agency {
          id
          name
          type
          contacts {
            id
            name
            phoneNo
            email
          }
        }
        projectAgencyContacts {
          contact {
            id
            name
            phoneNo
            email
          }
        }
        projectAgencyEqcs {
          id
          name
        }
      }
    }
  }
`;
export const GET_UNASSIGN_AGENCY_LIST = gql`
  query unassignAgencyContactList($filter: ProjectAgencyFilter!) {
    unassignAgencyContactList(filter: $filter) {
      total
      data {
        id
        name
        phoneNo
        email
      }
    }
  }
`;
export const GET_UNASSIGN_AGENCY_LIST_COUNT = gql`
  query unassignAgencyContactList($filter: ProjectAgencyFilter!) {
    unassignAgencyContactList(filter: $filter) {
      total
    }
  }
`;

export const GET_UNASSIGN_CONTACT_LIST_COUNT = gql`
  query unassignContactList($filter: ContactFilter!) {
    unassignContactList(filter: $filter) {
      total
    }
  }
`;

export const GET_UNASSIGN_CONTACT_LIST = gql`
  query unassignContactList($filter: ContactFilter) {
    unassignContactList(filter: $filter) {
      total
      data {
        contact {
          id
          name
          phoneNo
        }
        projectAgency {
          project {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_AGENCY_FOR_EQC_TYPES = gql`
  query projectEqcTypeAgencyDropdownList($filter: ProjectAgencyFilter!) {
    projectEqcTypeAgencyDropdownList(filter: $filter) {
      total
      data {
        id
        agency {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_EQC_TYPE = gql`
  query projectEqcTypeDropdownList($filter: ProjectEqcTypeFilter!) {
    projectEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_AGENCY_DROPDOWN_LIST = gql`
  query agencyDropdownList($filter: ProjectAgencyFilter) {
    agencyDropdownList(filter: $filter) {
      data {
        id
        name
        type
      }
      total
    }
  }
`;

export const ASSIGN_CONTACT_AGENCY_DROPDOWN_LIST = gql`
  query assignContactAgencyDropdownList($filter: ProjectAgencyFilter) {
    assignContactAgencyDropdownList(filter: $filter) {
      total
      data {
        id
        name
        projectAgencies {
          id
        }
      }
    }
  }
`;

export const GET_PROJECT_USER_EQC_TYPES_LIST = gql`
  query projectUserEqcTypeDropdownList($filter: ProjectEqcTypeFilter!) {
    projectUserEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const GET_PROJECT_AGENCY_EQC_TYPES_LIST = gql`
  query projectAgencyEqcTypeDropdownList($filter: ProjectEqcTypeFilter!) {
    projectAgencyEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const GET_PROJECT_EQCTYPES_LIST_MIN = gql`
  query projectEqcTypeList($filter: ProjectEqcTypeFilter!) {
    projectEqcTypeList(filter: $filter) {
      total
      data {
        id
        name
        projectId
        status
      }
    }
  }
`;

export const GET_PROJECT_EQC_TYPES_LIST = gql`
  query projectEqcTypeList($filter: ProjectEqcTypeFilter!) {
    projectEqcTypeList(filter: $filter) {
      total
      data {
        id
        name
        uom
        iso
        status
        eqcTypeId
        createdBy
        isRfiActive
        projectEqcTypeStages {
          id
          name
          needApproval
          projectEqcTypeStageApprovals {
            id
            levelName
            levelNumber
            internalApproverId
            externalApproverId
            internalApproverName
            externalApproverName
          }
        }
        projectUserEqcTypes {
          projectUser {
            user {
              id
              name
            }
          }
        }
        projectRFIUsers {
          projectUser {
            user {
              id
              name
            }
          }
        }
        projectAgencyEqcTypes {
          projectAgency {
            agency {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const GET_PROJECT_USER_LIST = gql`
  query projectUserList($filter: ProjectUserFilter!) {
    projectUserList(filter: $filter) {
      total
      data {
        id
        projectId
        userId
        roles
        webAccess
        access
        permissions
        assignedAsApprover
        user {
          id
          name
          roles
          email
          phoneNo
        }
        projectUserEqcTypes {
          id
          name
        }
        projectUserRFIEqcTypes {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_EQC_TYPE_DROPDOWN_LIST = gql`
  query projectEqcTypeDropdownList($filter: ProjectEqcTypeFilter!) {
    projectEqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const GET_PROJECT_USER_DROPDOWN_LIST = gql`
  query projectUserDropdownList($filter: ProjectUserFilter!) {
    projectUserDropdownList(filter: $filter) {
      total
      data {
        id
        name
        roles
      }
    }
  }
`;
export const GET_AGENCY_CONTACTS_DROPDOWN_LIST = gql`
  query projectAgencyContactDropdownList($filter: ProjectAgencyFilter) {
    projectAgencyContactDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const GET_PROJECT_EQC_LIST = gql`
  query eqcList($filter: EqcFilter) {
    eqcList(filter: $filter) {
      total
      data {
        id
        eqcName
        projectEqcTypeName
        status
        workStatus
        eqcStageIndex
        eqcStageCount
        doneAt
        isAudited
        createdAt
        creator {
          id
          name
        }
        modifier {
          id
          name
        }
        eqcStageHistories {
          id
          name
          status
          eqcStageId
          levelName
          levelNumber
          isOverdue
          isRfiStage
          inspectorReminderDate
          projectEqcTypeStageApprovals {
            id
            internalApproverId
            externalApproverId
            levelName
            levelNumber
            internalApproverName
            externalApproverName
          }
          eqcApprovalLogs {
            id
            internalApproverId
            externalApproverId
            approvalStatus
            levelName
            levelNumber
            approvedAt
            redoAt
            internalApproverName
            externalApproverName
          }
        }
        eqcStages {
          id
          name
          internalApproverName
          externalApproverName
          status
          eqcStageApprovals {
            id
            levelName
            levelNumber
            internalApproverId
            externalApproverId
            internalApproverName
            externalApproverName
          }
        }
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_EQC = gql`
  query getEqc($id: ID!) {
    getEqc(id: $id) {
      id
      eqcName
      projectEqcTypeName
      status
      workStatus
      doneAt
      projectEqcTypeName
      projectEqcTypeId
      uom
      isAudited
      eqcCompletedAt
      eqcStartedAt
      eqcSyncedAt
      eqcRenameLogs {
        id
        newName
        oldName
        action {
          id
          name
        }
        updatedAt
      }
      creator {
        id
        name
      }
      modifier {
        id
        name
      }
      eqcStageHistories {
        id
        name
        status
        eqcStageId
        eqcStageStartedAt
        eqcStageCompletedAt
        eqcStagePausedAt
        eqcStageRestartedAt
        eqcApprovalLogs {
          id
          approvalDetails
          updatedAt
        }
      }
      eqcStages {
        id
        name
        internalApproverName
        externalApproverName
        order
        status
        drawingTitleImg
        drawingImg
        witnessesImg
        witnessesId
        latitude
        projectEqcTypeStageId
        longitude
        updatedAt
        eqcStageStartedAt
        eqcStageCompletedAt
        eqcStagePausedAt
        eqcStageRestartedAt
        eqcStageSynceStartedAt
        eqcStageSynceCompletedAt
        actions {
          id
          name
        }
        eqcStageItems {
          id
          title
          description
          type
          options
          photo
          remark
          photos
          remarkAudios
          remarks
          qcOption
          generalRemark
          isFailedImmediately
          generalRemarkImages
          order
          qcStatus
        }
      }
      updatedAt
    }
  }
`;

export const GET_EQC_STAGE_HISTORY_LIST = gql`
  query eqcStageHistoryListByProjectEqc($filter: EqcStageHistoryInfoInput!) {
    eqcStageHistoryListByProjectEqc(filter: $filter) {
      id
      name
      status
      drawingTitleImg
      drawingImg
      witnessesImg
      drawingPDF
      witnessesId
      latitude
      longitude
      updatedAt
      actionAt
      itemHistory
      skipRemark
      levelName
      levelNumber
      isRfiStage
      byPassRemark
      eqcPauseLogs {
        id
      }
      skipImages
      skipAudios
      byPassedImages
      byPassedAudios
      eqcStageCompletedAt
      eqcStageStartedAt
      eqcStageSynceStartedAt
      eqcStageSynceCompletedAt
      inspectorReminderDate
      isRfiStage
      projectAgency {
        agency {
          name
          id
        }
      }
      eqcApprovalLogs {
        id
        approvalDetails
        updatedAt
      }
      action {
        id
        name
      }
    }
  }
`;

export const GET_EQC_STAGE_HISTORY = gql`
  query getWebStageHistory($id: ID!) {
    getWebStageHistory(id: $id) {
      id
      name
      status
      drawingTitleImg
      drawingImg
      witnessesImg
      witnessesId
      latitude
      longitude
      updatedAt
      actionAt
      itemHistory
      stageApprovals {
        id
        approvalDetails
        updatedAt
      }
      action {
        id
        name
      }
    }
  }
`;
export const GET_PROJECT_INSTRUCTION_LIST = gql`
  query instructionList($filter: InstructionFilter!) {
    instructionList(filter: $filter) {
      total
      data {
        id
        name
        tags
        description
        type
        status
        reminderDate
        createdAt
        descriptionPhoto
        descriptionAudio
        closingRemark
        closingAudio
        closingPhoto
        closedOn
        isOverdue
        contacts {
          phoneNo
        }
        projectAgency {
          agency {
            name
            type
          }
        }
        creator {
          name
        }
        modifier {
          id
          name
        }
        responder {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_INSTRUCTION = gql`
  query getInstruction($id: ID!) {
    getInstruction(id: $id) {
      id
      name
      description
      tags
      type
      projectAgency {
        agency {
          name
          type
        }
      }
      status
      reminderDate
      creator {
        name
      }
      modifier {
        id
        name
      }
      closer {
        id
        name
      }
      responder {
        id
        name
      }
      tenant {
        id
        organizationName
      }
      instructionAgencyContacts {
        contact {
          name
          phoneNo
        }
      }
      instructionIssueHistories {
        id
        status
        photos
        audios
        projectId
        remark
        revision
        latitude
        longitude
        projectAgencyId
        createdAt
        rejector {
          id
          name
        }
        responder {
          id
          name
        }
        creator {
          id
          name
          email
        }
      }
      instructionHistory {
        id
        modifier {
          id
          name
        }
        createdAt
        afterJson
        beforeJson
      }
      instructionRenameLogs {
        id
        newName
        oldName
        user {
          id
          name
        }
        updatedAt
      }
      createdAt
      descriptionPhoto
      descriptionAudio
      closingRemark
      closingAudio
      closingPhoto
      closedOn
      respondedOn
    }
  }
`;

export const GET_PROJECT_AUDITOR_COMMENTS = gql`
  query auditorCommentList($filter: AuditorCommentFilter!) {
    auditorCommentList(filter: $filter) {
      total
      data {
        id
        comment
        auditor {
          id
          name
        }
        createdAt
      }
    }
  }
`;

export const EQC_PAUSE_LOG_LIST_BY_HISTORY = gql`
  query eqcPauseLogListByHistory($filter: EqcPauseLogInput!) {
    eqcPauseLogListByHistory(filter: $filter) {
      total
      data {
        id
        remark
        pausedAt
        restartedAt
        tenantId
      }
    }
  }
`;

export const GET_PROJECT_EQC_TYPE = gql`
  query getProjectEqcType($id: ID!) {
    getProjectEqcType(id: $id) {
      id
      name
      uom
      iso
      status
      isAudited
      activityLogCount
      projectEqcType {
        id
        status
      }
      projectEqcTypeStageCount
      projectUserEqcTypes {
        id
        projectUser {
          id
          user {
            id
          }
        }
      }
      projectAgencyEqcTypes {
        id
        projectAgency {
          id
        }
      }
      project {
        id
        name
        isActive
        permissions
        isRfiRequestSent
        projectRFIs {
          id
          status
        }
        projectUsers {
          id
          userId
          roles
        }
      }
      creator {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_STAGE_LIST = gql`
  query projectEqcTypeStageList($filter: ProjectEqcTypeStageFilter!) {
    projectEqcTypeStageList(filter: $filter) {
      total
      activeStageCount
      data {
        id
        name
        order
        drawing
        witness
        isReRfiRequired
        needApproval
        stageConfiguration
        itemOrderType
        isActive
        type
        isRfiActive
        rfiStage {
          id
          name
          order
          drawing
          witness
          isReRfiRequired
          needApproval
          stageConfiguration
          itemOrderType
          isActive
          type
          stageRfiId
          projectEqcTypeStageApprovals {
            id
            internalApproverId
            externalApproverId
            levelName
            levelNumber
            externalApprover {
              id
              name
            }
            internalApprover {
              id
              user {
                id
                name
              }
            }
          }
        }
        stageRfiId
        projectEqcTypeStageApprovals {
          id
          approvalType
          internalApproverId
          externalApproverId
          levelName
          levelNumber
          externalApprover {
            id
            name
          }
          internalApprover {
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_STAGE_ITEM_LIST = gql`
  query projectEqcTypeStageItemList($filter: ProjectEqcTypeStageItemFilter!) {
    projectEqcTypeStageItemList(filter: $filter) {
      total
      data {
        id
        title
        type
        order
        options
        photo
        remark
        description
        isActive
        previousHistory
        projectEqcTypeStageItemId
      }
    }
  }
`;

export const GET_INTERNAL_USERS_LIST = gql`
  query internalApproverDropdownList($filter: ProjectUserFilter!) {
    internalApproverDropdownList(filter: $filter) {
      total
      data {
        id
        user {
          id
          name
        }
      }
    }
  }
`;

export const GET_EXTERNAL_USERS_LIST = gql`
  query externalApproverDropdownList($filter: ProjectAgencyFilter!) {
    externalApproverDropdownList(filter: $filter) {
      total
      data {
        id
        name
        agency {
          id
          name
        }
      }
    }
  }
`;

export const GET_INSTRUCTION_RECOMMENDATION_LIST = gql`
  query recommendationList($instructionId: ID!) {
    recommendationList(instructionId: $instructionId) {
      total
      data {
        id
        beforeJson
        afterJson
        modifiedBy
        modifier {
          id
          name
        }
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_EQC_TYPE_COUNT = gql`
  query projectEqcTypeList($filter: ProjectEqcTypeFilter!) {
    projectEqcTypeList(filter: $filter) {
      data {
        id
      }
    }
  }
`;

export const PROJECT_AGENCY_DROPDOWN = gql`
  query projectAgencyDropdownList($filter: ProjectAgencyFilter!) {
    projectAgencyDropdownList(filter: $filter) {
      total
      data {
        id
        agency {
          id
          name
        }
      }
    }
  }
`;

export const INSTRUCTION_TAG_LIST = gql`
  query instructionTagList($filter: InstructionFilter!) {
    instructionTagList(filter: $filter) {
      total
      data
    }
  }
`;
export const PROJECT_USER_DROPDOWN = gql`
  query projectUserDropdownList($filter: ProjectUserFilter!) {
    projectUserDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_EQC_TYPE_VERSION_LOGS = gql`
  query getProjectEqcTypeVersionLogs($id: ID!) {
    getProjectEqcTypeVersionLogs(id: $id) {
      data
    }
  }
`;

export const GET_PROJECT_RFI_VERSION_LOGS = gql`
  query getProjectRFIVersionLogs($projectId: ID!) {
    getProjectRFIVersionLogs(projectId: $projectId) {
      data
    }
  }
`;

export const TARGET_ACTUAL_METRIC_LIST = gql`
  query targetActualMetricList($filter: TargetActualMetricFilter!) {
    targetActualMetricList(filter: $filter) {
      total
      data {
        id
        startDate
        endDate
        target
        eqcCompletedCount
      }
      nextTargetAvailable
      previousTargetAvailable
    }
  }
`;

export const INSTRUCTION_OTHER_TYPE_LIST = gql`
  query instructionOtherTypeList($filter: InstructionFilter!) {
    instructionOtherTypeList(filter: $filter) {
      total
      data
    }
  }
`;

export const PROJECT_NOMENCLATURE_LIST = gql`
  query projectNomenclatureList($filter: NomenclatureFilter!) {
    projectNomenclatureList(filter: $filter)
  }
`;

export const REQUEST_NOMENCLATURE_FEATURE = gql`
  query requestProjectNomenclatureFeature($projectId: ID!) {
    requestProjectNomenclatureFeature(projectId: $projectId) {
      status
      message
      systemAdminList {
        name
        phoneNo
      }
    }
  }
`;

export const PROJECT_RFI_POINTS_LIST = gql`
  query projectRFIPointList($filter: ProjectRFIPointFilter!) {
    projectRFIPointList(filter: $filter) {
      total
      data {
        id
        title
        description
        type
        options
        photo
        remark
        order
        isActive
        projectId
        projectRfiId
        createdBy
        modifiedBy
        tenantId
        isNewPoint
      }
    }
  }
`;

export const GET_PROJECT_RFI = gql`
  query getProjectRFI($projectRFIId: ID!) {
    getProjectRFI(projectRFIId: $projectRFIId) {
      id
      status
      version
      projectId
      projectRfiId
      createdBy
      modifiedBy
      witness
      drawing
      isReRfiRequired
      tenantId
      publishedOn
      publishedBy
      projectRfi {
        id
        status
      }
      activityLogCount
      project {
        id
        name
        isActive
        isRfiActive
        isRfiRequestSent
      }
    }
  }
`;
export const GET_PROJECT_REPORT_CONFIG = gql`
  query getProjectReportConfig($projectId: ID!) {
    getProjectReportConfig(projectId: $projectId) {
      id
      organizationLogoRequired
      organizationLogoSize
      organizationNameRequired
      statusRequired
      photoSectionRequired
      photoSize
      reportTitleRequired
      reportTitle
      projectPhotos
      createdAt
      updatedAt
    }
  }
`;