import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Popover,
  Row,
  Tag,
  Tooltip,
} from 'antd';
import clsx from 'clsx';
import {
  drop,
  forEach,
  groupBy,
  includes,
  isEmpty,
  keys,
  map,
  max,
  nth,
  omit,
  slice,
} from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  Checkmark,
  CloseIcon,
  CommentBlackIcon,
  EditButton,
  InfoIcon,
  InfoIconDark,
  KebabMenu,
  Timer,
} from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DATE_TIME_WITH_AT,
  DEFAULTDATEFORMAT,
  DEFAULTDATETIMEFORMAT,
  DEFAULT_PAGE_SIZE,
  EQC_STATUS,
  EQC_STATUS_CLASSNAME,
  EQC_STATUS_LABEL,
  PROJECT_CHECKLIST_STATUS,
  ROUTES,
  STAGE_STATUS,
  STAGE_STATUS_KEYS,
  STAGE_STATUS_LABEL,
  STAGE_STATUS_TEXT_CLASS,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import {
  removeHistoryStateData,
  titleCase,
} from '../../../../../../common/utils';
import CanPerform from '../../../../../../components/CanPerform';
import CollapsibleFilterWrapper from '../../../../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../../../../components/CommonCard';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../components/CommonTable';
import EllipsisText from '../../../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import {
  GET_PROJECT_EQCTYPES_LIST_MIN,
  GET_PROJECT_EQC_LIST,
  GET_PROJECT_USER_LIST,
} from '../../../../graphql/Queries';
import EditEqcModal from './EditEqcModal';
import MarkQCPassModal from './MarkQCPassModal';

const Eqc = ({ uniqueCode }) => {
  const {
    navigate,
    params: { projectId },
    location,
  } = useRouter();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const eqcFilter = location?.state?.eqcFilter;
  const eqcPagination = location?.state?.eqcPagination;
  const eqcTypeRecord = location?.state?.eqcTypeRecord;
  const userRecord = location?.state?.userRecord;
  const { getCurrentProjectDetail } = useContext(AppContext);
  const currentProjectDetails = getCurrentProjectDetail();

  const initialProjectEqcFilter = {
    skip: 0,
    limit: 10,
    projectId: Number(projectId),
    sortBy: { field: 'doneAt', order: 'DESC' },
    isRfi: false,
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [open, setOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [selectedApproverRecord, setSelectedApproverRecord] = useState();
  const [paginationProp, setPaginationProp] = useState(
    eqcPagination || initialPaginationValue,
  );
  const [projectEqcFilter, setProjectEqcFilter] = useState(
    eqcFilter || initialProjectEqcFilter,
  );
  const [showModal, setShowModal] = useState();
  const [showMarkQCPassModal, setShowMarkQCPassModal] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [isOverdue, setIsOverdue] = useState(eqcFilter?.isOverdue);
  const [eqcStatus, setEqcStatus] = useState(eqcFilter?.eqcStatus);
  const [eqcStageStatus, setEqcStageStatus] = useState(
    eqcFilter?.eqcStageStatus,
  );
  const [userId, setUserId] = useState(userRecord?.[0]?.userId);
  const [projectEqcTypeId, setProjectEqcTypeId] = useState(
    eqcFilter?.projectEqcTypeId,
  );
  const [selectedEqcTypeRecord, setSelectedEqcTypeRecord] =
    useState(eqcTypeRecord);
  const [selectedUserRecord, setSelectedUserRecord] = useState(userRecord);
  const [eqcData, setEqcData] = useState([]);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [eqcListData, setEqcListData] = useState();
  const [isRfi, setIsRfi] = useState(eqcFilter?.isRfi);
  const approversByLevel = groupBy(
    selectedApproverRecord?.eqcStageHistories?.[0]
      ?.projectEqcTypeStageApprovals,
    (b) => {
      return b?.levelNumber;
    },
  );
  const levelsToShowAllApprovers = drop(
    Object.keys(approversByLevel),
    includes(
      [
        STAGE_STATUS_KEYS?.APPROVAL_PENDING,
        STAGE_STATUS_KEYS?.FAIL,
        STAGE_STATUS_KEYS.ACCEPTED,
        STAGE_STATUS_KEYS.REJECTED,
      ],
      selectedApproverRecord?.eqcStageHistories?.[0]?.status,
    )
      ? selectedApproverRecord?.eqcStageHistories?.[0]?.levelNumber - 1
      : selectedApproverRecord?.eqcStageHistories?.[0]?.levelNumber,
  );

  const [fetchEqcData, { loading }] = useLazyQuery(GET_PROJECT_EQC_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.eqcList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.eqcList?.total,
      };
      if (scrollFlag) {
        const datalist = [...eqcListData, ...data];
        setEqcListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setEqcListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    if (eqcTypeRecord) {
      setSelectedEqcTypeRecord(eqcTypeRecord);
    }
  }, [eqcTypeRecord]);

  useEffect(() => {
    if (eqcFilter) {
      setProjectEqcFilter(eqcFilter);
    }
    if (eqcFilter?.projectEqcTypeId) {
      setProjectEqcTypeId(eqcFilter?.projectEqcTypeId);
    }
    if (eqcFilter?.eqcStageStatus) {
      setEqcStageStatus(eqcFilter?.eqcStageStatus);
    }
    if (eqcFilter?.isOverdue) {
      setIsOverdue(eqcFilter?.isOverdue);
    }
    if (eqcFilter?.eqcStatus) {
      setEqcStatus(eqcFilter?.eqcStatus);
    }
    if (eqcFilter?.isRfi) {
      setIsRfi(eqcFilter?.isRfi);
    }
    if (eqcPagination) {
      setPaginationProp(eqcPagination);
    }
    if (userRecord) {
      setSelectedUserRecord(userRecord);
    }
    if (userRecord?.[0]?.userId) {
      setUserId(userRecord?.[0]?.userId);
    }
  }, [eqcFilter, eqcPagination, userRecord]);

  useEffect(() => {
    fetchEqcData({
      variables: { filter: projectEqcFilter },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEqcFilter]);

  useEffect(() => {
    let newFilter = {
      ...projectEqcFilter,
      eqcStageStatus,
      projectEqcTypeId,
      eqcStatus,
      userId,
      skip: projectEqcFilter?.skip !== 0 ? projectEqcFilter?.skip : 0,
      projectId: Number(projectId),
    };
    if (isOverdue) {
      newFilter.isOverdue = isOverdue;
    } else {
      newFilter = omit(newFilter, 'isOverdue');
    }
    setProjectEqcFilter(newFilter);
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eqcStageStatus,
    projectEqcTypeId,
    eqcStatus,
    userId,
    isOverdue,
    projectId,
  ]);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectEqcFilter({
        ...projectEqcFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchEqcData({
        variables: {
          filter: {
            ...projectEqcFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
            },
          },
        },
      });
    } else {
      setProjectEqcFilter({
        ...projectEqcFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'doneAt', order: 'DESC' },
      });

      fetchEqcData({
        variables: {
          filter: {
            ...projectEqcFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'doneAt', order: 'DESC' },
          },
        },
      });
    }
  };
  const getEQCStatus = (eqcStageHistories) => {
    const stageHistory = nth(eqcStageHistories, 0);
    return stageHistory ? stageHistory.status : '-';
  };
  const getStageNameAndStatus = (eqcStageHistories, record) => {
    if (!eqcStageHistories || !eqcStageHistories.length) {
      return '-';
    }
    const { name, status } = nth(eqcStageHistories, 0);
    return (
      <div>
        <span>
          <EllipsisText
            text={
              `${name} (${record?.eqcStageIndex}/${record?.eqcStageCount})` ||
              '-'
            }
          />
        </span>
        {includes(
          [
            STAGE_STATUS?.PASS,
            STAGE_STATUS?.FAIL,
            STAGE_STATUS?.SKIP,
            STAGE_STATUS?.BYPASS,
          ],
          status,
        ) && (
          <span
            className={`text-no-break d-inline-flex ${STAGE_STATUS_TEXT_CLASS[status]}`}
          >
            ({titleCase(STAGE_STATUS[status])})
          </span>
        )}
      </div>
    );
  };

  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    setEqcData({ uniqueCode, id: record?.id, eqcName: record?.eqcName });
    setShowModal(true);
  };

  const handleMarkQCPass = (e, record) => {
    e?.stopPropagation?.();
    setEqcData(record);
    setShowMarkQCPassModal(true);
  };

  const getActionButtons = (record) => {
    const maxLevel = max(
      Object.keys(
        groupBy(
          record?.eqcStageHistories?.[0]?.projectEqcTypeStageApprovals,
          (data) => data?.levelNumber,
        ),
      ),
    );
    const showPassBtn =
      getEQCStatus(record?.eqcStageHistories) === EQC_STATUS.APPROVED &&
      Number(maxLevel) === record?.eqcStageHistories?.[0]?.levelNumber;
    const conditionForEdit =
      CanPerform({
        action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC,
        type: ALLOWED_ACTION_TYPE.PROJECT,
      }) && record?.status !== EQC_STATUS.PASSED;
    const conditionForPassButton =
      CanPerform({
        action: ALLOWED_ACTION_KEYS.MARK_QC_PASS,
        type: ALLOWED_ACTION_TYPE.PROJECT,
      }) && showPassBtn;
    const showDropdownMenu = conditionForEdit || conditionForPassButton;

    return isDesktopViewport ? (
      <div className="d-flex" onClick={(e) => e?.stopPropagation?.()}>
        <CanPerform
          action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC}
          type={ALLOWED_ACTION_TYPE.PROJECT}
        >
          <Tooltip
            title={
              record?.status === EQC_STATUS.PASSED
                ? 'Completed eqc name cannot be changed!'
                : 'Edit'
            }
          >
            <Button
              shape="round"
              disabled={record?.status === EQC_STATUS.PASSED}
              className={clsx(
                record?.status === EQC_STATUS.PASSED && 'disabled-button',
              )}
              icon={<EditButton />}
              onClick={(e) => handleEdit(e, record)}
            />
          </Tooltip>
        </CanPerform>
        {showPassBtn && (
          <CanPerform
            action={ALLOWED_ACTION_KEYS.MARK_QC_PASS}
            type={ALLOWED_ACTION_TYPE.PROJECT}
          >
            <Tooltip title={showPassBtn ? 'Pass' : 'Not Required!'}>
              <Button
                shape="round"
                disabled={!showPassBtn}
                icon={<Checkmark />}
                onClick={(e) => handleMarkQCPass(e, record)}
              />
            </Tooltip>
          </CanPerform>
        )}
      </div>
    ) : (
      showDropdownMenu && (
        <Dropdown
          destroyPopupOnHide
          menu={{
            items: [
              ...(CanPerform({
                action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC,
                type: ALLOWED_ACTION_TYPE.PROJECT,
              })
                ? [
                    {
                      key: 'edit',
                      label: (
                        <span onClick={(e) => handleEdit(e, record)}>Edit</span>
                      ),
                    },
                  ]
                : []),
              ...(conditionForPassButton &&
              CanPerform({
                action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC,
                type: ALLOWED_ACTION_TYPE.PROJECT,
              })
                ? [
                    {
                      type: 'divider',
                    },
                  ]
                : []),
              ...(showPassBtn &&
              CanPerform({
                action: ALLOWED_ACTION_KEYS.MARK_QC_PASS,
                type: ALLOWED_ACTION_TYPE.PROJECT,
              })
                ? [
                    {
                      key: 'mark-qc-pass',
                      label: (
                        <span onClick={(e) => handleMarkQCPass(e, record)}>
                          Pass
                        </span>
                      ),
                    },
                  ]
                : []),
            ],
          }}
          trigger={['click']}
        >
          <KebabMenu />
        </Dropdown>
      )
    );
  };

  const stageStatusTags = (status) => {
    switch (status) {
      case STAGE_STATUS_KEYS.PASS:
        return <Tag className="active-tag">{titleCase(STAGE_STATUS.PASS)}</Tag>;
      case STAGE_STATUS_KEYS.APPROVED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.APPROVED)}</Tag>
        );
      case STAGE_STATUS_KEYS.ACCEPTED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.ACCEPTED)}</Tag>
        );
      case STAGE_STATUS_KEYS.APPROVAL_PENDING:
        return (
          <Tag className="yellow-tag">
            {titleCase(STAGE_STATUS.APPROVAL_PENDING)}
          </Tag>
        );
      case STAGE_STATUS_KEYS.REDO:
        return <Tag color="red">{titleCase(STAGE_STATUS.REDO)}</Tag>;
      case STAGE_STATUS_KEYS.REJECTED:
        return <Tag color="red">{titleCase(STAGE_STATUS.REJECTED)}</Tag>;

      default:
        return <Tag color="red">{titleCase(STAGE_STATUS.FAIL)}</Tag>;
    }
  };

  const getLevelWiseApproversFromPreviousHistory = () => {
    return map(
      selectedApproverRecord?.eqcStageHistories?.[0]?.eqcApprovalLogs,
      (record, index) => {
        return (
          <>
            <Card className="approvers-card">
              <Row justify="space-between" gutter={18}>
                <Col span={14}>
                  <EllipsisText
                    className="text-secondary mb-15 level-name"
                    text={record?.levelName}
                  />
                  {record?.internalApproverName ||
                  record?.externalApproverName ? (
                    <div>
                      By
                      <Tag className="ml-5 approver-name-tag">
                        <EllipsisText
                          text={
                            record?.internalApproverName ||
                            record?.externalApproverName
                          }
                        />
                      </Tag>
                    </div>
                  ) : (
                    '-'
                  )}
                </Col>
                <Col span={10}>
                  <div className="mb-15">
                    {stageStatusTags(record?.approvalStatus)}
                  </div>
                  <div className="font-size-12">
                    {moment(record?.approvedAt || record?.redoAt)
                      ?.tz(currentProjectDetails?.timeZone)
                      ?.format(DATE_TIME_WITH_AT)}
                  </div>
                </Col>
              </Row>
            </Card>
            {(selectedApproverRecord?.eqcStageHistories?.[0]?.eqcApprovalLogs
              ?.length -
              1 >
              index ||
              levelsToShowAllApprovers?.length > 0) && (
              <Divider className="approver-divider" dashed />
            )}
          </>
        );
      },
    );
  };

  const getAllApproversDataByLevel = (level, index) => {
    const fetchName = [];
    if (approversByLevel?.[level]?.length > 0) {
      forEach(approversByLevel?.[level], (record) => {
        fetchName.push(
          record?.internalApproverName || record?.externalApproverName,
        );
      });
    }
    return (
      <>
        <Card className="approvers-card">
          <Row justify="space-between" gutter={18}>
            <Col span={14}>
              <EllipsisText
                className="text-secondary mb-15 level-name"
                text={approversByLevel?.[level]?.[0]?.levelName || '-'}
              />
            </Col>
            <Col span={10}>
              <div className="mb-15">
                {stageStatusTags(STAGE_STATUS_KEYS.APPROVAL_PENDING)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                from
                {fetchName?.length > 0 && (
                  <Tag className="ml-5 approver-name-tag">
                    <EllipsisText text={fetchName?.[0]} />
                  </Tag>
                )}
                {fetchName?.length > 1 && (
                  <Tooltip
                    title={slice(fetchName, 1, fetchName?.length).join(', ')}
                    placement={isDesktopViewport ? 'right' : 'top'}
                  >
                    {`and +${fetchName?.length - 1}`}
                  </Tooltip>
                )}
              </div>
            </Col>
          </Row>
        </Card>
        {levelsToShowAllApprovers?.length - 1 > index && (
          <Divider className="approver-divider" dashed />
        )}
      </>
    );
  };
  const getApprovers = (values, record) => {
    const { levelName, status, projectEqcTypeStageApprovals, eqcApprovalLogs } =
      nth(record?.eqcStageHistories, 0);
    if (
      (isEmpty(projectEqcTypeStageApprovals) && isEmpty(eqcApprovalLogs)) ||
      includes([STAGE_STATUS?.SKIP, STAGE_STATUS?.BYPASS], status)
    ) {
      return '-';
    }
    return (
      <div>
        <div className="d-flex align-center">
          {!includes([STAGE_STATUS?.PASS, STAGE_STATUS?.FAIL], status) &&
            levelName}
          <Popover
            overlayClassName="approver-info"
            onOpenChange={setOpen}
            autoAdjustOverflow={!isDesktopViewport}
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.project-tab-details')
            }
            title={
              <Row
                justify="space-between"
                align="top"
                onClick={(e) => e.stopPropagation()}
              >
                <Col span={20}>
                  <EllipsisText text={record?.eqcName} />
                </Col>
                <Col span={2}>
                  <CloseIcon
                    height={24}
                    width={24}
                    className="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                    }}
                  />
                </Col>
              </Row>
            }
            trigger="click"
            placement={isDesktopViewport ? 'left' : 'top'}
            destroyTooltipOnHide
            open={open && selectedApproverRecord?.id === record?.id}
            content={
              <div onClick={(e) => e.stopPropagation()}>
                {getLevelWiseApproversFromPreviousHistory()}
                {map(levelsToShowAllApprovers, (level, index) => {
                  return getAllApproversDataByLevel(level, index);
                })}
              </div>
            }
          >
            {!includes([STAGE_STATUS?.BYPASS, STAGE_STATUS?.SKIP], status) && (
              <InfoIconDark
                height={18}
                width={18}
                className="approver-info-icon ml-5"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedApproverRecord(record);
                }}
              />
            )}
          </Popover>
          {includes([STAGE_STATUS?.PASS, STAGE_STATUS?.FAIL], status) && (
            <span className="ml-5">Approval log</span>
          )}
        </div>
        {!includes([STAGE_STATUS?.PASS, STAGE_STATUS?.FAIL], status) && (
          <span
            className={`text-no-break d-inline-flex ${STAGE_STATUS_TEXT_CLASS[status]}`}
          >
            ({titleCase(STAGE_STATUS[status])})
          </span>
        )}
      </div>
    );
  };
  const getDateHeader = (record) => {
    return (
      <Popover
        open={popoverVisible && selectedApproverRecord?.id === record?.id}
        destroyTooltipOnHide
        placement="bottom"
        overlayClassName="date-header-popover"
        content={
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h5>This will show date of last EQC stage when synced</h5>
          </div>
        }
        onOpenChange={(visible) => setPopoverVisible(visible)}
      >
        <InfoIcon
          height={16}
          width={16}
          className="info-icon ml-5"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedApproverRecord(record);
          }}
        />
      </Popover>
    );
  };

  const getDueDate = (record) => {
    const isOverDue =
      record?.eqcStageHistories?.[0]?.isRfiStage &&
      (moment(record?.eqcStageHistories?.[0]?.inspectorReminderDate).isBefore(
        moment(),
      ) ||
        record?.eqcStageHistories?.[0]?.isOverdue);
    return (
      <div className="d-flex align-center">
        <div>
          <EllipsisText text={record?.eqcName || '-'} />
        </div>
        {isOverDue && (
          <Tooltip trigger="focus" title="Overdue">
            <div className="d-flex align-center">
              <Timer height="18" width="18" />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            {projectEqcFilter?.skip + index + 1}
            {record?.isAudited && <CommentBlackIcon className="ml-10" />}
          </div>
        );
      },
    },
    {
      title: 'NAME',
      key: 'eqcName',
      dataIndex: 'eqcName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcName' && sortedInfo?.order,
      render: (text, record) => getDueDate(record),
    },
    {
      title: 'TYPE',
      dataIndex: 'projectEqcTypeName',
      key: 'projectEqcTypeName',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'projectEqcTypeName' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText text={text || '-'} />
        </div>
      ),
    },
    {
      title: 'STAGE',
      key: 'eqcStageHistories',
      dataIndex: 'eqcStageHistories',
      render: (text, record) => {
        return getStageNameAndStatus(text, record);
      },
    },
    {
      title: 'APPROVER',
      key: 'approver',
      dataIndex: 'approver',
      render: (values, record) => getApprovers(values, record),
    },
    {
      title: 'EQC STATUS',
      key: 'status',
      dataIndex: 'status',
      render: (value) => (
        <Tag className={EQC_STATUS_CLASSNAME[value]}>
          {EQC_STATUS_LABEL[value]}
        </Tag>
      ),
    },
    {
      title: 'INSPECTED BY',
      key: 'modifier',
      dataIndex: 'modifier',
      render: (values) => values?.name || '-',
    },
    {
      title: (
        <div className="d-flex justify-center align-center tags-col">
          DATE
          {getDateHeader()}
        </div>
      ),
      key: 'doneAt',
      dataIndex: 'doneAt',
      sortOrder: sortedInfo?.columnKey === 'doneAt' && sortedInfo?.order,
      sorter: true,
      render: (updatedAt, record) => {
        return record?.doneAt
          ? moment(`${record?.doneAt}`)
              ?.tz(currentProjectDetails?.timeZone)
              ?.format(DEFAULTDATEFORMAT)
          : '-';
      },
    },
    {
      key: 'actions',
      align: 'right',
      render: (record) => getActionButtons(record),
    },
  ];

  const onSearchChange = async (value) => {
    if (!value) {
      removeHistoryStateData(navigate, location, 'eqcFilter', 'search');
    }
    setProjectEqcFilter({
      ...projectEqcFilter,
      skip: 0,
      search: value,
    });
    setPaginationProp(initialPaginationValue);
    fetchEqcData({
      variables: { filter: { ...projectEqcFilter, skip: 0, search: value } },
    });
  };
  const handleRefetch = () => {
    fetchEqcData({
      variables: {
        filter: {
          ...projectEqcFilter,
          skip: eqcListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'doneAt', order: 'DESC' },
        },
      },
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC}/${record.id}`, {
        state: {
          navFilter,
          navPagination,
          eqcFilter: projectEqcFilter,
          eqcPagination: paginationProp,
          eqcTypeRecord: selectedEqcTypeRecord,
          userRecord: selectedUserRecord,
          currentPath: location?.pathname,
        },
      });
    }
  };
  const items = [
    {
      key: 'filter',
      children: (
        <Row
          className="d-flex justify-end filter-search align-center"
          wrap
          gutter={isDesktopViewport ? [10, 10] : [10, 10]}
        >
          <Col span={isDesktopViewport ? null : 12}>
            <Checkbox
              onChange={(event) => {
                setIsRfi(event?.target?.checked);
                setProjectEqcFilter({
                  ...projectEqcFilter,
                  skip: 0,
                  isRfi: event?.target?.checked,
                });
                setPaginationProp({ ...paginationProp, current: 1 });
                removeHistoryStateData(
                  navigate,
                  location,
                  'eqcFilter',
                  'isRfi',
                );
              }}
              checked={isRfi}
            >
              RFI
            </Checkbox>
          </Col>
          <Col span={isDesktopViewport ? null : 12}>
            <Checkbox
              onChange={(event) => {
                setIsOverdue(event?.target?.checked);
                setProjectEqcFilter({
                  ...projectEqcFilter,
                  skip: 0,
                });
                setPaginationProp({ ...paginationProp, current: 1 });
                removeHistoryStateData(
                  navigate,
                  location,
                  'eqcFilter',
                  'isOverdue',
                );
              }}
              checked={isOverdue}
            >
              RFI Overdue
            </Checkbox>
          </Col>
          <Col span={isDesktopViewport ? null : 12}>
            <CommonDropdown
              placeholder="Checklist"
              className={`instruction-selector dropdown-width-auto ${
                isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
              value={projectEqcTypeId}
              onChange={(id, record) => {
                setSelectedEqcTypeRecord(record);
                setProjectEqcTypeId(id);
                setProjectEqcFilter({ ...projectEqcFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
                if (!id) {
                  removeHistoryStateData(
                    navigate,
                    location,
                    'eqcFilter',
                    'projectEqcTypeId',
                  );
                }
              }}
              allowClear
              showSearch
              optionFilterProp="children"
              query={GET_PROJECT_EQCTYPES_LIST_MIN}
              variables={{
                filter: {
                  projectId: Number(projectId),
                  status: [
                    PROJECT_CHECKLIST_STATUS?.PUBLISHED,
                    PROJECT_CHECKLIST_STATUS?.ARCHIVED,
                  ],
                },
              }}
              responsePath="projectEqcTypeList.data"
              valuePath="id"
              labelPath="name"
              optionKey="eqc-type"
              customOptions={selectedEqcTypeRecord}
              dropdownMatchSelectWidth={false}
            />
          </Col>
          <Col span={isDesktopViewport ? null : 12}>
            <CommonDropdown
              allowClear
              placeholder="User"
              className={`instruction-selector dropdown-width-auto ${
                isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
              onChange={(id, record) => {
                setUserId(id);
                setProjectEqcFilter({ ...projectEqcFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
                setSelectedUserRecord(record);
                if (!id) {
                  removeHistoryStateData(navigate, location, 'userRecord');
                }
              }}
              value={userId}
              query={GET_PROJECT_USER_LIST}
              variables={{ filter: { projectId } }}
              fetchPolicy="network-only"
              responsePath="projectUserList.data"
              valuePath="user.id"
              labelPath="user.name"
              optionKey="user"
              showSearch
              optionFilterProp="children"
              customOptions={selectedUserRecord}
              dropdownMatchSelectWidth={false}
              placement="bottomRight"
            />
          </Col>
          <Col span={isDesktopViewport ? null : 12}>
            <CommonSelect
              placeholder="Stage Status"
              className={`instruction-selector ${
                isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
              value={eqcStageStatus}
              onChange={(stageStatus) => {
                setEqcStageStatus(stageStatus);
                setProjectEqcFilter({ ...projectEqcFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
                if (!stageStatus) {
                  removeHistoryStateData(
                    navigate,
                    location,
                    'eqcFilter',
                    'eqcStageStatus',
                  );
                }
              }}
              allowClear
              options={[
                ...map(keys(STAGE_STATUS_LABEL), (status, index) => {
                  return {
                    key: index,
                    value: status,
                    label: STAGE_STATUS_LABEL[status],
                  };
                }),
              ]}
            />
          </Col>
          <Col span={isDesktopViewport ? null : 12}>
            <CommonSelect
              placeholder="EQC Status"
              className={`instruction-selector ${
                isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
              value={eqcStatus}
              onChange={(status) => {
                setEqcStatus(status);
                setProjectEqcFilter({ ...projectEqcFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
                if (!status) {
                  removeHistoryStateData(
                    navigate,
                    location,
                    'eqcFilter',
                    'eqcStatus',
                  );
                }
              }}
              allowClear
              options={[
                ...map(keys(EQC_STATUS_LABEL), (status) => {
                  return {
                    key: `eqc-status-${status}`,
                    value: status,
                    label: EQC_STATUS_LABEL[status],
                  };
                }),
              ]}
            />
          </Col>
          {isDesktopViewport && (
            <Col>
              <SearchComponent
                className="search-component width-200"
                getData={onSearchChange}
                defaultValue={eqcFilter?.search}
              />
            </Col>
          )}
        </Row>
      ),
    },
  ];
  return (
    <>
      <div className="project-tab-details">
        <div
          className={`project-tab-details-header ${
            isDesktopViewport ? 'd-flex justify-between align-center' : ''
          }`}
        >
          <h2 className="mr-10">Eqc Details</h2>
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: onSearchChange,
              defaultValue: eqcFilter?.search,
            }}
            items={items}
          />
        </div>
        {isDesktopViewport ? (
          <div className="table pointer">
            <CommonTable
              columns={columns}
              data={eqcListData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.key ?? obj?.id}
              loading={loading}
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record),
                };
              }}
            />
          </div>
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={eqcListData?.length}
            skeletonRows={columns?.length - 4}
            onScroll={() => {
              setOpen(false);
              setPopoverVisible(false);
            }}
          >
            {map(eqcListData, (eqc, index) => {
              return (
                <CommonCard key={eqc?.id} onClick={() => handleRowClick(eqc)}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">
                      <div className="d-flex align-center">{index + 1}.</div>
                    </div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">
                          <div className="d-flex align-center">
                            {getDueDate(eqc)}
                          </div>
                        </span>
                        <span>
                          {eqc?.isAudited && (
                            <CommentBlackIcon className="ml-10" />
                          )}
                        </span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Type:</span>
                          {eqc?.projectEqcTypeName}
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Stage:</span>
                          {getStageNameAndStatus(eqc?.eqcStageHistories, eqc)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">EQC Status:</span>
                          <Tag className={EQC_STATUS_CLASSNAME[eqc?.status]}>
                            {EQC_STATUS_LABEL[eqc?.status]}
                          </Tag>
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Approver:</span>
                          {getApprovers(eqc?.approver, eqc)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Updated By:</span>
                          {eqc?.creator?.name}
                        </div>
                        <div>
                          <span className="d-flex align-center fw-medium mr-5 tags-col">
                            Done At {getDateHeader(eqc)}&nbsp;:
                          </span>
                          {eqc?.doneAt
                            ? moment(`${eqc?.doneAt}`)
                                ?.tz(currentProjectDetails?.timeZone)
                                ?.format(DEFAULTDATETIMEFORMAT)
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <span
                      className="d-flex position-absolute user-action-btn"
                      onClick={(e) => {
                        e?.stopPropagation?.();
                      }}
                    >
                      {getActionButtons(eqc)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
      <EditEqcModal
        showModal={showModal}
        setShowModal={setShowModal}
        eqcData={eqcData}
        setEqcData={setEqcData}
        refetchEqcData={() =>
          fetchEqcData({
            variables: { filter: projectEqcFilter },
          })
        }
      />
      {showMarkQCPassModal && (
        <MarkQCPassModal
          showModal={showMarkQCPassModal}
          setShowModal={setShowMarkQCPassModal}
          eqcData={eqcData}
          setEqcData={setEqcData}
          refetchEqcData={() =>
            fetchEqcData({
              variables: {
                filter: {
                  ...projectEqcFilter,
                },
              },
            })
          }
        />
      )}
    </>
  );
};

export default Eqc;
