import { Card } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../../common/constants';
import AgencyContactTable from './AgencyContactTable';

const AgenciesContact = ({ agencyId }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  return (
    <Card className="agency-contact-card setup-module">
      <div
        className={`header align-center justify-between ${
          isDesktopViewport ? 'mb-10 d-flex ' : ''
        } `}
      >
        <h2>Agency Contacts</h2>
        <div id="add-btn-contact" className="d-flex align-center" />
      </div>
      <AgencyContactTable agencyId={agencyId} />
    </Card>
  );
};

export default AgenciesContact;
