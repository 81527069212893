import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
} from 'antd';
import { get, includes, omit, trim, uniq } from 'lodash';
import momentTz from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  ADDONS,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PERMISSIONS,
  REGEX,
  REQUEST_FEATURE_UPGRADE_KEYS,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules } from '../../../common/utils';
import GoogleMapComponent from '../../../components/GoogleMap';
import UpgradeModal from '../../../components/UpgradeModal';
import { UPDATE_PROJECT } from '../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../graphql/Queries';
import DeactivateRfiModal from './DeactivateRfiModal';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const timeZones = momentTz.tz.names();
const timezone = uniq(timeZones)?.map((timeZone, index) => ({
  key: index,
  value: timeZone,
  label: `${timeZone} (GMT${momentTz?.tz(timeZone)?.format('Z')})`,
}));
const EditProjectModal = (props) => {
  const { showModal, setShowModal, projectId, callback } = props;
  const { getCurrentUser, getTenantUser, dispatch } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const currentTenant = getTenantUser();
  const [form] = Form.useForm();
  const [marker, setMarker] = useState(null);
  const [radius, setRadius] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDeactivateRfiModal, setShowDeactivateRfiModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchProjectDetails, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        dispatch({
          type: 'SET_PROJECT_DETAILS',
          data: JSON.stringify(res?.getProject),
        });
        const formData = {
          ...res?.getProject,
          radius: String(res?.getProject?.radius),
        };
        if (res?.getProject?.permissions?.includes(PERMISSIONS.FINGERPRINT)) {
          formData.fingerprint = true;
        }
        if (res?.getProject?.permissions?.includes('LOCATION')) {
          formData.location = true;
        }
        form.setFieldsValue(formData);
        setMarker({
          lat: res?.getProject?.latitude,
          lng: res?.getProject?.longitude,
        });
        setLoading(false);
        setRadius(Number(res?.getProject?.radius));
      },
      onError() {
        setLoading(false);
      },
    },
  );

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_PROJECT, {
        label: GA_LABEL.EDIT_PROJECT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
    },
  });
  const handleCancel = () => {
    setShowModal(false);
    setInputValue('');
    form.resetFields();
  };
  const submitForm = async (values) => {
    const { location, fingerprint } = values;
    const permissions = [];
    if (location) {
      permissions.push(PERMISSIONS.LOCATION);
    }
    if (fingerprint) {
      permissions.push(PERMISSIONS.FINGERPRINT);
    }
    const data = omit({ ...values, permissions }, [
      'location',
      'fingerprint',
      'logo',
    ]);
    data.radius = Number(data?.radius);
    await updateProject({
      variables: { data, id: Number(projectId) },
    });
    handleCancel();
    if (callback) {
      callback({
        variables: {
          id: Number(projectId),
        },
      });
    }
  };
  useEffect(() => {
    if (projectId && showModal) {
      fetchProjectDetails({ variables: { id: Number(projectId) } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, showModal]);

  useEffect(() => {
    form.setFieldsValue({ latitude: marker?.lat, longitude: marker?.lng });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker]);

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={720}
      >
        <div>
          <h2 className="mb-15">Edit Project</h2>
          {loading ? (
            <Skeleton active paragraph={{ rows: 20 }} />
          ) : (
            <Form form={form} layout="vertical" onFinish={submitForm}>
              <Row gutter={[18]}>
                <Col span={isDesktopViewport ? 8 : 24}>
                  <Form.Item
                    rules={[
                      required,
                      characterWithoutWhiteSpace,
                      { max: 50, message: 'Maximum 50 characters allowed' },
                    ]}
                    name="name"
                    label="Name"
                  >
                    <Input allowClear placeholder="Enter Name" />
                  </Form.Item>
                </Col>
                <Col span={isDesktopViewport ? 8 : 24}>
                  <Form.Item
                    rules={[
                      required,
                      characterWithoutWhiteSpace,
                      { max: 20, message: 'Maximum 20 characters allowed' },
                    ]}
                    name="uniqueCode"
                    label="Unique Code"
                  >
                    <Input allowClear placeholder="Enter Project Code" />
                  </Form.Item>
                </Col>
                <Col span={isDesktopViewport ? 8 : 24}>
                  <Form.Item
                    rules={[
                      required,
                      characterWithoutWhiteSpace,
                      { max: 50, message: 'Maximum 50 characters allowed' },
                    ]}
                    name="clientName"
                    label="Client Name"
                  >
                    <Input allowClear placeholder="Enter Client Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      characterWithoutWhiteSpace,
                      {
                        max: 1000,
                        message:
                          'Description cannot be more than 1000 characters',
                      },
                    ]}
                  >
                    <Input.TextArea
                      allowClear
                      autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="mb-10">Permissions</div>
              <Space size={isDesktopViewport ? 50 : 20} className="mb-20">
                <div className="d-flex align-center">
                  <div className="mr-5">Location:</div>
                  <Form.Item
                    name="location"
                    className="m-0"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </div>
                <div className="d-flex align-center">
                  <div className="mr-5">Authentication:</div>
                  <Form.Item
                    name="fingerprint"
                    className="m-0"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </div>
              </Space>
              <div className="mb-10">Access</div>
              <Space size={isDesktopViewport ? 50 : 20}>
                <div className="d-flex align-center">
                  <div className="mr-5">RFI:</div>
                  <Form.Item
                    name="isRfiActive"
                    className="m-0"
                    valuePropName="checked"
                  >
                    <Switch
                      onChange={(e) => {
                        if (
                          e &&
                          !includes(currentTenant?.tenant?.addOn, ADDONS.RFI)
                        ) {
                          setShowUpgradeModal(true);
                          form.setFieldsValue({ isRfiActive: false });
                        } else if (!e) {
                          setShowDeactivateRfiModal(true);
                          form.setFieldsValue({ isRfiActive: true });
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Space>
              <h3 className="mt-25">Site Details</h3>
              <Row gutter={[18]}>
                <Col span={isDesktopViewport ? 6 : 24}>
                  <Form.Item
                    rules={[required]}
                    name="latitude"
                    label="Latitude"
                  >
                    <InputNumber placeholder="Latitude" disabled />
                  </Form.Item>
                  <Form.Item
                    rules={[required]}
                    name="longitude"
                    label="Longitude"
                  >
                    <InputNumber placeholder="Longitude" disabled />
                  </Form.Item>
                </Col>
                <Col span={isDesktopViewport ? 18 : 24}>
                  <Row gutter={[18]}>
                    <Col span={isDesktopViewport ? 8 : 24}>
                      <Form.Item
                        rules={[
                          required,
                          {
                            pattern: REGEX.NUMBER,
                            message: 'Enter a valid number',
                          },
                          {
                            max: 7,
                            message: 'Value cannot be more than 7 characters',
                          },
                        ]}
                        name="radius"
                        label="Radius (m)"
                      >
                        <Input
                          allowClear
                          placeholder="Enter Radius"
                          onChange={(e) => setRadius(Number(e?.target?.value))}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={isDesktopViewport ? 16 : 24}>
                      <Form.Item
                        name="timeZone"
                        label="TimeZone"
                        rules={[required]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Timezone"
                          getPopupContainer={(triggerNode) =>
                            triggerNode?.parentElement
                          }
                          filterOption={(input, option) => {
                            const label = get(option, 'label', '');
                            return label
                              ?.toLowerCase()
                              ?.includes(trim(input?.toLowerCase()));
                          }}
                          options={timezone}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <GoogleMapComponent
                marker={marker}
                setMarker={setMarker}
                radius={radius}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
              <Row justify="end">
                <div className="form-buttons">
                  <Button
                    shape="round"
                    className="cancel-button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button shape="round" type="primary" htmlType="submit">
                    Save
                  </Button>
                </div>
              </Row>
            </Form>
          )}
        </div>
      </Modal>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.RFI}
          isAlreadyRequested={projectData?.getProject?.isRfiRequestSent}
          refetch={() => {
            fetchProjectDetails({ variables: { id: Number(projectId) } });
          }}
        />
      )}
      {showDeactivateRfiModal && (
        <DeactivateRfiModal
          showModal={showDeactivateRfiModal}
          setShowModal={setShowDeactivateRfiModal}
          handleOk={() => {
            setShowDeactivateRfiModal(false);
            form.setFieldsValue({ isRfiActive: false });
          }}
          handleCancel={() => {
            setShowDeactivateRfiModal(false);
            form.setFieldsValue({ isRfiActive: true });
          }}
        />
      )}
    </>
  );
};

export default EditProjectModal;
