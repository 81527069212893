import { Collapse, Space } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../common/constants';
import AgencyAnalysis from './components/AgencyAnalysis';
import Overview from './components/Overview';
import ProjectAnalysis from './components/ProjectAnalysis';
import UserAnalysis from './components/UserAnalysis';

const items = [
  {
    key: '1',
    label: <h3>Overview</h3>,
    children: <Overview />,
  },
  {
    key: '2',
    label: <h3>Project Analysis</h3>,
    children: <ProjectAnalysis />,
  },
  {
    key: '3',
    label: <h3>User Analysis</h3>,
    children: <UserAnalysis />,
  },
  {
    key: '4',
    label: <h3>Agency Analysis</h3>,
    children: <AgencyAnalysis />,
  },
];

const Dashboard = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Dashboard</div>
        </>
      )}
      {isDesktopViewport ? (
        <Space direction="vertical" size={24} className="width-percent-100">
          <Overview />
          <ProjectAnalysis />
          <UserAnalysis />
          <AgencyAnalysis />
        </Space>
      ) : (
        <div className="width-percent-100 mb-20 dashboard-wrapper">
          <Collapse items={items} defaultActiveKey={['1']} expandIconPosition='end' />
        </div>
      )}
    </>
  );
};
export default Dashboard;
