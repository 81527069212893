import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Descriptions, Upload, message } from 'antd';
import { includes } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import alternateLogo from '../../assets/images/alternateLogo.png';
import alternateProfile from '../../assets/images/alternateProfile.png';
import { DeleteButton, EditButton, EditIcon } from '../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  FILE_SIZE_20MB,
  GA_EVENT,
  GA_LABEL,
  SYSTEM_ROLES,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { compressImage, fileUpload, titleCase } from '../../common/utils';
import CanPerform from '../../components/CanPerform';
import CommonCard from '../../components/CommonCard';
import { GET_PROFILE } from '../users/graphql/Queries';
import CropperModal from './CropperModal';
import EditModal from './EditModal';
import { UPDATE_LOGGED_IN_USER, UPDATE_TENANT_LOGO } from './graphql/Mutation';
import { GET_URL } from './graphql/Queries';

const Profile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [showModal, setShowModal] = useState(false);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [filePrefix, setFilePrefix] = useState();
  const [fileObj, setFileObj] = useState();
  const [fileData, setFileData] = useState();
  const [src, setSrc] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showImageSizeError, setShowImageSizeError] = useState(false);
  const [
    showImageSizeErrorForOrganization,
    setShowImageSizeErrorForOrganization,
  ] = useState(false);

  const { data, loading, refetch } = useQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
  });
  const [updateImage] = useMutation(UPDATE_LOGGED_IN_USER, {
    onError() { },
    onCompleted() {
      Event(GA_EVENT.EDIT_USER_LOGO, {
        label: GA_LABEL.EDIT_USER_LOGO,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      refetch();
    },
  });
  const [updateTenantLogo] = useMutation(UPDATE_TENANT_LOGO, {
    onError() { },
    onCompleted() {
      Event(GA_EVENT.EDIT_TENANT_LOGO, {
        label: GA_LABEL.EDIT_TENANT_LOGO,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      refetch();
    },
  });
  const [fetchSignedUrl] = useLazyQuery(GET_URL, {
    onCompleted: (res) => {
      const signedImageUrl = res?.getSignedPutUrl?.signedUrl;
      fileUpload(signedImageUrl, fileObj)
        .then(() => {
          updateImage({
            variables: {
              data: {
                profileImage: res?.getSignedPutUrl?.key,
              },
            },
          });
        })
        .catch(() => {
          message.error('Error while uploading image');
        });
    },
    onError: () => { },
  });

  const handleModal = () => {
    setShowModal(true);
  };
  const readFile = (fileObject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setSrc(reader.result);
    });
    reader.readAsDataURL(fileObject);
  };

  const handleUploadprofileImage = (info) => {
    try {
      fetchSignedUrl({
        variables: {
          data: {
            fileName: `profile-image/${info?.name}`,
            contentType: info?.type,
            acl: 'private',
          },
        },
      });
      setFileObj(info);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while uploading image', error);
    }
  };
  const profileImageProps = {
    accept: '.png,.jpg,.jpeg',
    onChange: (info) => {
      const file = info?.file;
      setShowImageSizeError(false);
      if (file?.size > FILE_SIZE_20MB) {
        setShowImageSizeError(true);
        return false;
      }
      compressImage({
        file,
        fileType: file?.type?.split('/')?.[1],
        callBack: handleUploadprofileImage,
      });
    },
    beforeUpload: () => {
      return false;
    },
  };

  const logoProps = {
    accept: '.png,.jpg,.jpeg',
    onChange: (info) => {
      const file = info?.file;
      setShowImageSizeErrorForOrganization(false);
      if (file?.size > FILE_SIZE_20MB) {
        setShowImageSizeErrorForOrganization(true);
        return false;
      }
      setFilePrefix('tenant-image');
      const callBack = (compressedImage) => {
        setFileData(compressedImage);
        readFile(compressedImage);
      };
      compressImage({
        file,
        fileType: file?.type?.split('/')?.[1],
        callBack,
      });
      setShowCropperModal(true);
    },
    beforeUpload: () => {
      return false;
    },
  };
  const removeProfilePicture = () => {
    updateImage({
      variables: {
        data: {
          profileImage: null,
        },
      },
    });
  };
  const removeOrganisationProfilePicture = () => {
    updateTenantLogo({
      variables: {
        logo: null,
      },
    });
  };
  return (
    <>
      {showModal && (
        <EditModal
          showModal={showModal}
          setShowModal={setShowModal}
          userData={data?.getLoggedInUser}
          refetchUserData={refetch}
        />
      )}
      {showCropperModal && (
        <CropperModal
          showModal={showCropperModal}
          setShowModal={setShowCropperModal}
          filePrefix={filePrefix}
          fileData={fileData}
          refetch={refetch}
          src={src}
          setSrc={setSrc}
          isTenantLogo
        />
      )}
      {!isDesktopViewport && (
        <div className="profile-header d-flex justify-between">
          <div className="page-header text-primary">Personal Details</div>
          <Button
            shape="round"
            type="primary"
            icon={<EditIcon />}
            className="d-flex pointer"
            onClick={() => {
              handleModal();
            }}
          />
        </div>
      )}
      <div className="mobile-card-wrapper">
        <CommonCard className="profile" loading={loading}>
          <div className="personal-details">
            {isDesktopViewport && (
              <div className="profile-header d-flex justify-between">
                <h2>Personal Details</h2>
                <Button
                  shape="round"
                  type="primary"
                  icon={<EditIcon />}
                  className="d-flex pointer"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  Edit
                </Button>
              </div>
            )}
            <div className="profile-content d-flex">
              <div className="profile-content-image">
                <img
                  alt="Profile"
                  src={data?.getLoggedInUser?.profileImage || alternateProfile}
                  className="profile-image"
                />
                <div className="action-btns d-flex">
                  <Upload showUploadList={false} {...profileImageProps}>
                    <EditButton className="edit-btn pointer mr-5" />
                  </Upload>
                  {data?.getLoggedInUser?.profileImage && (
                    <DeleteButton
                      className="delete-btn pointer"
                      onClick={removeProfilePicture}
                    />
                  )}
                </div>
                {showImageSizeError && !isDesktopViewport && (
                  <div className="text-danger font-size-12 mt-10">
                    * Maximum file size is 20MB
                  </div>
                )}
              </div>
              <Descriptions
                layout="vertical"
                column={isDesktopViewport ? 2 : 1}
              >
                <Descriptions.Item label="Name">
                  {data?.getLoggedInUser?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {data?.getLoggedInUser?.email}
                </Descriptions.Item>
                <Descriptions.Item label="Role">
                  {titleCase(data?.getLoggedInUser?.roles[0])}
                </Descriptions.Item>
                <Descriptions.Item label="Phone Number">
                  {data?.getLoggedInUser?.phoneNo}
                </Descriptions.Item>
              </Descriptions>
            </div>
            {showImageSizeError && isDesktopViewport && (
              <div className="text-danger font-size-12">
                * Maximum file size is 20MB
              </div>
            )}
          </div>
        </CommonCard>
      </div>
      {includes(data?.getLoggedInUser?.roles, SYSTEM_ROLES.SYSTEM_ADMIN) && (
        <>
          {!isDesktopViewport && (
            <div className="profile-header d-flex justify-between">
              <div className="page-header text-primary">
                Organization Details
              </div>
            </div>
          )}
          <div className="mobile-card-wrapper">
            <CommonCard className="profile" loading={loading}>
              <div className="organization-details">
                {isDesktopViewport && (
                  <div className="d-flex justify-between mb-10">
                    <h2>Organization Details</h2>
                  </div>
                )}

                <div className="profile-content d-flex">
                  <div className="profile-content-image">
                    <img
                      alt="Logo"
                      src={
                        data?.getLoggedInUser?.tenantUser?.tenant?.logo ||
                        alternateLogo
                      }
                      className="profile-image"
                    />
                    <div className="action-btns d-flex">
                      <CanPerform action={ALLOWED_ACTION_KEYS.EDIT_ORG_LOGO}>
                        <Upload showUploadList={false} {...logoProps}>
                          <EditButton className="edit-btn pointer  mr-5" />
                        </Upload>
                        {data?.getLoggedInUser?.tenantUser?.tenant?.logo && (
                          <DeleteButton
                            className="delete-btn pointer"
                            onClick={removeOrganisationProfilePicture}
                          />
                        )}
                      </CanPerform>
                    </div>
                    {showImageSizeErrorForOrganization &&
                      !isDesktopViewport && (
                        <div className="text-danger font-size-12 mt-10">
                          * Maximum file size is 20MB
                        </div>
                      )}
                  </div>
                  <Descriptions
                    layout="vertical"
                    column={isDesktopViewport ? 2 : 1}
                  >
                    <Descriptions.Item label="Name">
                      {
                        data?.getLoggedInUser?.tenantUser?.tenant
                          ?.organizationName
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label="Plan">
                      {
                        data?.getLoggedInUser?.tenantUser?.tenant?.subscription
                          ?.planObj?.name
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label="User Limit">
                      {data?.getLoggedInUser?.tenantUser?.tenant?.userLimit}
                    </Descriptions.Item>
                    <Descriptions.Item label="Valid Till">
                      {moment(
                        `${data?.getLoggedInUser?.tenantUser?.tenant?.subscription?.validTill}`,
                      ).format(DEFAULTDATEFORMAT)}
                    </Descriptions.Item>
                    <Descriptions.Item label="TimeZone">
                      {data?.getLoggedInUser?.tenantUser?.tenant?.timeZone ||
                        '-'}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                {showImageSizeErrorForOrganization && isDesktopViewport && (
                  <div className="text-danger font-size-12">
                    * Maximum file size is 20MB
                  </div>
                )}
              </div>
            </CommonCard>
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
