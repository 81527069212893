import { map } from 'lodash';
import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { PIE_CHART_COLORS } from '../../../common/constants';

const colors = PIE_CHART_COLORS;
const ChartRenderer = ({ resultSet }) => {
  const data = map(resultSet, (item) => {
    return {
      category: item['ProjectEqcType.name'],
      'EqcStageHistory.count': Number(item['EqcStageHistory.count']),
    };
  });
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={data}
          nameKey="category"
          dataKey="EqcStageHistory.count"
          fill="#8884d8"
        >
          {data.map((e, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ChartRenderer;
