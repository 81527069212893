import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_URL = gql`
  query getSignedPutUrl($data: SignedUrlDataInput) {
    getSignedPutUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const GET_URL_BY_KEY = gql`
  query getImagesSignedUrls($data: ImageSignedUrlDataInput!) {
    getImagesSignedUrls(data: $data)
  }
`;
