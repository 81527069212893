import { useMutation } from '@apollo/client';
import { Alert, Button, Col, Form, Row, Spin, Switch } from 'antd';
import clsx from 'clsx';
import { capitalize, filter, includes, keys, map, omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../../../AppContext';
import {
  ACCESS_TYPE,
  ADDONS,
  EQC_CONFIG_LABELS,
  EQC_STAGE_CONFIG_LABEL,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
  REQUEST_FEATURE_UPGRADE_KEYS,
  ROUTES,
  STAGE_ITEM_SEQUENCE,
  TAB_KEYS,
  WARNINGS,
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../../common/useRouter';
import CommonSelect from '../../../../../../../../components/CommonSelect';
import UpgradeModal from '../../../../../../../../components/UpgradeModal';
import {
  CREATE_PROJECT_EQC_STAGE,
  CREATE_PROJECT_RFI_DRAFT,
  UPDATE_PROJECT_EQC_STAGE,
} from '../../../../../../graphql/Mutation';
import RfiActivationRequiredModal from '../../../rfi/RfiActivationRequiredModal';

const StageSettings = (props) => {
  const {
    isUpdate,
    stageData,
    eqcTypeId,
    refetchRef,
    isDisabled,
    eqcTypeData,
    isEditable,
  } = props;
  const {
    navigate,
    params: { projectId },
  } = useRouter();
  const { getCurrentUser, getTenantUser } = useContext(AppContext);
  const currentTenant = getTenantUser();
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [warning, setWarning] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const [showRfiActivationRequiredModal, setShowRfiActivationRequiredModal] =
    useState(false);
  const [createStage, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_EQC_STAGE,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_CHECKLIST_STAGE, {
          label: GA_LABEL.ADD_PROJECT_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        refetchRef?.current?.refetchStageDetails();
      },
    },
  );
  const [createProjectRFIDraft] = useMutation(CREATE_PROJECT_RFI_DRAFT, {
    onCompleted(res) {
      if (res) {
        setShowRfiActivationRequiredModal(false);
        navigate(
          `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.RFI}/${res?.createProjectRFIDraft?.projectRFIId}/draft`,
        );
      }
    },
    onError() {},
  });

  const [updateStage, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_EQC_STAGE,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_CHECKLIST_STAGE, {
          label: GA_LABEL.EDIT_PROJECT_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        refetchRef?.current?.refetchStageDetails();
      },
    },
  );

  const onSubmitFinish = async (formValues) => {
    const stageConfiguration = [];
    if (formValues?.pass) {
      stageConfiguration.push(EQC_STAGE_CONFIG_LABEL.BYPASS);
    }
    if (formValues?.skip) {
      stageConfiguration.push(EQC_STAGE_CONFIG_LABEL.SKIP);
    }
    let newFormValues = {
      name: formValues?.name,
      projectEqcTypeId: Number(eqcTypeId),
      witness: formValues?.witness || false,
      drawing: formValues?.drawing || false,
      isRfiActive: formValues?.isRfiActive || false,
      stageConfiguration,
      itemOrderType: formValues?.itemOrderType,
    };
    if (isDisabled) {
      newFormValues = omit(newFormValues, [
        'name',
        'witness',
        'drawing',
        'stageConfiguration',
      ]);
    }
    const variables = isUpdate
      ? { data: newFormValues, id: stageData?.id }
      : newFormValues;
    try {
      if (isUpdate) {
        await updateStage({
          variables: { ...variables },
        });
        return;
      }
      await createStage({
        variables: { data: { ...variables } },
      });
    } catch (error) {
      return error;
    }
  };

  const handleFieldsChange = () => {
    if (eqcTypeData?.isPublished) {
      const formData = form.getFieldsValue(true);
      if (formData?.needApproval) {
        setWarning(WARNINGS.APPROVER_CHANGED);
      } else if (stageData?.needApproval && !formData.needApproval) {
        setWarning(WARNINGS.APPROVER_REMOVED);
      } else {
        setWarning('');
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue(stageData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData]);

  return (
    <Form
      form={form}
      initialValues={stageData}
      layout="vertical"
      onFinish={onSubmitFinish}
      onFieldsChange={handleFieldsChange}
      className="stage-config"
    >
      {stageData?.type !== ACCESS_TYPE.INSPECTION && (
        <Alert
          message={
            <>
              This is a view-only tab. System admin or Project admin can make
              changes by clicking on the Project <b>Settings</b> button and
              click
              <b> RFI</b>.
            </>
          }
          type="info"
          showIcon
          className="mb-10 font-size-12"
        />
      )}
      <Spin spinning={updateLoading}>
        {map(
          filter(EQC_CONFIG_LABELS, (type) =>
            includes(
              type?.allowed_module,
              stageData?.type === ACCESS_TYPE.INSPECTION ? 'CHECKLIST' : 'RFI',
            ),
          ),
          (item) => {
            return (
              <div className={clsx('config-div')}>
                <Row
                  justify="space-between notification-row"
                  align="middle"
                  key={item?.key}
                  gutter={{ xs: 0, sm: 30 }}
                >
                  <Col xs={12} sm={16}>
                    <div className="title">{item?.label}</div>
                    <div className="description">{item?.description}</div>
                  </Col>
                  <Col>
                    <div className="d-flex justify-center align-center">
                      {item?.key === 'itemOrderType' ? (
                        <Form.Item name={item?.key} className="nested-form">
                          <CommonSelect
                            placeholder="Sequence"
                            disabled={!isEditable}
                            options={[
                              ...map(
                                keys(STAGE_ITEM_SEQUENCE),
                                (status, index) => {
                                  return {
                                    key: index,
                                    value: status,
                                    label: capitalize(
                                      STAGE_ITEM_SEQUENCE[status],
                                    ),
                                  };
                                },
                              ),
                            ]}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name={item?.key}
                          valuePropName="checked"
                          className="nested-form"
                        >
                          <Switch
                            disabled={
                              (item?.key !== 'needApproval' && isDisabled) ||
                              !isEditable ||
                              stageData?.type !== ACCESS_TYPE.INSPECTION
                            }
                            onChange={(e) => {
                              if (item?.key === 'isRfiActive') {
                                if (
                                  e &&
                                  !includes(
                                    currentTenant?.tenant?.addOn,
                                    ADDONS.RFI,
                                  )
                                ) {
                                  setShowUpgradeModal(true);
                                  form.setFieldsValue({ [item?.key]: false });
                                } else if (
                                  eqcTypeData?.project?.projectRFIs?.[0]
                                    ?.status !==
                                  PROJECT_CHECKLIST_STATUS.PUBLISHED
                                ) {
                                  setShowRfiActivationRequiredModal(true);
                                  form.setFieldsValue({ [item?.key]: false });
                                }
                              }
                            }}
                          />
                        </Form.Item>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          },
        )}
      </Spin>
      {warning && <div className="text-danger">{warning}</div>}
      {isEditable && stageData?.type === ACCESS_TYPE.INSPECTION && (
        <div className="form-buttons mt-15">
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      )}
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.RFI}
          isAlreadyRequested={eqcTypeData?.project?.isRfiRequestSent}
          refetch={() => {
            refetchRef?.current?.getProjectEqcType();
          }}
        />
      )}
      {showRfiActivationRequiredModal && (
        <RfiActivationRequiredModal
          projectSetupRequired
          showModal={showRfiActivationRequiredModal}
          setShowModal={setShowRfiActivationRequiredModal}
          eqcTypeData={eqcTypeData}
          onOk={() => {
            createProjectRFIDraft({
              variables: {
                data: { projectId },
              },
            });
          }}
        />
      )}
    </Form>
  );
};

export default StageSettings;
