import { Image, Modal } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { GA_EVENT, GA_LABEL } from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';

const EqcCommentModal = ({ showModal, setShowModal }) => {
  const commentData = showModal?.itemHistory
    ? get(showModal, 'itemHistory[0].generalComment')
    : get(showModal, 'generalRemark');
  const imageData = showModal?.itemHistory
    ? get(showModal, 'itemHistory[0].generalCommentImages')
    : get(showModal, 'generalRemarkImages');
  const renderBody = (comment, images = []) => {
    return (
      <div className="m-10">
        {comment && (
          <div className=" d-flex">
            <div className="text-no-break">
              <b>Comment: </b>{' '}
            </div>
            <div className="ml-5 text-break">{comment}</div>
          </div>
        )}
        {images?.length > 0 && (
          <>
            <div className="mt-10 mb-10">
              <b>Images:</b>
            </div>
            <div className="comment-img-wrapper">
              <Image.PreviewGroup
                preview={{
                  onVisibleChange: (visible) => {
                    if (visible) {
                      Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                        label: GA_LABEL.OPENED_IMAGE_VIEWER,
                        // eslint-disable-next-line no-undef
                        pathname: window?.location?.href,
                      });
                    }
                  },
                }}
              >
                {React.Children.map(images, (img) => (
                  <Image src={img} alt="comment-images" />
                ))}
              </Image.PreviewGroup>
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <Modal
      maskClosable={false}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={false}
    >
      <h2>View Comment</h2>
      <div className="comment-modal-body">
        {renderBody(commentData, imageData)}
      </div>
    </Modal>
  );
};
export default EqcCommentModal;
