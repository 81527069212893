import { Tabs } from 'antd';
import { find, map, nth, sortBy } from 'lodash';
import React, { useState } from 'react';
import useRouter from '../../../../../../../common/useRouter';
import EllipsisText from '../../../../../../../components/EllipsisText';
import EqcStageDetails from './EqcStageDetails';

const EqcStages = ({ data = {} }) => {
  const { location } = useRouter();
  const requestedStageId = location?.state?.stageId;
  const { eqcStages, eqcStageHistories } = data;
  const stages = sortBy(eqcStages, ['order', 'id']);
  const currentStage = requestedStageId
    ? find(stages, ({ id }) => id === requestedStageId)
    : nth(eqcStageHistories, 0) || nth(stages, 0);
  const [activeKey, setActiveKey] = useState(currentStage?.name);

  const items = map(stages, (stage) => ({
    key: stage?.name,
    label: <EllipsisText text={stage?.name} />,
    children: <EqcStageDetails stage={stage} eqcData={data} />,
  }));
  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
        }}
        destroyInactiveTabPane
        items={items}
      />
    </div>
  );
};

export default EqcStages;
