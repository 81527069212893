import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($data: CreateUserInput!) {
    createUser(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation activateUser($id: ID!) {
    activateUser(id: $id) {
      status
      message
    }
  }
`;

export const IMPORT_USER_CSV = gql`
  mutation importUserCsv($key: String!) {
    importUserCsv(key: $key) {
      insertedData
      invalidData
      insertedDataCount
      invalidDataCount
    }
  }
`;

export const DEACTIVATE_USER_APPROVER = gql`
  mutation deactivateUserApprover(
    $id: ID!
    $data: [DeactivateUserApproverInput!]
  ) {
    deactivateUserApprover(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_USER_APPROVER = gql`
  mutation deleteProjectUserApprover(
    $id: ID!
    $data: [DeactivateUserApproverInput!]
  ) {
    deleteProjectUserApprover(id: $id, data: $data) {
      status
      message
    }
  }
`;
