import { Button, Modal } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import history from '../historyData';

export default function RouterPrompt(props) {
  const { dispatch } = useContext(AppContext);
  const {
    openPrompt = false,
    title = 'Leave this page',
    description = 'Changes made will not be saved, are you sure?',
    okText = 'Yes',
    cancelText = 'No',
    handleContinue,
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [blockedLocation, setBlockedLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (
        !confirmedNavigation &&
        openPrompt &&
        nextLocation.pathname !== location.pathname
      ) {
        setBlockedLocation(nextLocation);
        setShowPrompt(true);
        return true; // Return true to indicate we're blocking
      }
      return false; // Return false to indicate we're not blocking
    },
    [confirmedNavigation, openPrompt, location],
  );
  const handleOK = async () => {
    if (handleContinue) {
      await handleContinue();
    }
    setShowPrompt(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setConfirmedNavigation(true);
  };

  const handleCancel = () => {
    setShowPrompt(false);
    setBlockedLocation(null);
  };

  useEffect(() => {
    if (confirmedNavigation && blockedLocation) {
      navigate(blockedLocation?.pathname, {
        state: blockedLocation?.state,
      });
    }
  }, [confirmedNavigation, blockedLocation, navigate]);

  useEffect(() => {
    if (openPrompt) {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = (event) => {
        // eslint-disable-next-line no-undef
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = description;
        }
        return description;
      };
    } else {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    }

    return () => {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
  }, [openPrompt, description]);

  useEffect(() => {
    // Reset confirmation when location changes
    setConfirmedNavigation(false);
  }, [location]);

  useEffect(() => {
    if (!openPrompt) return;

    const unblock = history.block((tx) => {
      // If we should block navigation
      if (handleBlockedNavigation(tx.location)) {
        return; // Block the navigation
      }

      // Otherwise, allow the navigation
      tx.retry();
    });

    return () => {
      unblock();
    };
  }, [handleBlockedNavigation, openPrompt]);

  return showPrompt ? (
    <div id="router-prompt">
      <Modal
        open={showPrompt}
        footer={null}
        closable={false}
        maskClosable={false}
        centered
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('router-prompt')
        }
      >
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOK}
          >
            {okText}
          </Button>
        </div>
      </Modal>
    </div>
  ) : null;
}
