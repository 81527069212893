import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Col, Popover, Row, Tag, message } from 'antd';
import clsx from 'clsx';
import { keys, map } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { InfoIcon, Timezone } from '../../../assets/svg';
import {
  AGENCY_TYPE,
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  REQUEST_FEATURE_UPGRADE_KEYS,
  ROUTES,
  TAB_KEYS,
} from '../../../common/constants';
import useRouter from '../../../common/useRouter';
import { removeHistoryStateData, titleCase } from '../../../common/utils';
import CollapsibleFilterWrapper from '../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../components/SearchComponent';
import UpgradeModal from '../../../components/UpgradeModal';
import CustomRangePicker from '../../dashboard/components/CustomRangePicker';
import {
  GET_PROJECT_DROPDOWN_LIST,
  INSTRUCTION_OTHER_TYPE_LIST,
  INSTRUCTION_TAG_LIST,
} from '../../projects/graphql/Queries';
import { INSTRUCTION_LOGS } from '../graphql/Queries';

const Instruction = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [selectedDates, setSelectedDates] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const { navigate, location } = useRouter()
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const instructionFilter = location?.state?.instructionFilter;
  const instructionPagination = location?.state?.instructionPagination;
  const projectRecord = location?.state?.projectRecord;
  const initialInstructionLogFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const [paginationProp, setPaginationProp] = useState(
    instructionPagination || initialPaginationValue,
  );

  const [instructionLogFilter, setInstructionLogFilter] = useState(
    instructionFilter || initialInstructionLogFilter,
  );
  const [projectId, setProjectId] = useState(instructionFilter?.projectId);
  const [selectedProjectRecord, setSelectedProjectRecord] = useState();
  const [status, setStatus] = useState(instructionFilter?.status);
  const [latest, setLatest] = useState(instructionFilter?.latest);
  const [type, setType] = useState(instructionFilter?.type);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { getTenantUser } = useContext(AppContext);
  const currentTenant = getTenantUser();

  useEffect(() => {
    if (instructionPagination) {
      setPaginationProp(instructionPagination);
    }
    if (instructionFilter) {
      setInstructionLogFilter(instructionFilter);
    }
    if (instructionFilter?.projectId) {
      setProjectId(instructionFilter?.projectId);
    }
    if (instructionFilter?.status) {
      setStatus(instructionFilter?.status);
    }
    if (projectRecord) {
      setSelectedProjectRecord(projectRecord);
    }
    if (instructionFilter?.tags?.length > 0) {
      setSelectedTags(instructionFilter?.tags);
    }
    if (instructionFilter?.fromDate && instructionFilter?.toDate) {
      setSelectedDates([
        instructionFilter?.fromDate,
        instructionFilter?.toDate,
      ]);
    }
  }, [instructionPagination, instructionFilter, projectRecord]);

  const [fetchInstructionLogs, { loading }] = useLazyQuery(INSTRUCTION_LOGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.instructionActivityLogList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.instructionActivityLogList?.total,
      };
      setPaginationProp(pagination);
      setHasMore(!!data?.length);
      if (scrollFlag) {
        const datalist = [...activityData, ...data];
        setActivityData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setActivityData(datalist);
      }
    },
    onError() { },
  });

  useEffect(() => {
    fetchInstructionLogs({
      variables: { filter: { ...instructionLogFilter } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionLogFilter]);

  useEffect(() => {
    const newFilter = {
      ...instructionLogFilter,
      projectId,
      status,
      skip: instructionLogFilter?.skip !== 0 ? instructionLogFilter?.skip : 0,
      tags: selectedTags,
      type,
      latest,
      fromDate: selectedDates?.[0] && moment(selectedDates?.[0]).startOf('day'),
      toDate: selectedDates?.[1] && moment(selectedDates?.[1]).endOf('day'),
    };
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1,
    });
    setInstructionLogFilter(newFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, projectId, selectedDates, latest, type, selectedTags]);

  const instructionLogsData = async (value) => {
    if (!value) {
      removeHistoryStateData(
        navigate,
        location,
        'instructionFilter',
        'search',
      );
    }
    setInstructionLogFilter({
      ...instructionLogFilter,
      skip: 0,
      limit: paginationProp?.pageSize,
      search: value,
    });
    setPaginationProp(initialPaginationValue);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setInstructionLogFilter({
        ...instructionLogFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
    } else {
      setInstructionLogFilter({
        ...instructionLogFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' },
      });
    }
  };

  const getStatus = (record) => {
    return (
      <Tag className={INS_STATUS_CLASSNAME[record?.status]}>
        {INS_STATUS_LABEL[record?.status]}
      </Tag>
    );
  };

  const handleRefetch = () => {
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          skip: activityData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };
  const getUpdatedBy = (record) => {
    if (record?.status === INS_STATUS_KEYS.RESPONDED) {
      return '-';
    }
    return record?.creator;
  };
  const getRespondedBy = (record) => {
    return record?.status === INS_STATUS_KEYS.RESPONDED
      ? record?.responder || '-'
      : '-';
  };
  const getTagsHeader = () => {
    return (
      <Popover
        destroyTooltipOnHide
        getPopupContainer={() =>
          // eslint-disable-next-line no-undef
          document.querySelector('.instruction')
        }
        placement="bottom"
        overlayClassName="upgrade-popover"
        content={
          <div onClick={(e) => e.stopPropagation()}>
            <h4>Upgrade Require</h4>
            <Button
              type="primary"
              onClick={() => {
                setShowUpgradeModal(true);
              }}
            >
              Click To Upgrade
            </Button>
          </div>
        }
      >
        <InfoIcon height={16} width={16} className="info-icon ml-5" />
      </Popover>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{instructionLogFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'PROJECT',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order,
    },
    {
      title: 'ISSUE NAME',
      dataIndex: '',
      key: 'instructionName',
      render: (text, record) => (
        <div className="text-primary">{record?.instruction?.name}</div>
      ),
    },
    {
      title: 'TYPE',
      dataIndex: '',
      key: 'instructionType',
      render: (record) => {
        return record?.instruction?.type;
      },
    },
    {
      title: (
        <div className="d-flex align-center tags-col">
          TAGS
          {!currentTenant?.tenant?.featureConfig?.issueTag && getTagsHeader()}
        </div>
      ),
      dataIndex: 'tags',
      key: 'tags',
      render: (text, record) => (
        <div className="tags-div">
          {record?.instruction?.tags?.length > 0
            ? record?.instruction?.tags?.map((tag) => <div key={tag}>{`#${tag}`}</div>)
            : '-'}
        </div>
      ),
    },
    {
      title: 'DUE DATE',
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'dueDate' && sortedInfo?.order,
      render: (dueDate, record) => {
        return record?.dueDate ? (
          <div>
            {moment(`${record?.dueDate}`)
              .tz(record?.timeZone)
              .format(DATETIMEWITHDIVIDE)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {record?.timeZone}
            </div>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'UPDATED BY',
      key: 'modifier',
      render: (record) => getUpdatedBy(record),
    },
    {
      title: 'RESPONDED BY',
      key: 'responder',
      render: (record) => getRespondedBy(record),
    },
    {
      title: 'AGENCY',
      dataIndex: 'agencyName',
      key: 'agencyName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'agencyName' && sortedInfo?.order,
      render: (agencyName, record) => {
        return record?.agencyType === AGENCY_TYPE.LOCAL ? (
          <>
            Phone Contact
            <div>{record?.contactPhoneNo}</div>
          </>
        ) : (
          agencyName
        );
      },
    },
    {
      title: 'DATE',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (dueDate, record) => {
        return record?.createdAt ? (
          <div>
            {moment(`${record?.createdAt}`)
              .tz(record?.timeZone)
              .format(DATETIMEWITHDIVIDE)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {record?.timeZone}
            </div>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (text, record) => getStatus(record),
    },
  ];
  const handleRowClick = (record) => {
    if (record?.projectId && record?.instructionId) {
      navigate(
        `${ROUTES.PROJECTS}/${record?.projectId}/${TAB_KEYS.INSTRUCTION}/${record?.instructionId}`,
        {
          state: {
            navFilter,
            navPagination,
            instructionFilter: JSON.stringify(instructionLogFilter),
            instructionPagination: paginationProp,
            projectRecord: selectedProjectRecord,
            currentPath: location?.pathname,
          },
        },
      );
    }
  };

  const items = [
    {
      key: 'filter',
      children: <Row
        justify={isDesktopViewport ? 'end' : 'start'}
        className="d-flex filter-search align-center"
        wrap
        gutter={isDesktopViewport ? [10, 10] : [0, 10]}
      >
        <Col span={isDesktopViewport ? null : 12}>
          <Checkbox
            onChange={(event) => {
              setLatest(event?.target?.checked);
              setInstructionLogFilter({
                ...instructionLogFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
            }}
            checked={latest}
          >
            Latest
          </Checkbox>
        </Col>
        {!isDesktopViewport && <Col span={12} />}
        <Col span={isDesktopViewport ? null : 12}>
          <CommonDropdown
            allowClear
            placeholder="Project"
            className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={projectId}
            onChange={(id, record) => {
              setProjectId(id);
              setSelectedProjectRecord(record);
              setInstructionLogFilter({
                ...instructionLogFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              if (!id) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'projectId',
                );
              }
            }}
            query={GET_PROJECT_DROPDOWN_LIST}
            fetchPolicy="network-only"
            responsePath="projectDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="project"
            showSearch
            optionFilterProp="children"
            customOptions={selectedProjectRecord}
            dropdownMatchSelectWidth={false}
          />
        </Col>
        <Col span={isDesktopViewport ? null : 12}>
          <CommonDropdown
            placeholder="Type"
            className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={type}
            onChange={(value) => {
              setType(value);
              setInstructionLogFilter({
                ...instructionLogFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              if (!value) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'type',
                );
              }
            }}
            allowClear
            showSearch
            optionFilterProp="children"
            query={INSTRUCTION_OTHER_TYPE_LIST}
            responsePath="instructionOtherTypeList.data"
            valuePath="type"
            labelPath="type"
            optionKey="type"
            uniqueByLabel="type"
            dropdownMatchSelectWidth={false}
            placement="bottomRight"
          />
        </Col>
        <Col span={isDesktopViewport ? null : 12}>
          <CommonDropdown
            placeholder="Tag"
            mode="multiple"
            maxTagTextLength={5}
            showArrow
            className={`instruction-selector dropdown-width-auto tags-dropdown ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={selectedTags}
            onChange={(tag) => {
              if (tag?.length <= 2) {
                setSelectedTags(tag);
                setInstructionLogFilter({
                  ...instructionLogFilter,
                  skip: 0,
                });
                setPaginationProp({ ...paginationProp, current: 1 });
              } else if (tag?.length > 2) {
                message.destroy();
                message.error('Only 2 tags are allowed!');
              }
              if (!tag) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'projectAgencyId',
                );
              }
            }}
            allowClear
            showSearch
            optionFilterProp="children"
            query={INSTRUCTION_TAG_LIST}
            responsePath="instructionTagList.data"
            valuePath="tag"
            labelPath="tag"
            optionKey="tag"
            uniqueByLabel="tag"
            dropdownMatchSelectWidth={false}
            placement="bottomRight"
          />
        </Col>
        <Col span={isDesktopViewport ? null : 12}>
          <CommonSelect
            allowClear
            name="status"
            placeholder="Status"
            className={`instruction-selector ${isDesktopViewport ? 'width-200' : 'width-percent-100'
              } `}
            value={status}
            onChange={(eqcStatus) => {
              setStatus(eqcStatus);
              setInstructionLogFilter({
                ...instructionLogFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              if (!eqcStatus) {
                removeHistoryStateData(
                  navigate,
                  location,
                  'instructionFilter',
                  'status',
                );
              }
            }}
            options={[
              ...keys(INS_STATUS_KEYS).map((statusText) => (
                {
                  value: statusText,
                  label: titleCase(statusText),
                  key: statusText,
                }
              )),
            ]}
          />
        </Col >
        {isDesktopViewport && (
          <Col>
            <SearchComponent
              id="search-container-id"
              className="search-component width-200"
              getData={instructionLogsData}
              defaultValue={instructionFilter?.search}
            />
          </Col>
        )}
        <Col span={isDesktopViewport ? null : 12}>
          <CustomRangePicker
            className={clsx(!isDesktopViewport && 'width-percent-100')}
            setDateSelected={(data) => {
              setSelectedDates(data);
              setInstructionLogFilter({
                ...instructionLogFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
            }}
            selectedDates={selectedDates}
            handleClear={() => {
              setInstructionLogFilter({
                ...instructionLogFilter,
                skip: 0,
              });
              setPaginationProp({ ...paginationProp, current: 1 });
              removeHistoryStateData(
                navigate,
                location,
                'instructionFilter',
                'fromDate',
              );
              removeHistoryStateData(
                navigate,
                location,
                'instructionFilter',
                'toDate',
              );
            }}
          />
        </Col>
      </Row >,
    },
  ]
  return (
    <div>
      <div className="d-flex justify-between width-percent-100">
        {isDesktopViewport && <h1>Activity Details</h1>}
        <div
          className={isDesktopViewport ? 'logs-filter' : 'width-percent-100'}
        >
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: instructionLogsData,
              defaultValue: instructionFilter?.search,
            }}
            className="mb-15"
            items={items}
          />
        </div>
      </div>

      {isDesktopViewport ? (
        <div className="table">
          <CommonTable
            loadingData={loading}
            columns={columns}
            data={activityData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowClassName="pointer"
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record),
              };
            }}
          />
        </div>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="instruction-scroll-wrapper"
          dataLength={activityData?.length}
          skeletonRows={columns?.length - 2}
        >
          {map(activityData, (activity, index) => {
            return (
              <CommonCard key={activity?.id} onClick={handleRowClick}>
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">{index + 1}.</div>
                  <div>
                    <div className="card-header fw-medium">
                      <span className="text-break">
                        {activity?.projectName}
                      </span>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Issue Name:</span>
                        <NavLink
                          className="text-primary text-underline pointer text-break"
                          to={`${ROUTES.PROJECTS}/${activity?.projectId}/${TAB_KEYS.INSTRUCTION}/${activity?.instructionId}`}
                        >
                          {activity?.instruction?.name}
                        </NavLink>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Type:</span>
                        <span className="text-break">
                          {activity?.instruction?.type}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="d-flex align-center fw-medium mr-5 tags-col">
                          Tags
                          {!currentTenant?.tenant?.featureConfig?.issueTag &&
                            getTagsHeader()}
                          &nbsp;:
                        </span>
                        <span className="text-break">
                          {activity?.instruction?.tags?.length > 0
                            ? activity?.instruction?.tags?.map((tag) => (
                              <div key={tag}>{`#${tag}`}</div>
                            ))
                            : '-'}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Due Date:</span>{' '}
                        <span>
                          {activity?.dueDate ? (
                            <div>
                              {moment(`${activity?.dueDate}`)
                                .tz(activity?.timeZone)
                                .format(DATETIMEWITHDIVIDE)}
                              <div className="d-flex align-center timezone-div">
                                <Timezone title="timeZone" />
                                {activity?.timeZone}
                              </div>
                            </div>
                          ) : (
                            '-'
                          )}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Updated By:</span>
                        {getUpdatedBy(activity)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Responded By:</span>
                        {getRespondedBy(activity)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Agency:</span>
                        <span className="text-break">
                          {activity?.agencyName || '-'}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Date:</span>
                        {activity?.createdAt ? (
                          <div>
                            {moment(`${activity?.createdAt}`)
                              .tz(activity?.timeZone)
                              .format(DATETIMEWITHDIVIDE)}
                            <div className="d-flex align-center timezone-div">
                              <Timezone title="timeZone" />
                              {activity?.timeZone}
                            </div>
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                      <div>
                        <span className="fw-medium mr-5">Status:</span>
                        {getStatus(activity)}
                      </div>
                    </div>
                  </div>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.ISSUE_TAG}
          isAlreadyRequested={
            currentTenant?.tenant?.featureApprovalRequestConfig
              ?.issueTagRequestSent
          }
        />
      )}
    </div>
  );
};

export default Instruction;
