import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_EQC_APPROVAL_LOGS = gql`
  query getUserEqcApprovalLogs($filter: EqcApprovalLogInput!) {
    getUserEqcApprovalLogs(filter: $filter) {
      total
      data {
        id
        updatedAt
        levelName
        levelNumber
        tenant {
          id
          organizationName
        }
        approvalDetails
        eqc {
          id
          eqcName
          projectEqcTypeName
          project {
            timeZone
            id
            name
          }
        }
        eqcStageHistory {
          id
          name
          status
          eqcStageId
          levelName
          isRfiStage
          levelNumber
          inspectorReminderDate
        }
        approvalSender {
          id
          name
          email
        }
        internalApprover {
          id
          project {
            id
            name
          }
          user {
            id
            name
          }
        }
        eqcStage {
          id
          name
          status
          eqcStageApprovals {
            id
            internalApproverId
            levelName
            levelNumber
            externalApproverId
            internalApproverName
            externalApproverName
          }
        }
        approvalUrl
      }
    }
  }
`;

export const GET_USER_EQC_APPROVAL_LOGS_COUNT = gql`
  query getUserEqcApprovalLogs($filter: EqcApprovalLogInput!) {
    getUserEqcApprovalLogs(filter: $filter) {
      total
    }
  }
`;

export const ACTIVITY_LOGS = gql`
  query eqcActivityLogList($filter: EqcActivityLogFilter) {
    eqcActivityLogList(filter: $filter) {
      total
      data {
        id
        projectId
        projectName
        eqcType
        eqcStageName
        eqcId
        stageId
        eqcStagesId
        eqcName
        eqcStatus
        eqcStageStatus
        creatorName
        externalApproverName
        internalApproverName
        timeZone
        eqcStageApprovals {
          id
          internalApproverId
          externalApproverId
          internalApproverName
          externalApproverName
        }
        dueDate
        createdAt
      }
    }
  }
`;

export const EQC_LIST = gql`
  query eqcTypeDropdownList($filter: EqcTypeFilter) {
    eqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
