import { Card } from 'antd';
import { capitalize, map, upperCase } from 'lodash';
import React from 'react';

const VersionLogCard = ({ data, key }) => {
  const getTitle = (record) => {
    switch (record?.type) {
      case 'GENERAL':
        return (
          <div className="title">
            {record?.field === 'iso'
              ? 'Reference Number'
              : upperCase(record?.field)}
            <span className="info-text"> (General)</span>
          </div>
        );
      case 'STAGE':
        return (
          <div className="title">
            {capitalize(record?.name)}
            <span className="info-text"> (Checklist Stage)</span>
          </div>
        );
      case 'STAGE_ITEM':
        return (
          <div className="title">
            {capitalize(record?.stageName)}
            <span className="info-text"> (Checklist Stage)</span>-{' '}
            {capitalize(record?.name)}
            <span className="info-text"> (Checklist Item)</span>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <Card bordered className="version-log-card" key={key}>
      {getTitle(data)}
      <ol type="a" className="mt-16">
        {data?.data ? (
          map(data?.data, (record, index) => {
            return (
              <li key={index}>{record?.message?.message || record?.message}</li>
            );
          })
        ) : (
          <li>{data?.message}</li>
        )}
      </ol>
    </Card>
  );
};

export default VersionLogCard;
