import { Tabs } from 'antd';
import React from 'react';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import CanPerform from '../../../../../../components/CanPerform';
import HasAccess from '../../../../../../components/HasAccess';
import Agencies from '../../../../../agencies/Agencies';
import EqcType from '../../../../../eqctype/EqcType';
import Users from '../../../../../users/Users';

function Setup() {
  const { navigate, params: { tab } } = useRouter()
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });

  const items = [
    ...(CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_USERS_PAGE }) ?
      [{
        key: TAB_KEYS.USERS,
        label: 'Users',
        children: <div className="details">
          <Users />
        </div>,
      }] : []),
    ...(hasInspectionAccess && CanPerform({
      action: ALLOWED_ACTION_KEYS.VIEW_CHECKLISTS_PAGE,
    }) ?
      [{
        key: TAB_KEYS.CHECKLISTS,
        label: 'Checklist',
        children: <div className="details">
          <EqcType />
        </div>,
      }] : []),
    ...(CanPerform({
      action: ALLOWED_ACTION_KEYS.VIEW_AGENCY_PAGE,
    }) ?
      [{
        key: TAB_KEYS.AGENCIES,
        label: 'Agencies',
        children: <div className="details">
          <Agencies />
        </div>,
      }] : []),
  ];

  return (
    <div className="project-details setup-module">
      <div className="mobile-card-wrapper">
        <div className="details-content">
          <Tabs
            onTabClick={(key) => navigate(`${ROUTES.SETUP}/${key}`)}
            activeKey={tab}
            destroyInactiveTabPane
            items={items}
          />
        </div>
      </div>
    </div>
  );
}

export default Setup;
