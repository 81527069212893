import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_NOTIFICATION_SETTING = gql`
  mutation updateNotificationSetting($data: UpdateNotificationSetting!) {
    updateNotificationSetting(data: $data) {
      status
      message
    }
  }
`;
