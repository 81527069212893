import React from 'react';
import RfiPointsTable from './RfiPointsTable';

const RfiPointsList = ({ RFIData, isEditable }) => {
  return (
    <div className="stage-item-card project-checklist">
      <div className="header d-flex align-center justify-end mb-10">
        <div className="eqc-stage-header-buttons d-flex">
          <div id="stageitem-add-btn" />
        </div>
      </div>
      <RfiPointsTable RFIData={RFIData} isEditable={isEditable} />
    </div>
  );
};

export default RfiPointsList;
