import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Steps,
  Switch,
  Tooltip,
  message,
} from 'antd';
import clsx from 'clsx';
import { filter, findIndex, keys, map, omit, reverse, values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  EditButton,
  MinusCircle,
  WrongIcon,
} from '../../../../../../assets/svg';
import {
  ACCESS_TYPE,
  ADD_AGENCY_STEPS,
  AGENCY_TYPE_KEYS,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules, titleCase } from '../../../../../../common/utils';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import EllipsisText from '../../../../../../components/EllipsisText';
import HasAccess from '../../../../../../components/HasAccess';
import AddContactModal from '../../../../../agencies/contacts/AddContactModal';
import {
  CREATE_PROJECT_AGENCY,
  UPDATE_PROJECT_AGENCY,
} from '../../../../graphql/Mutation';
import {
  GET_AGENCY_CONTACTS_DROPDOWN_LIST,
  GET_PROJECT_AGENCY_DROPDOWN_LIST,
  GET_PROJECT_AGENCY_EQC_TYPES_LIST,
} from '../../../../graphql/Queries';

const AddAgencyModal = (props) => {
  const {
    showModal,
    setShowModal,
    isUpdate,
    agencyData,
    setAgencyData,
    refetchAgencyData,
  } = props;
  const isTabletViewport = useMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const {
    params: { projectId },
  } = useRouter();
  const [form] = Form.useForm();
  const { required, characterWithoutWhiteSpace } = formValidatorRules;
  const [agencyId, setAgencyId] = useState(agencyData?.agencyId);
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSelectAllContacts, setIsSelectAllContacts] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [current, setCurrent] = useState(0);
  const [step1formDetails, setStep1formDetails] = useState();
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState();
  const [showUserModal, setShowUserModal] = useState(false);
  const [isGlobal, setIsGlobal] = useState(true);
  const [allEqcType, setAllEqcType] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);

  const handleCancel = () => {
    setShowModal(false);
    setAgencyData();
    form.resetFields();
  };
  useEffect(() => {
    if (hasInspectionAccess) {
      form.setFieldsValue({
        projectEqcTypeIds: agencyData?.projectAgencyEqcs?.map(
          (eqcValue) => eqcValue?.id,
        ),
      });
    }

    if (agencyData) {
      form.setFieldsValue({
        agencyId: agencyData?.agency?.name,
        contactIds: agencyData?.projectAgencyContacts?.map(
          (contactValue) => contactValue?.contact?.id,
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [createProjectAgency, { loading: updateLoading }] = useMutation(
    CREATE_PROJECT_AGENCY,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_AGENCY, {
          label: GA_LABEL.ADD_PROJECT_AGENCY,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setAgencyData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyData();
      },
    },
  );

  const [updateProjectAgency, { loading: createLoading }] = useMutation(
    UPDATE_PROJECT_AGENCY,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_AGENCY, {
          label: GA_LABEL.EDIT_PROJECT_AGENCY,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_agency_id: agencyData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setAgencyData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyData();
      },
    },
  );

  const onAgencySubmitFinish = async (formValues) => {
    if (current === 0) {
      setStep1formDetails(formValues);
      setCurrent(1);
      return;
    }
    if (!isGlobal && !userList?.length > 0) {
      message.destroy();
      message.error('Please add at least one contact!');
      return;
    }
    const newFormValues = {
      contactIds: isSelectAllContacts ? [] : formValues?.contactIds,
      projectEqcTypeIds: isSelectAll ? [] : step1formDetails?.projectEqcTypeIds,
      selectedAllContacts: isSelectAllContacts,
      selectedAllEqcTypes: isSelectAll,
      contacts: userList,
    };
    let variables = isUpdate
      ? {
          data: {
            ...newFormValues,
          },
          id: agencyData?.id,
        }
      : {
          ...formValues,
          agencyId: step1formDetails?.agencyId,
          agency: {
            name: step1formDetails?.agencyName,
            type: step1formDetails?.type,
          },
          ...newFormValues,
        };
    if (!hasInspectionAccess) {
      if (isUpdate) {
        variables.data = omit(variables?.data, ['projectEqcTypeIds']);
      } else {
        variables = omit(variables, ['projectEqcTypeIds']);
      }
    }
    if (isGlobal) {
      variables = omit(variables, ['agency']);
    }
    try {
      if (isUpdate) {
        await updateProjectAgency({
          variables: { ...variables },
        });
        return;
      }
      createProjectAgency({
        variables: {
          data: {
            ...variables,
            projectId,
          },
        },
      });
    } catch (error) {
      return error;
    }
  };
  const handleDeselect = () => {
    setIsSelectAll(false);
    form.setFieldsValue({ projectEqcTypeIds: [] });
  };
  useEffect(() => {
    if (isSelectAll) {
      form.setFieldsValue({ projectEqcTypeIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAll]);
  const handleContactsDeselect = () => {
    setIsSelectAllContacts(false);
    form.setFieldsValue({ contactIds: [] });
  };
  useEffect(() => {
    if (isSelectAllContacts) {
      form.setFieldsValue({ contactIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllContacts]);
  const handleAddUser = () => {
    setShowUserModal(true);
  };

  const handleEdit = (user) => {
    setShowUserModal(true);
    setUserData(user);
  };

  const handleCallback = (dataList) => {
    if (dataList?.projectAgencyEqcTypeDropdownList?.data?.length > 0) {
      setAllEqcType((prev) => [
        ...prev,
        ...dataList?.projectAgencyEqcTypeDropdownList?.data,
      ]);
    }
    if (dataList?.agencyDropdownList?.data?.length > 0) {
      setAllAgencies((prev) => [
        ...prev,
        ...dataList?.agencyDropdownList?.data,
      ]);
    }
  };
  const step1Form = (
    <>
      {!isUpdate && (
        <Form.Item label="Pick From Global ">
          <Switch checked={isGlobal} onChange={setIsGlobal} />
        </Form.Item>
      )}
      {isGlobal ? (
        <Form.Item name="agencyId" label="Agency" rules={[required]}>
          <CommonDropdown
            placeholder="Select Agency"
            onChange={(e) => {
              setCurrentPage(0);
              setAgencyId(e);
              setIsSelectAllContacts(false);
              form.setFieldsValue({ contactIds: [] });
            }}
            disabled={!!isUpdate}
            showSearch
            optionFilterProp="children"
            allowClear
            query={GET_PROJECT_AGENCY_DROPDOWN_LIST}
            variables={{
              filter: { projectId },
            }}
            fetchPolicy="network-only"
            responsePath="agencyDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="user"
            showExtraLoader
            callback={handleCallback}
            addExtraData={allAgencies}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            rules={[
              required,
              characterWithoutWhiteSpace,
              {
                max: 250,
                message: 'Name cannot be more than 250 characters',
              },
            ]}
            name="agencyName"
            label="Agency"
          >
            <Input allowClear placeholder="Enter Agency Name" />
          </Form.Item>
          <Form.Item
            rules={[{ required, message: 'Required' }]}
            name="type"
            label="Type"
          >
            <CommonSelect
              className="mr-3"
              placeholder="Select Type"
              allowClear
              options={[
                ...values(AGENCY_TYPE_KEYS).map((type) => {
                  return {
                    key: type,
                    value: type,
                    label: titleCase(type),
                  };
                }),
              ]}
            />
          </Form.Item>
        </>
      )}

      {hasInspectionAccess && (
        <Form.Item
          label={
            <div className="d-flex justify-between width-percent-100">
              <div>Checklist</div>
              {isSelectAll && (
                <div
                  className="text-primary pointer"
                  role="button"
                  tabIndex="0"
                  onClick={handleDeselect}
                >
                  Deselect All
                </div>
              )}
            </div>
          }
          name="projectEqcTypeIds"
          normalize={(value) => {
            if (value.includes('all')) {
              setIsSelectAll(true);
              return ['all'];
            }
            return value;
          }}
        >
          <CommonDropdown
            placeholder="Select Checklist"
            mode="multiple"
            disabled={isSelectAll}
            showSearch
            optionFilterProp="children"
            allowClear
            query={GET_PROJECT_AGENCY_EQC_TYPES_LIST}
            variables={{
              filter: {
                projectId: Number(projectId),
                status: PROJECT_CHECKLIST_STATUS?.PUBLISHED,
                projectAgencyId: agencyData?.id,
              },
            }}
            fetchPolicy="network-only"
            responsePath="projectAgencyEqcTypeDropdownList.data"
            valuePath="id"
            labelPath="name"
            isSelectedAll={isSelectAll}
            optionKey="eqc-type"
            hasSelectAll
            showExtraLoader
            callback={handleCallback}
            addExtraData={allEqcType}
          />
        </Form.Item>
      )}
      {!isGlobal && (
        <span className="text-danger note-text">
          Note: This agency will also be added at global level.
        </span>
      )}
      <div className="form-buttons">
        <Button
          shape="round"
          type="primary"
          className="save-button"
          htmlType="submit"
        >
          Next
        </Button>
      </div>
    </>
  );
  const step2Form = (
    <>
      {isGlobal && (
        <>
          <Form.Item
            name="contactIds"
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>Select Global Contact</div>
                {isSelectAllContacts && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleContactsDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectAllContacts(true);
                return ['all'];
              }
              return value;
            }}
            rules={[
              {
                required: userList?.length < 1,
                message: 'This field is required!',
              },
            ]}
          >
            <CommonDropdown
              allowClear
              showSearch
              mode="multiple"
              disabled={isSelectAllContacts}
              placeholder="Select Contact"
              optionFilterProp="children"
              query={GET_AGENCY_CONTACTS_DROPDOWN_LIST}
              variables={{ filter: { projectId, agencyId } }}
              fetchPolicy="network-only"
              responsePath="projectAgencyContactDropdownList.data"
              valuePath="id"
              labelPath="name"
              optionKey="contact-users"
              currentPage={currentPage}
              useEffectDeps={[agencyId]}
              conditionToCheckBeforeQuery={!!agencyId}
              projectId={agencyId}
              isSelectedAll={isSelectAllContacts}
              hasSelectAll
              showArrow
              showExtraLoader
            />
          </Form.Item>
          <Divider dashed />
        </>
      )}
      <div className="create-agency-title">Create Contact</div>
      {userList?.length > 0 &&
        map(userList, (user, index) => (
          <>
            <Row
              gutter={10}
              align="middle"
              justify="space-between"
              className="mt-15"
              key={index}
            >
              <Col span={22}>
                <Card className="user-card">
                  <Row justify="space-between" align="middle">
                    <Col span={20}>
                      <EllipsisText text={user?.name} />
                    </Col>
                    <Col span={2}>
                      <Tooltip trigger="focus" title="Edit" placement="bottom">
                        <Button
                          shape="round"
                          className="b-0"
                          icon={<EditButton height={28} width={28} />}
                          onClick={() => handleEdit(user)}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={2}>
                <Popconfirm
                  title="Are you sure you want to remove this contact?"
                  onConfirm={() => {
                    userList?.splice(index, index + 1);
                    setUserList([...userList]);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip trigger="focus" title="Remove" placement="bottom">
                    <Button className="b-0" icon={<MinusCircle />} />
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
            {filter(
              map(userList, 'email'),
              (data) => data?.toLowerCase() === user?.email?.toLowerCase(),
            )?.length > 1 ? (
              <Row>
                <div className="text-danger mt-5">Email ID already exists</div>
              </Row>
            ) : (
              filter(map(userList, 'phoneNo'), (data) => data === user?.phoneNo)
                ?.length > 1 && (
                <Row>
                  <div className="text-danger mt-5">
                    Phone number already exists
                  </div>
                </Row>
              )
            )}
          </>
        ))}
      <Button
        type="link"
        className="mt-15"
        icon={<PlusOutlined />}
        onClick={handleAddUser}
      >
        Add User
      </Button>
    </>
  );

  const renderFrom = {
    0: step1Form,
    1: step2Form,
  };

  return (
    <div id="add-edit-agency-modal">
      {showUserModal && (
        <AddContactModal
          showModal={showUserModal}
          setShowModal={setShowUserModal}
          agencyContactData={userData}
          isUpdate={!!userData}
          setAgencyContactData={setUserData}
          setUserList={setUserList}
          userList={userList}
          onlyGetContactData
          agencyId={findIndex(userList, userData)}
        />
      )}
      <Modal
        maskClosable={false}
        centered
        form={form}
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<WrongIcon />}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('add-edit-agency-modal')
        }
      >
        <div
          className={clsx(
            'd-flex align-center',
            isTabletViewport && 'justify-center',
          )}
        >
          <Steps
            className="mb-24 mt-5"
            current={current}
            items={[
              ...map(reverse(keys(ADD_AGENCY_STEPS)), (stepItem) => {
                return {
                  key: stepItem,
                  title: ADD_AGENCY_STEPS[stepItem],
                  id: `step-${stepItem}`,
                };
              }),
            ]}
          />
        </div>
        <Form layout="vertical" form={form} onFinish={onAgencySubmitFinish}>
          {renderFrom[current]}
          {current !== 0 && (
            <div className="form-buttons">
              <Button
                shape="round"
                className="cancel-button"
                onClick={() => {
                  if (current === 1) {
                    setCurrent(0);
                  } else {
                    handleCancel();
                  }
                }}
              >
                {current === 1 ? 'Previous' : 'Cancel'}
              </Button>
              <Button
                shape="round"
                type="primary"
                className="save-button"
                htmlType="submit"
                loading={updateLoading || createLoading}
              >
                {isUpdate ? 'Save' : 'Add'}
              </Button>
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AddAgencyModal;
