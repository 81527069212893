import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_REPORT_ASSETS = gql`
  query reportAssets($urlString: String) {
    reportAssets(urlString: $urlString) {
      reportName
      type
      images
      audios
      pdf
    }
  }
`;
