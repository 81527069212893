import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_EQC_TYPE = gql`
  mutation createEqcType($data: CreateEqcTypeInput) {
    createEqcType(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_EQC_TYPE = gql`
  mutation updateEqcType($id: ID!, $data: UpdateEqcTypeInput) {
    updateEqcType(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_EQC_TYPE_STATUS = gql`
  mutation activateEqcType($id: ID!, $isActive: Boolean!) {
    activateEqcType(id: $id, isActive: $isActive) {
      status
      message
    }
  }
`;

export const DUPLICATE_EQC_TYPE = gql`
  mutation duplicateEqcType($data: DuplicateEqcTypeInput) {
    duplicateEqcType(data: $data) {
      status
      message
    }
  }
`;

export const DELETE_EQC_TYPE = gql`
  mutation deleteEqcType($id: ID!) {
    deleteEqcType(id: $id) {
      status
      message
    }
  }
`;

export const CREATE_EQC_STAGE = gql`
  mutation createEqcTypeStage($data: CreateEqcTypeStageInput!) {
    createEqcTypeStage(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_EQC_STAGE = gql`
  mutation updateEqcTypeStage($id: ID!, $data: UpdateEqcTypeStageInput!) {
    updateEqcTypeStage(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_EQC_STAGE_ITEM = gql`
  mutation createEqcTypeStageItem($data: CreateEqcTypeStageItemInput!) {
    createEqcTypeStageItem(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_EQC_STAGE_ITEM = gql`
  mutation updateEqcTypeStageItem(
    $id: ID!
    $data: UpdateEqcTypeStageItemInput!
  ) {
    updateEqcTypeStageItem(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_STAGE = gql`
  mutation deleteEqcTypeStage($id: ID!) {
    deleteEqcTypeStage(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_STAGE_ITEM = gql`
  mutation deleteEqcTypeStageItem($id: ID!) {
    deleteEqcTypeStageItem(id: $id) {
      status
      message
    }
  }
`;

export const REORDER_STAGE = gql`
  mutation reorderEqcTypeStage($data: ReOrderEqcTypeStageInput!) {
    reorderEqcTypeStage(data: $data) {
      status
      message
    }
  }
`;

export const REORDER_STAGE_ITEM = gql`
  mutation reorderEqcTypeStageItem($data: ReOrderEqcTypeStageItemInput!) {
    reorderEqcTypeStageItem(data: $data) {
      status
      message
    }
  }
`;

export const IMPORT_EQC_TYPE_CSV = gql`
  mutation importEqcTypeCsv($eqcTypeId: ID!, $key: String!) {
    importEqcTypeCsv(eqcTypeId: $eqcTypeId, key: $key) {
      insertedData
      invalidData
      insertedDataCount
      invalidDataCount
    }
  }
`;
