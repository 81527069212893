import React from 'react';
import { Navigate } from 'react-router-dom';
import { ACCESS_TYPE, LOGS_TABS_KEYS, ROUTES } from '../../../common/constants';
import HasAccess from '../../../components/HasAccess';

const RedirectToTab = () => {
  const redirectPath = HasAccess({ type: ACCESS_TYPE.INSPECTION })
    ? LOGS_TABS_KEYS.ACTIVITY
    : LOGS_TABS_KEYS.INSTRUCTION;
  return <Navigate to={`${ROUTES.LOGS}/${redirectPath}`} />;
};

export default RedirectToTab;
