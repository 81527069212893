import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, Col, Modal, Row, Spin, Switch } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { WrongIcon } from '../../assets/svg';
import { NOTIFICATION_LABELS } from '../../common/constants';
import { UPDATE_NOTIFICATION_SETTING } from './graphql/Mutation';
import { GET_NOTIFICATION_SETTING } from './graphql/Queries';

const NotificationModal = (props) => {
  const { showModal, setShowModal } = props;
  const [notificationData, setNotificationData] = useState([]);

  const [getNotificationSetting] = useLazyQuery(GET_NOTIFICATION_SETTING, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setNotificationData(res?.getNotificationSetting);
    },
  });

  const [updateNotificationSetting, { loading: updateLoading }] = useMutation(
    UPDATE_NOTIFICATION_SETTING,
    {
      onCompleted() {
        getNotificationSetting();
      },
    },
  );

  useEffect(() => {
    getNotificationSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleChange = (status, key) => {
    updateNotificationSetting({
      variables: {
        data: { key, isActive: status },
      },
    });
  };

  return (
    <div id="notification-modal">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        width={678}
        closeIcon={<WrongIcon />}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('notification-modal')
        }
      >
        <div className="notification">
          <h2>Mail notification</h2>
          <div key={1}>
            <Card
              className="notification-card"
              title={
                <div className="d-flex justify-between notification-header">
                  <div>Description</div> <div>Get notified</div>
                </div>
              }
            >
              <Spin spinning={updateLoading}>
                {map(notificationData, (item) => {
                  return (
                    <Row
                      justify="space-between notification-row"
                      align="middle"
                      key={item?.key}
                      gutter={{ xs: 0, sm: 30 }}
                    >
                      <Col span={16}>
                        <div className="title">
                          {NOTIFICATION_LABELS?.[item?.key]?.label}
                        </div>
                        <div className="description">
                          {NOTIFICATION_LABELS?.[item?.key]?.description}
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="d-flex justify-center align-center">
                          <Switch
                            defaultChecked={item?.isActive}
                            onChange={(e) => handleChange(e, item?.key)}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </Spin>
            </Card>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NotificationModal;
