import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { isEmpty, map, sum } from 'lodash';
import React from 'react';
import { NOMENCLATURE_ACTION_BUTTON_URL } from '../../../../../../common/constants';
import {
  INDENT_PROJECT_NOMENCLATURE_LEVEL,
  OUTDENT_PROJECT_NOMENCLATURE_LEVEL,
} from '../../../../graphql/Mutation';

const IndentOutdentModal = ({
  showModal,
  setShowModal,
  handleRefetch,
  setCheckedTreeNode,
  checkedTreeNode,
  setCheckedKeys,
  isIndent,
  projectId,
  setExpandedKeys,
}) => {
  const eqcCount = sum(
    map(checkedTreeNode, 'data.data.totalInspectionCount').map((data) =>
      Number(data),
    ),
  );
  const handleCancel = () => {
    setShowModal(false);
  };

  const [indentProjectNomenclatureLevel, { loading }] = useMutation(
    INDENT_PROJECT_NOMENCLATURE_LEVEL,
    {
      onCompleted(res) {
        if (
          !isEmpty(res?.indentProjectNomenclatureLevel?.indentedParentLevelIds)
        ) {
          setExpandedKeys((prev) => [
            ...prev,
            ...res?.indentProjectNomenclatureLevel?.indentedParentLevelIds,
          ]);
        }
        setCheckedKeys();
        setCheckedTreeNode();
        handleCancel();
        handleRefetch();
      },
      onError() { },
    },
  );

  const [
    outdentProjectNomenclatureLevel,
    { loading: outdentLoading },
  ] = useMutation(OUTDENT_PROJECT_NOMENCLATURE_LEVEL, {
    onCompleted(res) {
      if (
        !isEmpty(res?.outdentProjectNomenclatureLevel?.outdentedParentLevelIds)
      ) {
        setExpandedKeys((prev) => [
          ...prev,
          ...res?.outdentProjectNomenclatureLevel?.outdentedParentLevelIds,
        ]);
      }
      setCheckedKeys();
      setCheckedTreeNode();
      handleCancel();
      handleRefetch();
    },
    onError() { },
  });

  const handleIndentOutdent = async () => {
    const mutation = isIndent
      ? indentProjectNomenclatureLevel
      : outdentProjectNomenclatureLevel;
    await mutation({
      variables: {
        data: {
          projectId,
          levels: map(checkedTreeNode, 'id'),
        },
      },
    });
  };
  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        <div>
          <h2>{isIndent ? `Indent` : `Outdent`} Level</h2>
          <p>Hey there! </p>
          <p>
            {isIndent && eqcCount > 0
              ? `This will indent level, ${eqcCount} EQCs linked will get renamed.`
              : `This will ${isIndent ? `indent` : 'outdent'} selected levels`}
          </p>
          <p>
            <a
              href={
                isIndent
                  ? NOMENCLATURE_ACTION_BUTTON_URL.INDENT
                  : NOMENCLATURE_ACTION_BUTTON_URL.OUTDENT
              }
              rel="noreferrer"
              target="_blank"
            >
              Click here
            </a>{' '}
            to understand more in detail.
          </p>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
              disabled={loading || outdentLoading}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleIndentOutdent}
              disabled={loading || outdentLoading}
              loading={loading || outdentLoading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IndentOutdentModal;
