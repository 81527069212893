import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($data: AuthInput!) {
    login(data: $data) {
      token
      refreshToken
      tenantUserData
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotUserPassword($email: String) {
    forgotUserPassword(where: { email: $email }) {
      status
      message
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation updateUserPassword($data: TokenInput!) {
    updateUserPassword(data: $data) {
      status
      message
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setUserPassword($data: TokenInput!) {
    setUserPassword(data: $data) {
      status
      message
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation isValidToken($data: TokenInput!) {
    isValidToken(data: $data) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      status
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
      status
    }
  }
`;
