import { Button, Modal } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { REPORT_NOTES } from '../../common/constants';

const ConfirmationModal = ({
  showModal = false,
  setShowModal,
  loading,
  mutationFunc = () => { },
}) => {
  const handleOk = () => {
    mutationFunc();
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <h2>Generate Download Link</h2>
          <p>
            Are you sure, you want to generate report download link and send to
            your registered email id?
          </p>
          <div>
            <b className="text-danger">Note:</b>
            <ul className="reports-note">
              {map(REPORT_NOTES, (note) => (
                <li key={note}>{note}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            No, Cancel
          </Button>
          <Button
            shape="round"
            className="save-button"
            onClick={handleOk}
            loading={loading}
            type="primary"
          >
            Yes, Generate
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
