import { Button, Modal } from 'antd';
import React from 'react';
import { NOMENCLATURE_ACTION_BUTTON_URL } from '../../../../../../common/constants';

const CreateModal = ({ showModal, setShowModal, handleClickEvent }) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setShowModal(false);
    handleClickEvent();
  };
  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        <div>
          <h2>Create nomenclature</h2>
          <p>Hey there! 👋 </p>
          <p>
            If you are creating first time we recommend to see this{' '}
            <a
              href={NOMENCLATURE_ACTION_BUTTON_URL.CREATE}
              rel="noreferrer"
              target="_blank"
            >
              video
            </a>
            .
          </p>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleClick}
            >
              Create
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateModal;
