import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_LOGGED_IN_USER = gql`
  mutation updateLoggedInUser($data: UpdateLoggedInUserInput!) {
    updateLoggedInUser(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_TENANT_LOGO = gql`
  mutation updateTenantLogo($logo: String) {
    updateTenantLogo(logo: $logo) {
      status
      message
    }
  }
`;
