import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { NOMENCLATURE_ACTION_BUTTON_URL } from '../../../../../../common/constants';
import { TOGGLE_PROJECT_NOMENCLATURE_LEVEL } from '../../../../graphql/Mutation';

const EnableDisableModal = ({
  showModal,
  setShowModal,
  handleRefetch,
  setCheckedTreeNode,
  setCheckedKeys,
  checkedKeys,
  isDisabledNodeSelected,
}) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  const [toggleProjectNomenclatureLevel, { loading }] = useMutation(
    TOGGLE_PROJECT_NOMENCLATURE_LEVEL,
    {
      onCompleted() {
        setCheckedKeys();
        setCheckedTreeNode();
        handleCancel();
        handleRefetch();
      },
      onError() { },
    },
  );

  const handleClick = () => {
    toggleProjectNomenclatureLevel({
      variables: {
        levels: map(checkedKeys, (item) => Number(item)),
      },
    });
  };
  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        <div>
          <h2>{isDisabledNodeSelected ? `Enable` : `Disable`} level</h2>
          {!isDisabledNodeSelected ? (
            <>
              <p>Hey there! </p>
              <p>This will disable all the selected level</p>
            </>
          ) : (
            <p>
              These changes will shown once mobile user will refresh the
              application. Are you sure?
            </p>
          )}
          {!isDisabledNodeSelected && (
            <p>
              <a
                href={NOMENCLATURE_ACTION_BUTTON_URL.DISABLE}
                rel="noreferrer"
                target="_blank"
              >
                Click here
              </a>{' '}
              to understand more in detail.
            </p>
          )}
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleClick}
              disabled={loading}
              loading={loading}
            >
              {isDisabledNodeSelected ? `Enable` : `Disable`}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EnableDisableModal;
