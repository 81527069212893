import { useLazyQuery } from '@apollo/client';
import { message } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FILE_SIZE_10MB } from '../common/constants';
import { fileUpload } from '../common/utils';
import { GET_URL, GET_URL_BY_KEY } from '../modules/profile/graphql/Queries';

const TextEditor = ({
  setEditorValue,
  className = '',
  placeholder,
  toggleImageLoader,
  readOnly = false,
  ...rest
}) => {
  const quillRef = useRef();
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState('');
  const [file, setFile] = useState(null);

  const [getImagesSignedUrls, { data }] = useLazyQuery(GET_URL_BY_KEY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const range = quillRef.current.getEditorSelection(true);
      quillRef.current
        .getEditor()
        .insertEmbed(range?.index, 'image', data?.getImagesSignedUrls?.[0]);
      setLoader(false);
    }
  }, [data]);

  const [fetchSignedUrl] = useLazyQuery(GET_URL, {
    onCompleted: ({ getSignedPutUrl: { signedUrl, key } }) => {
      fileUpload(signedUrl, file)
        .then(() => {
          toggleImageLoader();
          getImagesSignedUrls({
            variables: {
              data: { keys: [key] },
            },
          });
        })
        .catch(() => {
          toggleImageLoader();
          setLoader(false);
          message.error('Image upload failed!');
        });
    },
    onError() { },
  });
  const handleChange = (newValue) => {
    setValue(newValue);
    if (setEditorValue) {
      setEditorValue(newValue);
    }
  };
  const imageHandler = () => {
    try {
      // eslint-disable-next-line no-undef
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async () => {
        const selectedFile = input.files[0];
        if (selectedFile.size > FILE_SIZE_10MB) {
          return message.error('File size should not be greater than 10MB');
        }
        const { name, type: contentType } = selectedFile;
        const fileName = name.substr(0, name.lastIndexOf('.') - 1);
        setFile(selectedFile);
        setLoader(true);
        toggleImageLoader();
        fetchSignedUrl({
          variables: {
            data: {
              fileName,
              contentType,
              acl: 'private',
            },
          },
        });
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'link'],
          ['image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean'],
        ],
        handlers: {
          image: () => {
            if (!readOnly) {
              imageHandler();
            }
          },
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <ReactQuill
        className={`editor-component ${className}`}
        ref={quillRef}
        theme="snow"
        placeholder={placeholder}
        modules={modules}
        value={value || ''}
        readOnly={readOnly}
        onChange={handleChange}
        {...rest}
      />
      {loader && (
        <div className="text-danger file-size-warning">
          <i className="fa fa-spin fa-spinner mr-2" />
          Image upload in progress, please wait.
        </div>
      )}
    </>
  );
};

export default TextEditor;
