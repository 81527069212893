import { Layout } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../AppContext';
import {
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  SUBSCRIPTION_STATUS,
} from '../common/constants';
import CanPerform from '../components/CanPerform';
import './App.css';
import AppHeader from './components/header/AppHeader';
import MobileHeader from './components/header/MobileHeader';
import TenantSelect from './components/header/TenantSelect';
import GracePeriodMessage from './components/subscriptionPopups/GracePeriodMessage';
import OneMonthExpiryMessage from './components/subscriptionPopups/OneMonthExpiryMessage';

const VERSION = require('../../package.json').version;

const { Content, Footer } = Layout;

const App = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { getCurrentUser, getTenantData } = useContext(AppContext);
  const tenantUser = getCurrentUser()?.tenantUser;
  const tenantData = getTenantData();
  return (
    <>
      <Layout className="gx-app-layout">
        {tenantUser?.tenant?.subscription?.status ===
        SUBSCRIPTION_STATUS.OVER_DUE ? (
          <GracePeriodMessage />
        ) : (
          tenantUser?.tenant?.subscription?.status ===
            SUBSCRIPTION_STATUS.ACTIVE &&
          moment().isSameOrAfter(
            moment(tenantUser?.tenant?.subscription?.validTill).subtract(
              1,
              'month',
            ),
          ) && <OneMonthExpiryMessage />
        )}
        {!isDesktopViewport && <MobileHeader />}
        <Layout>
          {isDesktopViewport && <AppHeader />}
          <CanPerform
            action={ALLOWED_ACTION_KEYS.VIEW_TENANT_SELECTION_DROPDOWN}
          >
            {tenantData?.length > 1 && <TenantSelect />}
          </CanPerform>
          <Content className="gx-layout-content">
            {/* commented for future use */}
            {/* {!isOnline && <NetworkOffline />} */}
            <div
              className={clsx(
                isDesktopViewport &&
                  tenantData?.length <= 1 &&
                  'gx-main-content-wrapper',
                tenantData?.length > 1 && 'multiple-tenant',
              )}
            >
              <Outlet />
            </div>
            <Footer className="app-footer">
              <div className="mobile-layout">
                digiQC © {new Date().getFullYear()} (v{VERSION})
              </div>
              <div className="app-footer-product">
                <span>Product by</span>
                <span className="app-footer-product-title">
                  <a
                    href="https://www.digiqc.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SPACEIFY
                  </a>
                </span>
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default App;
