import { Card, Col, message, Row } from 'antd';
import { get, isArray, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import {
  BREAKPOINTS,
  DASHBOARD_API_ROUTES,
  DEFAULTDATETIMEFORMATFORREPORTS,
} from '../../../common/constants';
import AgencySummaryTable from './AgencySummaryTable';
import CustomRangePicker from './CustomRangePicker';
import ProjectSummaryTable from './ProjectSummaryTable';
import UserSummaryTable from './UserSummaryTable';

const Overview = () => {
  const [projectStats, setProjectStats] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [agencyStats, setAgencyStats] = useState([]);
  const [filterOption, setFilterOption] = useState('');
  const [agencyLoading, setAgencyLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const { getToken, getCurrentTenant } = useContext(AppContext);
  const authToken = getToken();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const tenantId = getCurrentTenant();

  const getProjectStats = () => {
    let query = DASHBOARD_API_ROUTES.PROJECT_LIST;
    const customHeaders = {
      Authorization: `Bearer ${authToken}`,
    };
    if (tenantId) {
      customHeaders.tenantId = tenantId;
    }
    if (isArray(filterOption) && filterOption?.length === 2) {
      query = `${query}?startDate=${filterOption?.[0]}&endDate=${filterOption?.[1]}`;
    }
    setProjectLoading(true);

    api
      .get(query, {
        headers: customHeaders,
      })
      .then((resultSet) => {
        // eslint-disable-next-line no-console
        const table = get(resultSet, 'data.data');
        const data = [];
        const dataArray = [];

        for (let i = 0; i < table.length; i += 1) {
          const dataItem = [];

          dataItem.push(table[i]);

          while (
            i < table.length - 1 &&
            table[i]['Project.id'] === table[i + 1]['Project.id']
          ) {
            i += 1;
            dataItem.push(table[i]);
          }

          dataArray.push(dataItem);
        }
        for (let k = 0; k < dataArray.length; k += 1) {
          let pass = 0;
          let fail = 0;
          let total = 0;
          map(dataArray[k], (value) => {
            if (value['EqcStageHistory.status'] === 'FAIL') {
              fail += Number(value['EqcStageHistory.count']);
            }
            if (value['EqcStageHistory.status'] === 'PASS') {
              pass += Number(value['EqcStageHistory.count']);
            }
            if (
              value['EqcStageHistory.status'] === 'FAIL' ||
              value['EqcStageHistory.status'] === 'PASS'
            ) {
              total += Number(value['EqcStageHistory.count']);
            }
          });
          const row = {
            id: dataArray[k][0]['Project.id'],
            projectName: dataArray[k][0]['Project.name'],
            failCount: Number(fail),
            passCount: Number(pass),
            totalCount: Number(total),
          };
          if (row.id !== null) {
            data.push(row);
          }
        }
        setProjectStats(data);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message) {
          message.destroy();
          message.error(error?.response?.data?.error?.message);
        }
      })
      .finally(() => setProjectLoading(false));
  };

  const getUserStats = () => {
    const userId = 'User.id';
    const userName = 'User.name';
    const status = 'EqcStageHistory.status';
    const count = 'EqcStageHistory.count';
    const customHeaders = {
      Authorization: `Bearer ${authToken}`,
    };
    if (tenantId) {
      customHeaders.tenantId = tenantId;
    }
    let query = DASHBOARD_API_ROUTES.USER_LIST;
    if (isArray(filterOption) && filterOption?.length === 2) {
      query = `${query}?startDate=${filterOption?.[0]}&endDate=${filterOption?.[1]}`;
    }
    setUserLoading(true);
    api
      .get(query, {
        headers: customHeaders,
      })
      .then((resultSet) => {
        const table = get(resultSet, 'data.data');
        const data = [];
        const dataArray = [];

        for (let i = 0; i < table.length; i += 1) {
          const dataItem = [];

          dataItem.push(table[i]);

          while (
            i < table.length - 1 &&
            table[i][userId] === table[i + 1][userId]
          ) {
            i += 1;
            dataItem.push(table[i]);
          }

          dataArray.push(dataItem);
        }
        for (let k = 0; k < dataArray.length; k += 1) {
          let pass = 0;
          let fail = 0;
          let total = 0;
          map(dataArray[k], (value) => {
            if (value[status] === 'FAIL') {
              fail += Number(value[count]);
            }
            if (value[status] === 'PASS') {
              pass += Number(value[count]);
            }
            if (value[status] === 'FAIL' || value[status] === 'PASS') {
              total += Number(value[count]);
            }
          });
          const row = {
            id: dataArray[k][0][userId],
            userName: dataArray[k][0][userName],
            failCount: Number(fail),
            passCount: Number(pass),
            totalCount: Number(total),
          };
          if (row.id !== null) {
            data.push(row);
          }
        }
        setUserStats(data);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message) {
          message.destroy();
          message.error(error?.response?.data?.error?.message);
        }
      })
      .finally(() => setUserLoading(false));
  };

  const getAgencyStats = () => {
    const agencyId = 'Agency.id';
    const agencyName = 'Agency.name';
    const status = 'EqcStageHistory.status';
    const count = 'EqcStageHistory.count';
    const customHeaders = {
      Authorization: `Bearer ${authToken}`,
    };
    if (tenantId) {
      customHeaders.tenantId = tenantId;
    }
    let query = DASHBOARD_API_ROUTES.AGENCY_LIST;
    if (isArray(filterOption) && filterOption?.length === 2) {
      query = `${query}?startDate=${filterOption?.[0]}&endDate=${filterOption?.[1]}`;
    }
    setAgencyLoading(true);
    api
      .get(query, {
        headers: customHeaders,
      })
      .then((resultSet) => {
        const table = get(resultSet, 'data.data');
        const data = [];

        const dataArray = [];

        for (let i = 0; i < table.length; i += 1) {
          const dataItem = [];

          dataItem.push(table[i]);

          while (
            i < table.length - 1 &&
            table[i][agencyId] === table[i + 1][agencyId]
          ) {
            i += 1;
            dataItem.push(table[i]);
          }

          dataArray.push(dataItem);
        }
        for (let k = 0; k < dataArray.length; k += 1) {
          let pass = 0;
          let fail = 0;
          let total = 0;
          map(dataArray[k], (value) => {
            if (value[status] === 'FAIL') {
              fail += Number(value[count]);
            }
            if (value[status] === 'PASS') {
              pass += Number(value[count]);
            }
            if (value[status] === 'FAIL' || value[status] === 'PASS') {
              total += Number(value[count]);
            }
          });
          const row = {
            id: dataArray[k][0][agencyId],
            agencyName: dataArray[k][0][agencyName],
            failCount: Number(fail),
            passCount: Number(pass),
            totalCount: Number(total),
          };
          if (row.id !== null) {
            data.push(row);
          }
        }
        setAgencyStats(data);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message) {
          message.destroy();
          message.error(error?.response?.data?.error?.message);
        }
      })
      .finally(() => setAgencyLoading(false));
  };

  useEffect(() => {
    getProjectStats();
    getUserStats();
    getAgencyStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption]);

  return isDesktopViewport ? (
    <Card>
      <div className="d-flex align-center justify-between mb-15">
        <h1>Overview</h1>
        <CustomRangePicker
          setDateSelected={(date) => {
            if (date?.length > 0) {
              setFilterOption([
                moment(date?.[0]).format(DEFAULTDATETIMEFORMATFORREPORTS),
                moment(date?.[1]).format(DEFAULTDATETIMEFORMATFORREPORTS),
              ]);
            } else {
              setFilterOption('');
            }
          }}
          selectedDates={filterOption}
        />
      </div>
      <Row gutter={20} wrap={false} className="width-percent-100 align-start">
        <Col flex="1 1 1px">
          <ProjectSummaryTable loading={projectLoading} data={projectStats} />
        </Col>
        <Col flex="1 1 1px">
          <UserSummaryTable loading={userLoading} data={userStats} />
        </Col>
        <Col flex="1 1 1px">
          <AgencySummaryTable loading={agencyLoading} data={agencyStats} />
        </Col>
      </Row>
    </Card>
  ) : (
    <div className="common-card-wrapper">
      <div className="mb-15">
        <CustomRangePicker
          className="width-percent-100"
          setDateSelected={(date) => {
            if (date?.length > 0) {
              setFilterOption([
                moment(date?.[0]).format(DEFAULTDATETIMEFORMATFORREPORTS),
                moment(date?.[1]).format(DEFAULTDATETIMEFORMATFORREPORTS),
              ]);
            } else {
              setFilterOption('');
            }
          }}
          selectedDates={filterOption}
        />
      </div>
      <div className={!isDesktopViewport ? 'mb-15' : ''}>
        <ProjectSummaryTable loading={projectLoading} data={projectStats} />
      </div>
      <div className={!isDesktopViewport ? 'mb-15' : ''}>
        <UserSummaryTable loading={userLoading} data={userStats} />
      </div>
      <AgencySummaryTable loading={agencyLoading} data={agencyStats} />
    </div>
  );
};

export default Overview;
