import { isNaN } from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Error404 from '../../../Error404';
import { ADD_PROJECT_STEPS_KEYS, ROUTES } from '../../../common/constants';
import useRouter from '../../../common/useRouter';

const RedirectToAddProject = () => {
  const { params: { projectId } } = useRouter();
  if (isNaN(Number(projectId))) {
    return <Error404 />;
  }
  return (
    <Navigate
      to={`${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.DETAILS}`}
    />
  );
};

export default RedirectToAddProject;
