import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Radio, Steps } from 'antd';
import clsx from 'clsx';
import { filter, keys, map, values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import { WrongIcon } from '../../../../../../assets/svg';
import {
  ACCESS_TYPE,
  ADD_AGENCY_STEPS,
  AGENCY_TYPE_KEYS,
  BREAKPOINTS,
  CREATE_AGENCY_TYPE,
  EMAIL_NOT_REQUIRED_NOTE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules, titleCase } from '../../../../../../common/utils';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import HasAccess from '../../../../../../components/HasAccess';
import { ASSIGN_PROJECT_AGENCY_CONTACT } from '../../../../graphql/Mutation';
import {
  ASSIGN_CONTACT_AGENCY_DROPDOWN_LIST,
  GET_PROJECT_AGENCY_EQC_TYPES_LIST,
} from '../../../../graphql/Queries';

const AssignAgencyModal = (props) => {
  const {
    showModal,
    setShowModal,
    agencyData,
    setAgencyData,
    refetchAgencyData,
  } = props;
  const [current, setCurrent] = useState(0);
  const isTabletViewport = useMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);
  const [step1formDetails, setStep1formDetails] = useState();
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { params: { projectId } } = useRouter();
  const [form] = Form.useForm();
  const { required, email } = formValidatorRules;
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const [agencyType, setAgencyType] = useState(CREATE_AGENCY_TYPE?.EXISTING);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSelectAllContacts, setIsSelectAllContacts] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [agencyList, setAgencyList] = useState([]);
  const [selectedAgencyData, setSelectedAgencyData] = useState();

  const handleCancel = () => {
    setShowModal(false);
    setAgencyData();
    form.resetFields();
  };
  useEffect(() => {
    if (hasInspectionAccess) {
      form.setFieldsValue({
        projectEqcTypeIds: agencyData?.projectAgencyEqcs?.map(
          (eqcValue) => eqcValue?.id,
        ),
      });
    }

    if (agencyData) {
      form.setFieldsValue({
        name: agencyData?.name,
        phoneNo: agencyData?.phoneNo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [assignProjectAgencyContact, { loading: updateLoading }] = useMutation(
    ASSIGN_PROJECT_AGENCY_CONTACT,
    {
      onError() { },
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_AGENCY, {
          label: GA_LABEL.ADD_PROJECT_AGENCY,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setAgencyData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyData();
      },
    },
  );

  const onAgencySubmitFinish = async (formValues) => {
    if (current === 0) {
      setStep1formDetails(formValues);
      setCurrent(1);
      return;
    }

    const data = {
      name: step1formDetails?.name,
      phoneNo: step1formDetails?.phoneNo,
      email: step1formDetails?.email,
      designation: step1formDetails?.designation,
      contactId: Number(agencyData?.id),
      agencyId: Number(formValues?.agencyId),
      projectId: Number(projectId),
      projectEqcTypeIds: isSelectAll ? [] : formValues?.projectEqcTypeIds,
      selectedAllEqcTypes: isSelectAll,
    };
    if (agencyType === CREATE_AGENCY_TYPE?.NEW) {
      data.agency = {
        name: formValues?.agencyName,
        type: formValues?.type,
      };
    }
    try {
      assignProjectAgencyContact({
        variables: {
          data,
        },
      });
    } catch (error) {
      return error;
    }
  };

  const handleDeselect = () => {
    setIsSelectAll(false);
    form.setFieldsValue({ projectEqcTypeIds: [] });
  };
  useEffect(() => {
    if (isSelectAll) {
      form.setFieldsValue({ projectEqcTypeIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAll]);

  useEffect(() => {
    if (isSelectAllContacts) {
      form.setFieldsValue({ contactIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllContacts]);

  const step1Form = (
    <>
      <Form.Item
        rules={[
          required,
          {
            max: 250,
            message: 'Name cannot be more than 250 characters',
          },
        ]}
        name="name"
        label="Name"
      >
        <Input allowClear placeholder="Enter Name" />
      </Form.Item>
      <Form.Item
        rules={[
          email,
          {
            max: 250,
            message: 'Email cannot be more than 250 characters',
          },
        ]}
        name="email"
        label="Email"
      >
        <Input allowClear placeholder="Enter Email" />
      </Form.Item>
      <div className="text-warning note-margin">{EMAIL_NOT_REQUIRED_NOTE}</div>
      <Form.Item label="Mobile Number" name="phoneNo">
        <Input allowClear disabled placeholder="Enter Mobile Number" />
      </Form.Item>
      <Form.Item
        rules={[
          required,
          {
            max: 250,
            message: 'Designation cannot be more than 250 characters',
          },
        ]}
        name="designation"
        label="Designation"
      >
        <Input allowClear placeholder="Enter Designation" />
      </Form.Item>
      <div className="form-buttons">
        <Button
          shape="round"
          type="primary"
          className="save-button"
          htmlType="submit"
        >
          Next
        </Button>
      </div>
    </>
  );
  const step2Form = (
    <>
      <Radio.Group
        value={agencyType}
        onChange={(e) => setAgencyType(e?.target?.value)}
        className="mb-16 agency-type-radio"
      >
        <Radio value={CREATE_AGENCY_TYPE?.EXISTING}>
          {CREATE_AGENCY_TYPE?.EXISTING}
        </Radio>
        <Radio value={CREATE_AGENCY_TYPE?.NEW}>{CREATE_AGENCY_TYPE?.NEW}</Radio>
      </Radio.Group>
      {agencyType === CREATE_AGENCY_TYPE?.EXISTING ? (
        <Form.Item name="agencyId" label="Agency" rules={[required]}>
          <CommonDropdown
            placeholder="Select Agency"
            onChange={(id) => {
              setSelectedAgencyData(
                filter(
                  agencyList?.assignContactAgencyDropdownList?.data,
                  (value) => value?.id === id,
                ),
              );
              setCurrentPage(0);
              setIsSelectAllContacts(false);
              form.setFieldsValue({ contactIds: [] });
            }}
            currentPage={currentPage}
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            allowClear
            query={ASSIGN_CONTACT_AGENCY_DROPDOWN_LIST}
            variables={{
              filter: { projectId },
            }}
            fetchPolicy="network-only"
            responsePath="assignContactAgencyDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="agency"
            showExtraLabel
            showExtraLoader
            addExtraData={selectedAgencyData}
            callback={(e) => setAgencyList(e)}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            rules={[
              required,
              {
                max: 250,
                message: 'Name cannot be more than 250 characters',
              },
            ]}
            name="agencyName"
            label="Agency"
          >
            <Input allowClear placeholder="Enter Agency Name" />
          </Form.Item>
          <Form.Item
            rules={[{ required, message: 'Required' }]}
            name="type"
            label="Type"
          >
            <CommonSelect options={[
              ...values(AGENCY_TYPE_KEYS).map((type) => {
                return {
                  key: type,
                  value: type,
                  label: titleCase(type),
                }
              }),
            ]} className="mr-3" placeholder="Select Type" allowClear />
          </Form.Item>
          <Form.Item
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>Checklist</div>
                {isSelectAll && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            name="projectEqcTypeIds"
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              placeholder="Select Checklist"
              mode="multiple"
              disabled={isSelectAll}
              showSearch
              optionFilterProp="children"
              allowClear
              query={GET_PROJECT_AGENCY_EQC_TYPES_LIST}
              variables={{
                filter: {
                  projectId: Number(projectId),
                  status: PROJECT_CHECKLIST_STATUS?.PUBLISHED,
                },
              }}
              fetchPolicy="network-only"
              responsePath="projectAgencyEqcTypeDropdownList.data"
              valuePath="id"
              labelPath="name"
              isSelectedAll={isSelectAll}
              optionKey="eqc-type"
              hasSelectAll
            />
          </Form.Item>
        </>
      )}
    </>
  );

  const renderFrom = {
    0: step1Form,
    1: step2Form,
  };

  return (
    <div id="assign-agency-modal">
      <Modal
        maskClosable={false}
        centered
        form={form}
        open={showModal}
        onCancel={handleCancel}
        closable
        footer={null}
        closeIcon={<WrongIcon />}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('assign-agency-modal')
        }
      >
        <div
          className={clsx(
            'd-flex align-center',
            isTabletViewport && 'justify-center',
          )}
        >
          <Steps className="mb-24 mt-5" current={current}
            items={[
              ...map(keys(ADD_AGENCY_STEPS), (stepItem) => {
                return {
                  key: stepItem,
                  title: ADD_AGENCY_STEPS[stepItem],
                  id: `step-${stepItem}`,
                }
              }),
            ]} />
        </div>
        <Form layout="vertical" form={form} onFinish={onAgencySubmitFinish}>
          {renderFrom[current]}
          {current === 1 && (
            <div className="form-buttons">
              <Button
                shape="round"
                className="cancel-button"
                onClick={() => setCurrent(0)}
              >
                Previous
              </Button>
              <Button
                shape="round"
                type="primary"
                className="save-button"
                htmlType="submit"
                loading={updateLoading}
              >
                Save
              </Button>
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AssignAgencyModal;
