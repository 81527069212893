import { useQuery } from '@apollo/client';
import { Button, Card, Image, Layout, message } from 'antd';
import clsx from 'clsx';
import { map } from 'lodash';
import { isAndroid } from 'mobile-device-detect';
import React from 'react';
import { useMedia } from 'react-use';
import Error404 from '../../Error404';
import logo from '../../assets/Logo.png';
import { BREAKPOINTS } from '../../common/constants';
import useRouter from '../../common/useRouter';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_REPORT_ASSETS } from './graphql/Queries';

const ReportAssetsViewer = () => {
  const {
    params: { url },
  } = useRouter();
  const { Header } = Layout;
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const { data, loading } = useQuery(GET_REPORT_ASSETS, {
    variables: {
      urlString: url,
    },
    onCompleted(res) {
      if (res?.reportAssets?.type === 'PDF') {
        // eslint-disable-next-line no-undef
        window.open(res?.reportAssets?.pdf, '_self');
        if (isAndroid) {
          message.success('PDF downloaded');
        }
      }
    },
  });
  if (loading || (!isAndroid && data?.reportAssets?.type === 'PDF'))
    return <LoaderComponent />;
  return data?.reportAssets ? (
    <div className="report-image-viewer">
      <Header>
        <h2 className="mb-0">{data?.reportAssets?.reportName}</h2>
        <Image preview={false} src={logo} alt="digiqc-logo" />
      </Header>
      <div className={isDesktopViewport ? 'gx-main-content-wrapper' : ''}>
        <Card className="report-image-viewer-content">
          <h2>
            {data?.reportAssets?.type === 'PDF'
              ? 'DRAWING PDF'
              : data?.reportAssets?.type}
          </h2>
          {data?.reportAssets?.images?.length > 0 && (
            <Image.PreviewGroup>
              {map(data?.reportAssets?.images, (photo, index) => (
                <Image src={photo} alt="report-images" id={`image-${index}`} />
              ))}
            </Image.PreviewGroup>
          )}
          {data?.reportAssets?.type === 'PDF' && isAndroid && (
            <Button
              shape="round"
              type="primary"
              // eslint-disable-next-line no-undef
              onClick={() => window.open(data?.reportAssets?.pdf, '_self')}
            >
              Download Again
            </Button>
          )}
          {data?.reportAssets?.audios?.length > 0 && (
            <div>
              {map(data?.reportAssets?.audios, (audio, index) => (
                <div
                  key={`audio-${index}`}
                  className="d-flex align-center mt-25"
                >
                  <div className="d-flex align-center">
                    <span>Audio</span>
                    <span className="ml-5"> {index + 1}</span>
                  </div>
                  {/*  eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <audio
                    controls
                    name="media"
                    className={clsx('audio-player ml-10')}
                  >
                    <source src={audio} type="audio/mp4" />
                  </audio>
                </div>
              ))}
            </div>
          )}
        </Card>
      </div>
    </div>
  ) : (
    <Error404 />
  );
};

export default ReportAssetsViewer;
