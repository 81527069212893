import { Button, Modal } from 'antd';
import React from 'react';

const DeleteModalStage = ({
  showModal = false,
  setShowModal,
  data,
  mutation,
  eqcTypeId,
  stageId,
  isDeletable,
}) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOk = () => {
    if (isDeletable) {
      mutation({
        variables: {
          id: data?.id,
        },
      });
      handleCancel()
    } else {
      mutation({
        variables: {
          id: data?.id,
          data: {
            projectEqcTypeId: eqcTypeId ? Number(eqcTypeId) : undefined,
            projectEqcTypeStageId: stageId ? Number(stageId) : undefined,
            isActive: !data?.isActive,
          },
        },
      });
      handleCancel()
    }
  };

  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>CAUTION</h2>
        <p>
          {
            // eslint-disable-next-line no-nested-ternary
            isDeletable
              ? `This will permanently remove all the ${eqcTypeId ? 'stage ' : 'checkpoint '
              } configuration done for the  ${eqcTypeId ? 'stage ' : 'checkpoint '
              }. Are you sure you want to delete this  ${eqcTypeId ? 'stage' : 'checkpoint'
              }?`
              : data?.isActive
                ? `This will permanently remove all the ${eqcTypeId ? 'stage ' : 'checkpoint '
                } configuration once saved. Are you sure you want to deactivate this ${eqcTypeId ? 'stage' : 'checkpoint'
                }?`
                : `Are you sure you want to activate this ${eqcTypeId ? 'stage' : 'checkpoint'
                }?`
          }
        </p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            No
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOk}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModalStage;
