import { get } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { ACCESS_TYPE } from '../common/constants';

const HasAccess = ({ children, type = ACCESS_TYPE.INSPECTION }) => {
  const {
    state: { currentUser = {} },
  } = useContext(AppContext);
  const access = get(currentUser, 'tenantUser.tenant.access', []);
  const accessValue = access.includes(type);
  return accessValue && (children || accessValue);
};
export default HasAccess;
