/* eslint-disable no-nested-ternary */
import { Card, Descriptions, Image, Space, Tag } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import { filter, findIndex, map, takeRight, toUpper } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon, DoubleTick } from '../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  QCSTATUS,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { titleCase } from '../../common/utils';
import AudioPlayerModal from '../../components/AudioPlayerModal';
import EllipsisText from '../../components/EllipsisText';
import CommentModal from './CommentModal';
import CommentPopUp from './CommentPopUp';
import PreviousCommentModal from './PreviousCommentModal';

const ApproverStageItemDetails = ({
  data,
  refetch,
  commentData,
  setCommentData,
  loading,
  setIsBannerBlinking,
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showModal, setShowModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [stageItemData, setStageItemData] = useState();
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [audios, setAudios] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const stageData = data?.getEqcStageHistoryWithoutAuth?.itemHistory;
  const previousHistoryData =
    data?.getEqcStageHistoryWithoutAuth?.previousHistoryData;
  const generalCommentData = takeRight(
    previousHistoryData,
    previousHistoryData ? previousHistoryData.length - 1 : 0,
  );
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData]);
  const eqcStageItemIds = map(commentData?.eqcStageItems, 'eqcStageItemId');
  const getPhotos = (photos, imageId) => {
    return photos?.length ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
              });
            }
          },
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      '-'
    );
  };
  const eqcStatus = (status) => {
    switch (status) {
      case toUpper(QCSTATUS.PASS):
        return <Tag className="active-tag">{titleCase(QCSTATUS.PASS)}</Tag>;
      case toUpper(QCSTATUS.APPROVED):
        return <Tag className="active-tag">{titleCase(QCSTATUS.APPROVED)}</Tag>;
      case toUpper(QCSTATUS.ACCEPTED):
        return <Tag className="active-tag">{titleCase(QCSTATUS.ACCEPTED)}</Tag>;
      case toUpper(QCSTATUS.SKIP):
        return <Tag className="yellow-tag">{titleCase(QCSTATUS.SKIP)}</Tag>;
      case toUpper(QCSTATUS.REDO):
        return <Tag className="inactive-tag">{titleCase(QCSTATUS.REDO)}</Tag>;
      case toUpper(QCSTATUS.REJECTED):
        return (
          <Tag className="inactive-tag">{titleCase(QCSTATUS.REJECTED)}</Tag>
        );
      case toUpper(QCSTATUS.NOT_RECORDED):
        return (
          <Tag className="inactive-tag">{titleCase(QCSTATUS.NOT_RECORDED)}</Tag>
        );
      default:
        <Tag color="red">{titleCase(QCSTATUS.FAIL)}</Tag>;
        break;
    }
  };
  return (
    <>
      {showModal && (
        <PreviousCommentModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={generalCommentData}
          stageItemData={stageItemData}
        />
      )}
      {showCommentModal && (
        <CommentModal
          showModal={showCommentModal}
          setShowModal={setShowCommentModal}
          stageItemData={stageItemData}
          data={data}
          refetch={refetch}
          setCommentData={setCommentData}
          commentData={commentData}
        />
      )}
      <div className="approver-stage-details">
        {stageData?.length > 0 && (
          <div className="approver-stage-details-header d-flex">
            <i data-eva="checkmark-square-outline" className="mr-16" />
            <h2>Checkpoint</h2>
          </div>
        )}
        <div>
          {map(
            stageData || Array.from({ length: 2 }, (_, i) => i + 1),
            (stageItem, stageIndex) => {
              const additionalCommentData = filter(
                stageData,
                (item) => item?.isAdditionalCommentAdded,
              );
              const additionalCommentIndex = findIndex(
                additionalCommentData,
                (item) => {
                  return item?.eqcStageItemId === stageItem?.eqcStageItemId;
                },
              );
              const additionalPointData = filter(
                stageData,
                (item) => item?.isAdditionalPointAdded,
              );
              const additionalPointIndex = findIndex(
                additionalPointData,
                (item) => {
                  return item?.eqcStageItemId === stageItem?.eqcStageItemId;
                },
              );
              return (
                <Card
                  loading={loading}
                  className="mb-24"
                  key={stageItem?.eqcStageItemId}
                >
                  <div>
                    {additionalCommentIndex >= 0 ? (
                      <EllipsisText
                        className="checklist-item-text"
                        text={`AC-${additionalCommentIndex + 1}.  ${
                          stageItem.title
                        }`}
                      />
                    ) : additionalPointIndex >= 0 ? (
                      <EllipsisText
                        className="checklist-item-text"
                        text={`AP-${additionalPointIndex + 1}.  ${
                          stageItem.title
                        }`}
                      />
                    ) : (
                      <EllipsisText
                        className="checklist-item-text"
                        text={`${stageIndex + 1}.  ${stageItem.title}`}
                      />
                    )}
                  </div>
                  <div>
                    <div
                      className={
                        isDesktopViewport
                          ? 'd-flex justify-between align-end'
                          : 'd-flex flex-vertical'
                      }
                    >
                      <Descriptions
                        layout="vertical"
                        column={isDesktopViewport ? 10 : 4}
                        className="mt-24"
                      >
                        <Descriptions.Item
                          span={2}
                          label="Review"
                          className={clsx(!isDesktopViewport && 'common-item')}
                        >
                          <EllipsisText text={stageItem?.qcOption || '-'} />
                        </Descriptions.Item>
                        <Descriptions.Item span={2} label="Remarks">
                          <EllipsisText text={stageItem?.remarks || '-'} />
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={2}
                          label="Audio Remarks"
                          className={clsx(!isDesktopViewport && 'common-item')}
                        >
                          {!stageItem?.remarkAudios ||
                          stageItem?.remarkAudios?.length === 0 ||
                          stageItem?.remarkAudios === null ? (
                            '-'
                          ) : (
                            <div
                              className="audio-player-wrapper"
                              onClick={() => {
                                setShowAudioModal(true);
                                setAudios(stageItem?.remarkAudios);
                                Event(GA_EVENT.DOWNLOAD_AUDIO, {
                                  label: GA_LABEL.DOWNLOAD_AUDIO,
                                  // eslint-disable-next-line no-undef
                                  pathname: window?.location?.href,
                                });
                              }}
                            >
                              <AudioIcon />
                              {stageItem?.remarkAudios?.length - 1 > 0 && (
                                <div className="extra-count">{`+${
                                  stageItem?.remarkAudios?.length - 1
                                }`}</div>
                              )}
                            </div>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={2}
                          label="Photos"
                          className={clsx(!isDesktopViewport && 'common-item')}
                        >
                          {stageItem?.photos?.length === 0 ||
                          stageItem?.photos === null ? (
                            '-'
                          ) : (
                            <>
                              {getPhotos(
                                stageItem?.photos,
                                stageItem?.eqcStageItemId,
                              )}
                            </>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={2}
                          label="Status"
                          className={clsx(!isDesktopViewport && 'common-item')}
                        >
                          <div>
                            {data?.getEqcStageHistoryWithoutAuth?.isRfiStage &&
                            stageItem?.qcStatus === toUpper(QCSTATUS.PASS) ? (
                              <DoubleTick
                                className="double-tick-icon"
                                width={18}
                                height={18}
                              />
                            ) : (
                              eqcStatus(stageItem?.qcStatus)
                            )}
                          </div>
                        </Descriptions.Item>
                      </Descriptions>
                      <CommentPopUp
                        previousHistoryData={previousHistoryData}
                        stageItem={stageItem}
                        onClick={() => {
                          setShowCommentModal(true);
                          setStageItemData(stageItem);
                        }}
                        data={data?.getEqcStageHistoryWithoutAuth}
                        setIsBannerBlinking={setIsBannerBlinking}
                        eqcStageItemIds={eqcStageItemIds}
                      />
                    </div>
                  </div>
                </Card>
              );
            },
          )}
        </div>
        {audios?.length > 0 && (
          <AudioPlayerModal
            audios={audios}
            showModal={showAudioModal}
            setShowModal={setShowAudioModal}
          />
        )}
      </div>
    </>
  );
};

export default ApproverStageItemDetails;
