import { useLazyQuery } from '@apollo/client';
import { Empty } from 'antd';
import * as eva from 'eva-icons';
import { findIndex, isNaN, toUpper } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../../AppContext';
import Error404 from '../../../../../../../Error404';
import { ForwardArrow, ProjectIcon } from '../../../../../../../assets/svg';
import { ROUTES, TAB_KEYS } from '../../../../../../../common/constants';
import useRouter from '../../../../../../../common/useRouter';
import EllipsisText from '../../../../../../../components/EllipsisText';
import LoaderComponent from '../../../../../../../components/LoaderComponent';
import history from '../../../../../../../historyData';
import {
  GET_PROJECT_EQC,
  GET_SINGLE_PROJECT,
} from '../../../../../graphql/Queries';
import EqcStages from './EqcStages';
import EqcSummary from './EqcSummary';

const EqcDetails = () => {
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const currentUserId = getCurrentUser()?.id || {};
  const { navigate, params: { projectId, eqcId }, location } = useRouter()
  const [loading, setLoading] = useState(true);
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const eqcFilter =
    typeof location?.state?.eqcFilter === 'string'
      ? JSON.parse(location?.state?.eqcFilter)
      : location?.state?.eqcFilter;
  const eqcPagination = location?.state?.eqcPagination;
  const eqcTypeRecord = location?.state?.eqcTypeRecord;
  const userRecord = location?.state?.userRecord;
  const projectRecord = location?.state?.projectRecord;
  const currentPath = location?.state?.currentPath;
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unlisten = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, {
          state: {
            navFilter,
            navPagination,
            eqcFilter,
            eqcPagination,
            eqcTypeRecord,
            userRecord,
            projectRecord,
          },
        });
      }
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  const [getWebProject, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        dispatch({
          type: 'SET_PROJECT_DETAILS',
          data: JSON.stringify(res?.getProject),
        });
        const index = findIndex(
          res?.getProject?.projectUsers,
          (user) => Number(user?.userId) === Number(currentUserId),
        );
        if (index !== -1) {
          const loggedInUserRole =
            res?.getProject?.projectUsers?.[index]?.roles;
          dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        } else {
          dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
        }
      },
    },
  );

  useEffect(() => {
    if (!isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: projectId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const [fetchEqcDataLazyQuery, { data: eqcData }] = useLazyQuery(
    GET_PROJECT_EQC,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        setLoading(false);
      },
    },
  );

  const fetchEqcData = () => {
    if (!isNaN(Number(eqcId))) {
      fetchEqcDataLazyQuery({
        variables: {
          id: eqcId,
        },
      });
    }
  };

  useEffect(() => {
    fetchEqcData();
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNaN(Number(projectId)) || isNaN(Number(eqcId))) {
    return <Error404 />;
  }
  return loading ? (
    <LoaderComponent />
  ) : (
    <>
      <div className="project-details">
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex">
            <ProjectIcon
              onClick={() => {
                navigate(ROUTES.PROJECTS, {
                  state: {
                    navFilter,
                    navPagination,
                  },
                });
              }}
              className="pointer"
            />
            <ForwardArrow />
            <h1
              className="pointer mr-10 text-primary"
              onClick={() => {
                navigate(
                  `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC}`,
                  {
                    state: TAB_KEYS[
                      toUpper(
                        currentPath?.split('/')?.[
                        currentPath?.split('/')?.length - 1
                        ],
                      )
                    ]
                      ? {
                        navFilter,
                        navPagination,
                        eqcFilter,
                        eqcPagination,
                        eqcTypeRecord,
                        userRecord,
                      }
                      : {},
                  },
                );
              }}
            >
              {projectData?.getProject?.name}
            </h1>
            <ForwardArrow />
            <EllipsisText
              className="h1-typography"
              text={eqcData?.getEqc?.eqcName}
            />
          </div>
        </div>
        {eqcData?.getEqc ? (
          <div className="eqc-tab-details mobile-card-wrapper">
            <div className="mb-15 ">
              <EqcSummary
                data={eqcData?.getEqc}
                projectData={projectData}
                refetch={() => {
                  if (!eqcData?.isAudited) {
                    fetchEqcData();
                  }
                }}
              />
            </div>
            <div>
              <EqcStages data={eqcData?.getEqc} />
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
};

export default EqcDetails;
